import React from 'react';
const FacebookIcon = () => {
  return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="FacebookIcon" data-sentry-source-file="facebook-icon.tsx">
      <rect width="32" height="32" rx="16" fill="#F2F2F0" data-sentry-element="rect" data-sentry-source-file="facebook-icon.tsx" />
      <g clipPath="url(#clip0_12639_9448)" data-sentry-element="g" data-sentry-source-file="facebook-icon.tsx">
        <path d="M25 16C25 11.0294 20.9706 7 16 7C11.0294 7 7 11.0294 7 16C7 20.4921 10.2912 24.2155 14.5938 24.8907V18.6016H12.3086V16H14.5938V14.0172C14.5938 11.7616 15.9374 10.5156 17.9932 10.5156C18.9776 10.5156 20.0078 10.6914 20.0078 10.6914V12.9062H18.873C17.755 12.9062 17.4062 13.6001 17.4062 14.3125V16H19.9023L19.5033 18.6016H17.4062V24.8907C21.7088 24.2155 25 20.4921 25 16Z" fill="#8C8C8E" data-sentry-element="path" data-sentry-source-file="facebook-icon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="facebook-icon.tsx">
        <clipPath id="clip0_12639_9448" data-sentry-element="clipPath" data-sentry-source-file="facebook-icon.tsx">
          <rect width="18" height="18" fill="white" transform="translate(7 7)" data-sentry-element="rect" data-sentry-source-file="facebook-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default FacebookIcon;