const UserIcon = () => {
  return <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="UserIcon" data-sentry-source-file="UserIcon.tsx">
      <g clipPath="url(#clip0_11648_25852)" data-sentry-element="g" data-sentry-source-file="UserIcon.tsx">
        <rect width="58" height="58" rx="16" fill="white" data-sentry-element="rect" data-sentry-source-file="UserIcon.tsx" />
        <path d="M56 60.3271C55.4437 60.884 54.8706 61.4237 54.2799 61.9444C53.1161 62.9735 51.8885 63.9267 50.605 64.7984C44.834 68.7194 37.9248 71 30.5 71C23.0752 71 16.166 68.7194 10.395 64.7984C9.11151 63.9267 7.88389 62.9735 6.72011 61.9444C6.12945 61.4237 5.55634 60.884 5 60.3271C6.38008 55.9126 9.75486 52.3343 14.2064 50.8842L25.0451 47.3513V45.5066C23.6866 44.6736 22.4725 43.7212 21.4716 43.0233C19.6094 41.7265 18.3562 39.6939 17.9859 37.4075L17.408 33.8384C15.5977 33.8384 14.1346 32.3281 14.1346 30.4595C14.1346 29.0704 14.9423 27.879 16.1013 27.3608C15.0892 24.9113 14.3469 22.3152 13.9007 19.6531C13.6173 17.9666 13.4577 16.1877 13.9374 14.5613C14.4163 12.9349 15.6855 11.5079 17.1829 11.4799L17.3792 11.4939C18.3243 9.97052 19.801 8.967 21.3511 8.38696C22.9012 7.80611 24.5375 7.60919 26.1586 7.4164C26.7924 7.34142 27.4293 7.2648 28.0679 7.19889C31.0124 6.9064 34.2188 6.83554 37.2296 7.70065C40.2412 8.56658 43.1506 10.5077 44.684 13.6023C45.902 16.0576 46.1487 19.0195 45.8421 21.8216C45.6394 23.6812 45.2076 25.4913 44.7007 27.2784C45.9643 27.7456 46.8654 28.9921 46.8654 30.4595C46.8654 32.3281 45.4039 33.8384 43.592 33.8384L43.0141 37.4075C42.6438 39.6939 41.3914 41.7265 39.5292 43.0233C38.5267 43.7212 37.3142 44.6736 35.9557 45.5066V47.3513L46.7936 50.8842C51.2451 52.3351 54.6199 55.9134 56 60.3271Z" fill="#98A2B3" data-sentry-element="path" data-sentry-source-file="UserIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="UserIcon.tsx">
        <clipPath id="clip0_11648_25852" data-sentry-element="clipPath" data-sentry-source-file="UserIcon.tsx">
          <rect width="58" height="58" rx="16" fill="white" data-sentry-element="rect" data-sentry-source-file="UserIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default UserIcon;