import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import { MagazineType } from "pages/magazines/utils/models/magazineModel";
import "swiper/css"; // Swiper core styles
import "swiper/css/navigation"; // For optional navigation buttons
import "swiper/css/pagination"; // For optional pagination
import styles from "./magazines-mobile.module.scss";
import { cx } from "utils/cx";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/ru";
import "dayjs/locale/uz-latn";
import ArticleItem from "common/article-item/ArticleItem";
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(advancedFormat);
type Props = {
  magazines: MagazineType[] | undefined;
};
const MagazinesMobile: React.FC<Props> = ({
  magazines
}) => {
  if (!magazines || magazines.length === 0) {
    return null;
  }
  return <div className={styles.container} data-sentry-component="MagazinesMobile" data-sentry-source-file="MagazinesMobile.tsx">
      <Swiper spaceBetween={10} slidesPerView={1} pagination={{
      clickable: true,
      el: ".custom_pagination"
    }} navigation={{
      prevEl: "#navigation_prev",
      nextEl: "#navigation_next"
    }} modules={[Pagination, Navigation, Autoplay]} autoplay={{
      delay: 5000,
      disableOnInteraction: false
    }} onTouchStart={e => e?.autoplay?.stop()} onTouchEnd={e => e?.autoplay?.start()} className={styles.magazines_mobile_swiper} data-sentry-element="Swiper" data-sentry-source-file="MagazinesMobile.tsx">
        {magazines?.map((magazine, index) => <SwiperSlide key={index}>
            <ArticleItem item={magazine} />
          </SwiperSlide>)}
      </Swiper>
      {magazines && magazines?.length > 1 && <div className={styles.custom_pagination_container}>
          <div className={cx("custom_pagination", styles.custom_pagination)}></div>
        </div>}
    </div>;
};
export default MagazinesMobile;