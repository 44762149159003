export const findLinksInText = (text: string) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g

  return text
    .replace(
      urlPattern,
      (_, p1) => `<a href='${p1}' target='_blank'>${p1}</a>`
    )
    .trim()
}
