const uz = {
  OZBEKISTONDAGI_ENG_YIRIK_KOCHMAS_MULK_YARMARKASI:
    "O'ZBEKISTONDAGI ENG YIRIK KO'CHMAS MULK YARMARKASI",
  "5000_tashrif": "5000+ \n tashrif",
  "28_29_Sentabr": "3-4 \n May",
  Axelon_Karting_Toshkent_Anhor_park: "Axelon Karting \n Toshkent, Anhor park",
  Birinchi_royxatdan_otgan_10_ta:
    "Birinchi ro'yxatdan o'tgan 10 ta quruvchi hamkorimiz uchun 15% chegirma",
  Royxatdan_otish: "Ro’yxatdan o’tish",
  potensial_mijozlar: "potensial mijozlar",
  quruvchi_kompaniyalar: "quruvchi kompaniyalar",
  yarmarka_maydoni: "yarmarka maydoni",
  ishtirokchilar_soni: "ishtirokchilar soni",
  spikerlar: "spikerlar",
  investitsiya_va_uy_joy_uchun:
    "investitsiya va uy-joy uchun qiziqarli loyihalar",
  Oz_brendingizni_tanitish_bozorni_yaqindan_organish:
    "O'z brendingizni tanitish, bozorni yaqindan o'rganish, raqobatchilardan ustun bo'lish \n hamda yangi potensial mijozlaringiz bilan tanishish imkoniyatini qo'ldan boy bermang",
  OTGAN_EXPO_NATIJALARI_RAQAMLARDA: "O’TGAN EXPO NATIJALARI RAQAMLARDA",
  QATNASHCHI: "QATNASHCHI",
  OAV_DAGI_QAMROV: "OAV DAGI QAMROV",
  ROYXATDAN_OTGANLAR: "RO'YXATDAN O'TGANLAR",
  TASHRIF_BUYURUVCHILAR: "TASHRIF BUYURUVCHILAR",
  SHARTNOMALAR_TUZILDI: "SHARTNOMALAR TUZILDI",
  SOTUVLAR_HAR_BIR_QATNASHCHI_UCHUN: "SOTUVLAR HAR BIR QATNASHCHI UCHUN",
  MAMNUN_QATNASHUVCHILAR_VA_MAMNUN_MIJOZLAR:
    "MAMNUN QATNASHUVCHILAR VA MAMNUN MIJOZLAR",
  QURUVCHI_HAMKORLARIMIZ_FIKRLARI: "QURUVCHI HAMKORLARIMIZ FIKRLARI",
  OZBEKISTONDAGI_ENG_YIRIK_NOVOSTROYKALAR:
    "O'ZBEKISTONDAGI ENG YIRIK NOVOSTROYKALAR \n KO'RGAZMAMIZDA ISHTIROK ETISHGA TAYYORMISIZ?",
  biz_bilan_boglaning: "biz bilan bog’laning",
  Manzil: "Manzil",
  Vaqti: "Vaqti",
  kontakt: "kontakt",
  Bosh_sahifa: "Bosh sahifa",
  Uy_sotib_oling: "Uy sotib oling",
  Aloqa: "Aloqa",
  KOCHMAS_MULK_YARMARKASI: "KO'CHMAS MULK YARMARKASI",
  xaritada_korish: "xaritada ko’rish",
  ROYXATDAN_OTING: "RO’YXATDAN O’TING",
  Malumotlaringizni_qoldiring_va_biz_siz_bilan_boglanamiz:
    "Malumotlaringizni qoldiring va biz siz bilan bog’lanamiz!",
  Ism_sharifingiz: "Ism sharifingiz",
  Telefon_raqam: "Telefon raqam",
  Tashrifdan_maqsad: "Tashrifdan maqsad",
  Hamkorlik_qilmoqchiman: "Hamkorlik qilmoqchiman",
  Mehmon_bolmoqchiman: "Mehmon bo’lmoqchiman",
  YUBORISH: "YUBORISH",
  Siz_bilan_boglanishimiz_uchun_kiritgan_mlumotlarni_tekshiring:
    "Siz bilan bog'lanishimiz uchun kiritgan ma'lumotlarni tekshiring. So'rovni qoldirish orqali siz maxfiylik siyosatiga rozilik bildirasiz",
  "-YIL": "-YIL",
  Royxatdan_muvaffaqiyatli_otdingiz: "Ro’yxatdan muvaffaqiyatli o’tdingiz!",
  Tez_orada_siz_bilan_boglanamiz: "Tez orada siz bilan bog’lanamiz.",
  BILETNI_YUKLAB_OLISH: "BILETNI YUKLAB OLISH",
  Uy_olish: "Uy olish",
  Tomosha: "Tomosha",
  Uy_sotish_quruvchi: "Uy sotish(quruvchi)",
  Boshqa_biznes: "Boshqa biznes",
}

const ru: typeof uz = {
  OZBEKISTONDAGI_ENG_YIRIK_KOCHMAS_MULK_YARMARKASI:
    "САМАЯ КРУПНАЯ ЯРМАРКА НОВОСТРОЕК В УЗБЕКИСТАНЕ",
  "5000_tashrif": "5000+ \n ПОСЕТИТЕЛЕЙ",
  "28_29_Sentabr": "3-4 \n МАЯ",
  Axelon_Karting_Toshkent_Anhor_park: "Axelon КАРТИНГ \n ПАРК АНХОР, ТАШКЕНТ",
  Birinchi_royxatdan_otgan_10_ta:
    "Скидка 15 % для наших первых 10 зарегистрированных партнеров-застройщиков.",
  Royxatdan_otish: "регистрация",
  potensial_mijozlar: "ПОТЕНЦИАЛЬНЫЕ ПОКУПАТЕЛИ",
  quruvchi_kompaniyalar: "КОМПАНИИ ЗАСТРОЙЩИКИ",
  yarmarka_maydoni: "ПЛОЩАДЬ ВЫСТАВКИ",
  ishtirokchilar_soni: "КОЛИЧЕСТВО УЧАСТНИКОВ",
  spikerlar: "СПИКЕРЫ",
  investitsiya_va_uy_joy_uchun: "ИНТЕРЕСНЫЕ ПРОЕКТЫ ДЛЯ ИНВЕСТИЦИЙ И ЖИЛЬЯ",
  Oz_brendingizni_tanitish_bozorni_yaqindan_organish:
    "ПРОДВИЖЕНИЕ СОБСТВЕННОГО БРЕНДА, ИСЛЕДОВАНИЕ РЫНКА, Не упустите возможность \n опередить конкурентов и познакомиться с новыми потенциальными клиентами",
  OTGAN_EXPO_NATIJALARI_RAQAMLARDA: "Итоги прошедшей выставки в цифрах",
  QATNASHCHI: "УЧАСТНИКОВ",
  OAV_DAGI_QAMROV: "ОХВАТ В СМИ",
  ROYXATDAN_OTGANLAR: "ЗАРЕГИСТРИРОВАННЫХ ЛИЦ",
  TASHRIF_BUYURUVCHILAR: "ПОСЕТИТЕЛЕЙ",
  SHARTNOMALAR_TUZILDI: "ЗАКЛЮЧЁННЫХ ДОГОВОРОВ",
  SOTUVLAR_HAR_BIR_QATNASHCHI_UCHUN: "ПРОДАЖ В СРЕДНЕМ НА КАЖДОГО УЧАСТНИКА",
  MAMNUN_QATNASHUVCHILAR_VA_MAMNUN_MIJOZLAR:
    "ДОВОЛЬНЫХ УЧАСТНИКОВ И ДОВОЛЬНЫХ  ПОСЕТИТЕЛЕЙ",
  QURUVCHI_HAMKORLARIMIZ_FIKRLARI: "ОТЗЫВЫ ОТ НАШИХ ПАРТНЁРОВ ЗАСТРОЙЩИКОВ",
  OZBEKISTONDAGI_ENG_YIRIK_NOVOSTROYKALAR:
    "ГОТОВЫ УЧАСТВОВАТЬ В САМОЙ КРУПНОЙ ВЫСТАВКЕ \n НОВОСТРОЕК В УЗБЕКИСТАНЕ?",
  biz_bilan_boglaning: "связаться с нами",
  Manzil: "АДРЕС",
  Vaqti: "ВРЕМЯ",
  kontakt: "КОНТАКТЫ",
  Bosh_sahifa: "Главное меню",
  Uy_sotib_oling: "Покупка жилья",
  Aloqa: "Контакты",
  KOCHMAS_MULK_YARMARKASI: "ЯРМАРКА НОВОСТРОЕК",
  xaritada_korish: "смотреть на карте",
  ROYXATDAN_OTING: "ПРОЙДИТЕ РЕГИСТРАЦИЮ",
  Malumotlaringizni_qoldiring_va_biz_siz_bilan_boglanamiz:
    "Оставьте свои данные и мы свяжемся с вами!",
  Ism_sharifingiz: "ФИО",
  Telefon_raqam: "Номер телефона",
  Tashrifdan_maqsad: "Цель посещения выставки",
  Hamkorlik_qilmoqchiman: "Хочу сотрудничество",
  Mehmon_bolmoqchiman: "Хочу посетить как гость",
  YUBORISH: "отправить",
  Siz_bilan_boglanishimiz_uchun_kiritgan_mlumotlarni_tekshiring:
    "Проверьте данные, по которым мы с вами свяжемся. Оставляя заявку вы соглашаетесь с публичной офертой",
  "-YIL": " г",
  Royxatdan_muvaffaqiyatli_otdingiz: "Вы успешно зарегистрировались!",
  Tez_orada_siz_bilan_boglanamiz: "Свяжемся с вами в ближайшее время.",
  BILETNI_YUKLAB_OLISH: "СКАЧАТЬ БИЛЕТ",
  Uy_olish: "Получить дом",
  Tomosha: "Смотреть",
  Uy_sotish_quruvchi: "Продажа домов (застройщик)",
  Boshqa_biznes: "Другой бизнес",
}

export const langs = (lang?: string) => {
  const obj: Record<string, typeof uz> = {
    uz,
    ru,
  }
  return obj[lang || "ru"]
}
