import React, { useEffect, useRef } from "react";
import { Input, InputRef, Spin } from "antd";
import CheckIcon from "assets/icons/CheckIcon";
import Location1Icon from "assets/icons/Location1Icon";
import SearchIcon from "assets/icons/SearchIcon";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useRouter } from "next/router";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { cx } from "utils/cx";
import { layoutLang } from "utils/language/layout";
import { useGetAllRegion } from "pages/main-layout/components/region-city/services/queries";
import styles from "../searchLocation.module.scss";
import MiniCloseIcon from "assets/icons/MiniCloseIcon";
interface IProps {
  val: string;
  type: "filter" | "desktop" | "main" | undefined;
  handleChangeSearch: (value: string) => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
}
const LocationOverlay: React.FC<IProps> = ({
  type,
  handleChangeSearch,
  val,
  setVisible,
  visible
}) => {
  const dispatch = useAppDispatch();
  const {
    locale
  } = useRouter();
  const {
    setCurrentRegion
  } = favouritesReducerActions;
  const location = useAppSelector(state => state.favouritesReducer.location);
  const inputRef = useRef<InputRef>(null);
  const finalLocation = type === "main" ? location?.mainRegionValue : location?.regionValue;
  const {
    data,
    isFetching
  } = useGetAllRegion(location?.searchValue);
  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current?.focus();
      }
    }, 100);
  }, [inputRef, visible]);
  return <div className={`border border-solid border-[#EAECF0] rounded-xl bg-white w-[380px] mobileTopFilter ${type !== "filter" && 'mt-4'}`} style={{
    boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814"
  }} data-sentry-component="LocationOverlay" data-sentry-source-file="LocationOverlay.tsx">
      {type !== "filter" && <div className="px-2 pt-2">
          <Input prefix={<SearchIcon color="#98A2B3" size="18" />} placeholder={`${layoutLang[String(locale)]["Qidirish"]} ...`} value={val} onChange={e => {
        handleChangeSearch(e?.target?.value);
      }} className="h-[43px] rounded-xl border-t border-solid border-[#EAECF0] shadow-none px-4" ref={inputRef} allowClear={{
        clearIcon: <div onClick={() => {
          dispatch(setCurrentRegion({
            type: type === "main" ? "main" : "location",
            value: {
              city: null,
              district: null
            }
          }));
        }} className="w-4 h-4 rounded-full flex items-center justify-center bg-[#D0D5DD]">
                <MiniCloseIcon />
              </div>
      }} />
        </div>}
      <div className="flex flex-col py-3 px-2 bg-white min-h-10">
        <Spin spinning={isFetching} data-sentry-element="Spin" data-sentry-source-file="LocationOverlay.tsx">
          <div className={cx("max-h-[300px] overflow-y-auto", styles.regions)}>
            <div className="flex items-center justify-between gap-3 px-1 pb-3 border-b border-solid border-[#EAECF0] cursor-pointer w-full" onClick={() => {
            dispatch(setCurrentRegion({
              type: type === "main" ? "main" : "location",
              value: undefined
            }));
            setVisible(false);
          }}>
              <div className="flex items-center justify-between gap-3">
                <Location1Icon data-sentry-element="Location1Icon" data-sentry-source-file="LocationOverlay.tsx" />
                <div className="flex flex-col">
                  <span className="text-[#344054] font-medium">
                    {layoutLang[String(locale)]["Butun_Ozbekiston_boylab"]}
                  </span>
                </div>
              </div>
              {finalLocation?.city === null && finalLocation?.district === null && <CheckIcon />}
            </div>
            {data?.map((item, index) => <div key={index}>
                {item?.district ? <div className="flex items-center justify-between gap-3 px-1 py-3 border-b border-solid  border-[#EAECF0] cursor-pointer" onClick={() => {
              dispatch(setCurrentRegion({
                type: type === "main" ? "main" : "location",
                value: item
              }));
              setVisible(false);
            }}>
                    <div className="flex items-center gap-3">
                      <Location1Icon />
                      <div className="flex flex-col">
                        <span className="text-[#344054] font-medium">
                          {item?.district?.name?.[locale as keyof typeof item.district.name]}
                        </span>
                        <span className="text-[#667085] text-xs">
                          {item?.district?.city?.name[locale as keyof typeof item.district.city.name]}
                        </span>
                      </div>
                    </div>
                    {finalLocation?.district?.id === item?.district?.id && <CheckIcon />}
                  </div> : <div className="flex items-center justify-between gap-3 px-1 py-3 border-b border-solid border-[#EAECF0] cursor-pointer" onClick={() => {
              dispatch(setCurrentRegion({
                type: type === "main" ? "main" : "location",
                value: item
              }));
              setVisible(false);
            }}>
                    <div className="flex items-center gap-3">
                      <Location1Icon />
                      <span>
                        {item?.city?.name[locale as keyof typeof item.city.name]}
                      </span>
                    </div>
                    {finalLocation?.city?.id === item?.city?.id && <CheckIcon />}
                  </div>}
              </div>)}
          </div>
        </Spin>
      </div>
    </div>;
};
export default LocationOverlay;