import { useTranslations } from "next-intl";
import React from "react";
import style from "./MainTop.module.scss";
import MainFilter from "../main-filter/MainFilter";
import { useCheckAuth } from "pages/main-layout/services/queries";
import Banner from "../main-filter/banner/Banner";
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper";
import { BannerImageType, BannerPosition, IBanner, IBannerImage } from "pages/app/utils/models/banner";
import { useRouter } from "next/router";
import { useBanners } from "pages/app/services/queries";
type Props = {
  banner: Partial<Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>>;
};
const MainTop: React.FC<Props> = () => {
  const {
    locale
  } = useRouter();
  const t = useTranslations("body.mainTop");
  useCheckAuth();
  const {
    data: banners
  } = useBanners('main', locale!);
  return <section className={`${style.main_top} watch`} id="main_top" data-sentry-component="MainTop" data-sentry-source-file="MainTop.tsx">
      <div className="wrapper flex flex-col items-center relative">
        <div className="desctop_only">
          <div className={style.reklama}>
            <div className={style.reklama_bg}>
              <div className={style.reklama_bg_main}></div>
            </div>
          </div>
        </div>
        <div className={`${style.main_top_top} w-full`}>
          <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="MainTop.tsx">
            <div className="mobile_only">
              <div className={style.reklama}>
                <div className="relative h-full w-full">
                  <div className={`absolute left-0 top-0 h-full w-full ${style.reklama_back}`} />
                </div>
                <div className="absolute bottom-5 left-0 w-full sm:w-max z-[2]">
                  <Banner banner={banners?.top} data-sentry-element="Banner" data-sentry-source-file="MainTop.tsx" />
                </div>
              </div>
            </div>
          </NonSSRWrapper>
          <div className="flex w-full h-full flex-col items-center justify-center pt-[60px] mb-24">
            <h1 className="!md:text-center">{t("Найди дом своей мечты прямо сейчас")}</h1>

            <MainFilter data-sentry-element="MainFilter" data-sentry-source-file="MainTop.tsx" />
          </div>
          <div className="hidden absolute top-28 md:block">
            <Banner banner={banners?.top} data-sentry-element="Banner" data-sentry-source-file="MainTop.tsx" />
          </div>
        </div>
      </div>
    </section>;
};
export default MainTop;