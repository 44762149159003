import React from "react";
interface IProps {
  stroke?: string;
  className?: string;
}
const ArrowBottom: React.FC<IProps> = ({
  stroke = "#616161",
  className
}) => {
  return <svg className={className} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ArrowBottom" data-sentry-source-file="ArrowBottom.tsx">
      <path d="M4.5 7.25L9 11.75L13.5 7.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ArrowBottom.tsx" />
    </svg>;
};
export default ArrowBottom;