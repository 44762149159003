import React from "react";
import { useTranslations } from "next-intl";
import { useGetTopBuilders } from "../../services/queries";
import BuilderCard from "../../../../common/builderCard/BuilderCard";
import BuildersQuiz from "../builders-quiz/BuildersQuiz";
import styles from "./MainTopBuilders.module.scss";
const MainTopBuilders: React.FC = () => {
  const t = useTranslations("body.topBuilders");
  const {
    data
  } = useGetTopBuilders();
  return <section className={styles.container} data-sentry-component="MainTopBuilders" data-sentry-source-file="MainTopBuilders.tsx">
      <div className="wrapper">
        <div className={styles.container_header}>
          <h2>{t("Топовые застройщики")}</h2>
        </div>
        <div className={styles.container_body}>
          {data?.map((item, index) => <BuilderCard {...item} key={index} />)}
        </div>
        <BuildersQuiz data-sentry-element="BuildersQuiz" data-sentry-source-file="MainTopBuilders.tsx" />
      </div>
    </section>;
};
export default MainTopBuilders;