import { useTranslations } from "next-intl";
import React from "react";
import PaginationNextIcon from "../../../../assets/icons/PaginationNextIcon";
import MagazinesCont from "../../../../common/magazinesCont/MagazinesCont";
import { rootPaths } from "../../../../utils/constants/rootPaths";
import { useGetTopMagazines } from "../../services/queries";
import styles from "./MainMagazines.module.scss";
import MagazinesMobile from "pages/main/components/magazines/MagazinesMobile";
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper";
import CustomLink from "common/custom-link";
import { layoutLang } from "utils/language/layout";
import { useRouter } from "next/router";
const MainMagazines: React.FC = () => {
  const {
    locale
  } = useRouter();
  const t = useTranslations("body.magazines");
  const {
    data
  } = useGetTopMagazines();
  return <section className={styles.container} data-sentry-component="MainMagazines" data-sentry-source-file="MainMagazines.tsx">
      <div className="wrapper">
        <div className={styles.container_header}>
          <h2 className={styles.title}>{t("Журнал UYSOT")}</h2>
          <CustomLink href={rootPaths.MAGAZINES} data-sentry-element="CustomLink" data-sentry-source-file="MainMagazines.tsx">
            <span>
            {layoutLang[String(locale)]['Hammasi']} <PaginationNextIcon data-sentry-element="PaginationNextIcon" data-sentry-source-file="MainMagazines.tsx" />
            </span>
          </CustomLink>
        </div>
        <div className="desctop_only">
          <MagazinesCont magazines={data} data-sentry-element="MagazinesCont" data-sentry-source-file="MainMagazines.tsx" />
        </div>
        <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="MainMagazines.tsx">
          <div className="mobile_only">
            <MagazinesMobile magazines={data} data-sentry-element="MagazinesMobile" data-sentry-source-file="MainMagazines.tsx" />
          </div>
        </NonSSRWrapper>
      </div>
    </section>;
};
export default MainMagazines;