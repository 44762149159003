const RegCheckIcon = ({
  stroke = "#00A389",
  size = 24
}: {
  stroke?: string;
  size?: number;
}) => {
  return <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="RegCheckIcon" data-sentry-source-file="RegCheckIcon.tsx">
      <path d="M10.8334 3.25L4.87502 9.20833L2.16669 6.5" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="RegCheckIcon.tsx" />
    </svg>;
};
export default RegCheckIcon;