import React from "react";
import { QuizStateModel } from "../utils/models/quizStateModel";
import { layoutLang } from "utils/language/layout";
import { itemStyle } from "../Quiz";
import { useRouter } from "next/router";
import JKArrowLeftIcon from "assets/icons/JKArrowLeftIcon";
import { Button } from "antd";
import { PaymentModel } from "../utils/models/paymentModel";
interface IProps {
  quizState: QuizStateModel;
  setQuizState: React.Dispatch<React.SetStateAction<QuizStateModel>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  finalPayment: PaymentModel[];
  setTotalAmount: React.Dispatch<React.SetStateAction<string>>;
  total_amount: string;
}
const QuizComponent4: React.FC<IProps> = ({
  page,
  quizState,
  setPage,
  setQuizState,
  finalPayment,
  setTotalAmount,
  total_amount
}) => {
  const {
    locale
  } = useRouter();
  return <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0" data-sentry-component="QuizComponent4" data-sentry-source-file="QuizComponent4.tsx">
      <div className="min-h-[305px]">
        <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${quizState?.payment_method_id === 2 ? 6 : 5} ${layoutLang[String(locale)]["savol"]}`}</p>
        <h2 className="text-[#344054] text-2xl font-medium">
          {quizState?.payment_method_id === 2 ? layoutLang[String(locale)]["boshlangich_tolov_oraligi"] : layoutLang[String(locale)]["narx_oraligi"]}
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5 h-[70%] overflow-auto">
          {finalPayment?.map(item => {
          return <div key={item?.id} className={`${item?.value === total_amount ? "bg-[#EEFBF6]" : ""} ${item?.value === total_amount ? "!border-[#00A389]" : ""} ${itemStyle}`} onClick={() => {
            item?.value === total_amount ? setTotalAmount("") : setTotalAmount(item?.value);
          }}>
                {`${locale === "ru" && (item?.value === "50" || item?.value === "200") ? "до" : ""} ${item?.id === 5 && quizState?.payment_method_id !== 2 ? item?.value?.replace("mln", layoutLang[String(locale)]["mln"])?.replace("mlrd", layoutLang[String(locale)]["mlrd"]) : item?.value} ${item?.id === 5 && quizState?.payment_method_id !== 2 ? "" : layoutLang[String(locale)][item?.type]} ${locale === "uz" && (item?.value === "50" || item?.value === "200") ? "gacha" : ""}`}
              </div>;
        })}
        </div>
      </div>
      <div className="flex items-center gap-3 w-full mt-[68px]">
        <div onClick={() => {
        setPage(page - 1);
        setQuizState(state => ({
          ...state,
          monthly_amount: ""
        }));
        setTotalAmount("");
      }} className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base">
          <JKArrowLeftIcon fill="#475467" width={18} height={14} data-sentry-element="JKArrowLeftIcon" data-sentry-source-file="QuizComponent4.tsx" />
          <span className="text-base text-[#475467] font-medium">
            {layoutLang[String(locale)]["ortga"]}
          </span>
        </div>
        <Button type="primary" onClick={() => quizState?.payment_method_id === 2 ? setPage(page + 1) : setPage(page + 2)} className="w-full h-[47px] rounded-xl flex items-center justify-center text-base" disabled={!total_amount} data-sentry-element="Button" data-sentry-source-file="QuizComponent4.tsx">
          {layoutLang[String(locale)]["keyingisi"]}
        </Button>
      </div>
    </div>;
};
export default QuizComponent4;