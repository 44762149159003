import React, { useEffect, useRef, useState } from 'react';
import { imageLoadings } from 'utils/constants/imageAttributes';
import { cx } from 'utils/cx';
import { isBrowser } from 'utils/helpers/isBrowser';
interface CustomImageProps {
  src: string;
  alt: string;
  layout?: 'fixed' | 'intrinsic' | 'responsive' | 'fill';
  priority?: boolean;
  className?: string;
  title?: string;
}
const CustomImage: React.FC<CustomImageProps> = props => {
  const {
    src,
    alt,
    className,
    ...rest
  } = props;
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const imageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (imgRef.current) {
            setIsLoading(true);
            imgRef.current.setAttribute('data-src', src);
            imgRef.current.setAttribute('src', src);
            observer.unobserve(imgRef.current);
          }
        }
      });
    }, {
      threshold: Array.from({
        length: 1000
      }, (_, i) => i / 1000)
    });
    if (imgRef.current) {
      imageObserver.observe(imgRef.current);
    }
    return () => imageObserver.disconnect();
  }, [src]);
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return isBrowser() ? <div className={`relative h-full w-full`} data-sentry-component="CustomImage" data-sentry-source-file="index.tsx">
      {isLoading && <div className="absolute inset-0 bg-gray-200 animate-pulse h-full w-full"></div>}
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img className={cx('w-full h-full object-cover transition-opacity duration-300', isLoading ? 'invisible' : 'visible', className)} ref={imgRef} loading={imageLoadings.LAZY} alt={alt} onLoad={handleImageLoad} onError={handleImageLoad} {...rest} src="" data-src={src} />
    </div> : (/* eslint-disable-next-line @next/next/no-img-element */
  <div className={`relative h-full w-full`} data-sentry-component="CustomImage" data-sentry-source-file="index.tsx">
      {isLoading && <div className="absolute inset-0 bg-gray-200 animate-pulse h-full w-full"></div>}
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img className={cx('w-full h-full object-cover transition-opacity duration-300', isLoading ? 'invisible' : 'visible', className)} ref={imgRef} loading={imageLoadings.LAZY} alt={alt} onLoad={handleImageLoad} onError={handleImageLoad} {...rest} src={src} />
    </div>);
};
export default CustomImage;