const AppStoreIcon = () => {
  return <svg width="133" height="39" viewBox="0 0 133 39" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="AppStoreIcon" data-sentry-source-file="app-store-icon.tsx">
      <path d="M132.151 33.9626C132.151 36.0085 130.459 37.6657 128.367 37.6657H4.52742C2.43594 37.6657 0.738281 36.0085 0.738281 33.9626V4.43201C0.738281 2.38711 2.43594 0.724121 4.52742 0.724121H128.366C130.459 0.724121 132.15 2.38711 132.15 4.43201L132.151 33.9626Z" fill="black" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M127.967 0.768901C130.246 0.768901 132.101 2.57684 132.101 4.79868V33.5914C132.101 35.8132 130.246 37.6211 127.967 37.6211H4.92183C2.64302 37.6211 0.788722 35.8132 0.788722 33.5914V4.79868C0.788722 2.57684 2.64302 0.768901 4.92183 0.768901H127.967ZM127.967 -0.000101122H4.92183C2.21605 -0.000101122 0 2.16055 0 4.79868V33.5914C0 36.2295 2.21605 38.3901 4.92183 38.3901H127.967C130.673 38.3901 132.889 36.2295 132.889 33.5914V4.79868C132.889 2.16055 130.673 -0.000101122 127.967 -0.000101122Z" fill="#A6A6A6" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M29.657 18.9883C29.6284 15.895 32.2547 14.3901 32.3748 14.32C30.8874 12.2057 28.582 11.9168 27.7719 11.8938C25.8357 11.6951 23.9575 13.0234 22.9712 13.0234C21.9651 13.0234 20.4463 11.913 18.8093 11.9456C16.7027 11.9773 14.732 13.1664 13.6512 15.013C11.4206 18.7781 13.0842 24.3111 15.2213 27.3545C16.2903 28.845 17.5394 30.5092 19.1745 30.4507C20.7741 30.3864 21.3716 29.4564 23.3019 29.4564C25.2145 29.4564 25.7756 30.4507 27.4431 30.4132C29.1599 30.3864 30.2407 28.916 31.2723 27.4121C32.5077 25.7037 33.0038 24.0213 33.0235 23.9349C32.9831 23.9214 29.6895 22.6958 29.657 18.9883Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M26.507 9.89173C27.3673 8.84271 27.956 7.41556 27.7926 5.96729C26.5474 6.02103 24.9901 6.80707 24.0933 7.83305C23.2999 8.73714 22.5912 10.219 22.7743 11.6126C24.1731 11.7143 25.6093 10.9244 26.507 9.89173Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M52.8063 30.2363H50.5708L49.3462 26.4846H45.0899L43.9234 30.2363H41.7469L45.964 17.4639H48.5686L52.8063 30.2363ZM48.9771 24.9106L47.8697 21.5755C47.7526 21.2347 47.533 20.4324 47.2092 19.1694H47.1698C47.0409 19.7126 46.8332 20.5149 46.5477 21.5755L45.46 24.9106H48.9771Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M63.6511 25.5181C63.6511 27.0845 63.217 28.3225 62.3488 29.2314C61.5711 30.0405 60.6055 30.4446 59.4528 30.4446C58.2085 30.4446 57.3147 30.0088 56.7704 29.1374H56.731V33.9889H54.6323V24.0583C54.6323 23.0736 54.6058 22.063 54.5546 21.0265H56.4003L56.5174 22.4863H56.5568C57.2566 21.3864 58.3188 20.8374 59.7441 20.8374C60.8584 20.8374 61.7887 21.2664 62.5328 22.1254C63.279 22.9853 63.6511 24.1159 63.6511 25.5181ZM61.513 25.593C61.513 24.6966 61.3063 23.9576 60.8909 23.376C60.4371 22.7694 59.8278 22.4661 59.0639 22.4661C58.5462 22.4661 58.0756 22.635 57.6553 22.9681C57.234 23.304 56.9584 23.7426 56.8294 24.2858C56.7645 24.5392 56.732 24.7465 56.732 24.9096V26.4453C56.732 27.1152 56.9426 27.6805 57.3639 28.1421C57.7853 28.6037 58.3326 28.8341 59.0059 28.8341C59.7963 28.8341 60.4115 28.5366 60.8515 27.9434C61.2925 27.3493 61.513 26.5662 61.513 25.593Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M74.5155 25.5181C74.5155 27.0845 74.0814 28.3225 73.2122 29.2314C72.4355 30.0405 71.4698 30.4446 70.3171 30.4446C69.0729 30.4446 68.1791 30.0088 67.6357 29.1374H67.5964V33.9889H65.4977V24.0583C65.4977 23.0736 65.4711 22.063 65.4199 21.0265H67.2656L67.3827 22.4863H67.4221C68.121 21.3864 69.1832 20.8374 70.6095 20.8374C71.7228 20.8374 72.653 21.2664 73.3992 22.1254C74.1424 22.9853 74.5155 24.1159 74.5155 25.5181ZM72.3774 25.593C72.3774 24.6966 72.1697 23.9576 71.7543 23.376C71.3005 22.7694 70.6932 22.4661 69.9283 22.4661C69.4096 22.4661 68.94 22.635 68.5187 22.9681C68.0974 23.304 67.8228 23.7426 67.6938 24.2858C67.6298 24.5392 67.5964 24.7465 67.5964 24.9096V26.4453C67.5964 27.1152 67.807 27.6805 68.2263 28.1421C68.6477 28.6028 69.195 28.8341 69.8702 28.8341C70.6607 28.8341 71.2759 28.5366 71.7159 27.9434C72.1569 27.3493 72.3774 26.5662 72.3774 25.593Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M86.6624 26.6546C86.6624 27.7411 86.2756 28.625 85.4989 29.3074C84.6455 30.0531 83.4574 30.4255 81.9306 30.4255C80.521 30.4255 79.3909 30.1606 78.5355 29.6299L79.0218 27.9244C79.9432 28.4676 80.9541 28.7402 82.0556 28.7402C82.8461 28.7402 83.4613 28.5655 83.9033 28.2181C84.3433 27.8706 84.5628 27.4042 84.5628 26.8226C84.5628 26.3043 84.3817 25.8676 84.0184 25.5135C83.6572 25.1593 83.0538 24.8301 82.2111 24.5259C79.9176 23.6919 78.7718 22.4701 78.7718 20.8635C78.7718 19.8135 79.1734 18.9526 79.9776 18.2827C80.7789 17.6118 81.8479 17.2769 83.1847 17.2769C84.3767 17.2769 85.367 17.4794 86.1575 17.8834L85.6328 19.5515C84.8945 19.1599 84.0598 18.9641 83.1256 18.9641C82.3873 18.9641 81.8105 19.1417 81.3971 19.4949C81.0476 19.8106 80.8724 20.1955 80.8724 20.6514C80.8724 21.1562 81.0722 21.5737 81.4739 21.9019C81.8233 22.2052 82.4582 22.5334 83.3796 22.8876C84.5067 23.33 85.3345 23.8473 85.8671 24.4405C86.3976 25.0317 86.6624 25.7717 86.6624 26.6546Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M93.6013 22.5618H91.288V27.0333C91.288 28.1706 91.6955 28.7388 92.5126 28.7388C92.8876 28.7388 93.1987 28.7071 93.4447 28.6438L93.5028 30.1977C93.0894 30.3483 92.545 30.4242 91.8707 30.4242C91.0419 30.4242 90.3942 30.1775 89.9266 29.6851C89.461 29.1918 89.2267 28.3645 89.2267 27.2023V22.5599H87.8486V21.0243H89.2267V19.338L91.288 18.7314V21.0243H93.6013V22.5618Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M104.039 25.5551C104.039 26.9707 103.623 28.133 102.794 29.0419C101.925 29.9776 100.771 30.4441 99.3333 30.4441C97.9473 30.4441 96.8438 29.9959 96.0209 29.0995C95.198 28.2031 94.7865 27.0715 94.7865 25.7077C94.7865 24.2805 95.2098 23.1116 96.0593 22.2027C96.9068 21.2928 98.0507 20.8379 99.4888 20.8379C100.875 20.8379 101.99 21.2861 102.832 22.1835C103.637 23.054 104.039 24.1778 104.039 25.5551ZM101.861 25.6213C101.861 24.7719 101.675 24.0435 101.298 23.436C100.858 22.7008 100.229 22.3342 99.414 22.3342C98.5704 22.3342 97.9296 22.7017 97.4896 23.436C97.1126 24.0444 96.9265 24.7844 96.9265 25.6597C96.9265 26.5091 97.1126 27.2375 97.4896 27.8441C97.9434 28.5793 98.5773 28.9459 99.3953 28.9459C100.197 28.9459 100.826 28.5716 101.279 27.8249C101.666 27.2059 101.861 26.4697 101.861 25.6213Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M110.86 22.826C110.653 22.7886 110.431 22.7694 110.199 22.7694C109.46 22.7694 108.89 23.041 108.488 23.5852C108.138 24.0651 107.963 24.6716 107.963 25.4039V30.2363H105.866L105.885 23.9269C105.885 22.8654 105.859 21.8989 105.807 21.0274H107.634L107.711 22.7895H107.769C107.991 22.1839 108.34 21.6964 108.819 21.3307C109.286 21.0015 109.791 20.8374 110.336 20.8374C110.53 20.8374 110.705 20.8508 110.86 20.8748V22.826Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M120.246 25.1954C120.246 25.562 120.221 25.871 120.169 26.1234H113.873C113.898 27.0333 114.202 27.7291 114.787 28.209C115.317 28.638 116.003 28.853 116.846 28.853C117.778 28.853 118.629 28.7081 119.394 28.4173L119.722 29.8377C118.829 30.2178 117.773 30.4068 116.556 30.4068C115.091 30.4068 113.941 29.9865 113.105 29.1467C112.27 28.3069 111.851 27.1792 111.851 25.7645C111.851 24.3757 112.24 23.2192 113.019 22.2969C113.834 21.3122 114.935 20.8198 116.321 20.8198C117.683 20.8198 118.713 21.3122 119.413 22.2969C119.968 23.0791 120.246 24.0465 120.246 25.1954ZM118.245 24.6646C118.259 24.058 118.122 23.534 117.837 23.0916C117.474 22.5224 116.916 22.2383 116.165 22.2383C115.479 22.2383 114.921 22.5157 114.494 23.0724C114.145 23.5148 113.937 24.0456 113.873 24.6636H118.245V24.6646Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M48.2831 9.60602C48.2831 10.7357 47.9357 11.586 47.2417 12.1571C46.5989 12.684 45.6854 12.9479 44.5022 12.9479C43.9155 12.9479 43.4135 12.9229 42.9932 12.873V6.70084C43.5415 6.61446 44.1321 6.57031 44.7699 6.57031C45.897 6.57031 46.7466 6.80929 47.3195 7.28725C47.9613 7.82759 48.2831 8.6002 48.2831 9.60602ZM47.1954 9.63385C47.1954 8.90156 46.9966 8.3401 46.5989 7.94852C46.2012 7.5579 45.6204 7.36211 44.8556 7.36211C44.5307 7.36211 44.2541 7.38323 44.0248 7.42737V12.1196C44.1518 12.1388 44.3841 12.1475 44.7217 12.1475C45.5112 12.1475 46.1205 11.9334 46.5497 11.5054C46.9789 11.0773 47.1954 10.4535 47.1954 9.63385Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M54.0503 10.5932C54.0503 11.289 53.8465 11.8591 53.439 12.3064C53.0118 12.7661 52.4458 12.9955 51.739 12.9955C51.0578 12.9955 50.5155 12.7757 50.1109 12.3342C49.7073 11.8937 49.5055 11.338 49.5055 10.6681C49.5055 9.96746 49.7132 9.39256 50.1306 8.94628C50.5479 8.49999 51.109 8.27637 51.8158 8.27637C52.497 8.27637 53.0443 8.49615 53.4587 8.93668C53.8525 9.36473 54.0503 9.91755 54.0503 10.5932ZM52.9803 10.6258C52.9803 10.2084 52.8878 9.85037 52.7037 9.55188C52.4871 9.19101 52.179 9.01058 51.7784 9.01058C51.364 9.01058 51.049 9.19101 50.8324 9.55188C50.6474 9.85037 50.5558 10.2141 50.5558 10.6441C50.5558 11.0616 50.6483 11.4196 50.8324 11.7181C51.0559 12.0789 51.3669 12.2594 51.7686 12.2594C52.1623 12.2594 52.4714 12.076 52.6939 11.7085C52.8848 11.4042 52.9803 11.0433 52.9803 10.6258Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M61.7836 8.36816L60.3317 12.8925H59.3867L58.7853 10.9278C58.6327 10.4374 58.5087 9.94984 58.4122 9.46613H58.3935C58.3039 9.96328 58.1799 10.4499 58.0204 10.9278L57.3816 12.8925H56.4257L55.0604 8.36816H56.1206L56.6452 10.519C56.7722 11.0276 56.8766 11.5123 56.9602 11.9711H56.9789C57.0557 11.5929 57.1827 11.1111 57.3619 10.5286L58.0204 8.36912H58.8611L59.492 10.4825C59.6446 10.9979 59.7686 11.4941 59.8641 11.972H59.8927C59.9626 11.5066 60.0679 11.0104 60.2077 10.4825L60.7707 8.36912H61.7836V8.36816Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M67.1315 12.8923H66.0999V10.3009C66.0999 9.5024 65.7888 9.10314 65.1647 9.10314C64.8586 9.10314 64.6115 9.21255 64.4196 9.43233C64.2296 9.65212 64.1331 9.91125 64.1331 10.2078V12.8913H63.1015V9.66076C63.1015 9.26342 63.0887 8.83249 63.0641 8.36605H63.9707L64.0189 9.07339H64.0475C64.1676 8.8536 64.3467 8.67221 64.582 8.52728C64.8615 8.35837 65.1746 8.27295 65.5171 8.27295C65.9502 8.27295 66.3105 8.40923 66.597 8.68277C66.9533 9.01772 67.1315 9.51775 67.1315 10.1819V12.8923Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M69.9764 12.8922H68.9458V6.29199H69.9764V12.8922Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M76.0499 10.5932C76.0499 11.289 75.8462 11.8591 75.4387 12.3064C75.0114 12.7661 74.4444 12.9955 73.7387 12.9955C73.0565 12.9955 72.5141 12.7757 72.1105 12.3342C71.7069 11.8937 71.5051 11.338 71.5051 10.6681C71.5051 9.96746 71.7128 9.39256 72.1302 8.94628C72.5476 8.49999 73.1087 8.27637 73.8144 8.27637C74.4966 8.27637 75.0429 8.49615 75.4583 8.93668C75.8521 9.36473 76.0499 9.91755 76.0499 10.5932ZM74.979 10.6258C74.979 10.2084 74.8864 9.85037 74.7023 9.55188C74.4868 9.19101 74.1777 9.01058 73.778 9.01058C73.3626 9.01058 73.0476 9.19101 72.8321 9.55188C72.647 9.85037 72.5554 10.2141 72.5554 10.6441C72.5554 11.0616 72.648 11.4196 72.8321 11.7181C73.0555 12.0789 73.3666 12.2594 73.7682 12.2594C74.1619 12.2594 74.47 12.076 74.6925 11.7085C74.8845 11.4042 74.979 11.0433 74.979 10.6258Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M81.0426 12.8928H80.1164L80.0396 12.3716H80.011C79.6941 12.7872 79.2422 12.9955 78.6556 12.9955C78.2175 12.9955 77.8632 12.8582 77.5964 12.5857C77.3542 12.3381 77.2332 12.03 77.2332 11.6643C77.2332 11.1115 77.4694 10.6902 77.9448 10.3984C78.4193 10.1066 79.0867 9.96362 79.9461 9.97034V9.88588C79.9461 9.28987 79.6252 8.99235 78.9824 8.99235C78.5246 8.99235 78.1211 9.10464 77.7726 9.3273L77.5629 8.66699C77.9941 8.40689 78.5266 8.27637 79.1546 8.27637C80.3674 8.27637 80.9757 8.90021 80.9757 10.1479V11.814C80.9757 12.2661 80.9983 12.626 81.0426 12.8928ZM79.9717 11.338V10.6402C78.8337 10.6211 78.2648 10.9253 78.2648 11.552C78.2648 11.7881 78.3297 11.9647 78.4626 12.0828C78.5955 12.2008 78.7648 12.2594 78.9666 12.2594C79.193 12.2594 79.4047 12.1893 79.5976 12.0501C79.7915 11.91 79.9106 11.7324 79.9549 11.5146C79.9657 11.4656 79.9717 11.4061 79.9717 11.338Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M86.9045 12.8922H85.989L85.9408 12.1657H85.9122C85.6199 12.7185 85.1218 12.9949 84.4219 12.9949C83.8628 12.9949 83.3972 12.7809 83.0281 12.3529C82.6589 11.9248 82.4749 11.3691 82.4749 10.6867C82.4749 9.95442 82.6747 9.36129 83.0763 8.90829C83.4651 8.48599 83.9416 8.27485 84.5085 8.27485C85.1316 8.27485 85.5677 8.47928 85.8158 8.88909H85.8355V6.29199H86.8681V11.6733C86.8681 12.1139 86.8799 12.5198 86.9045 12.8922ZM85.8355 10.9842V10.2299C85.8355 10.0993 85.8256 9.99377 85.8069 9.91315C85.7488 9.67129 85.6238 9.46782 85.4338 9.30371C85.2419 9.13959 85.0106 9.05705 84.7438 9.05705C84.3589 9.05705 84.0577 9.20581 83.8362 9.5043C83.6167 9.80278 83.5055 10.1838 83.5055 10.6493C83.5055 11.0965 83.6108 11.4593 83.8224 11.7386C84.0459 12.0361 84.3471 12.1849 84.7241 12.1849C85.0627 12.1849 85.3334 12.0611 85.5392 11.8125C85.738 11.5831 85.8355 11.3067 85.8355 10.9842Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M95.7273 10.5932C95.7273 11.289 95.5235 11.8591 95.116 12.3064C94.6888 12.7661 94.1238 12.9955 93.416 12.9955C92.7358 12.9955 92.1934 12.7757 91.7879 12.3342C91.3843 11.8937 91.1825 11.338 91.1825 10.6681C91.1825 9.96746 91.3902 9.39256 91.8076 8.94628C92.2249 8.49999 92.786 8.27637 93.4938 8.27637C94.174 8.27637 94.7223 8.49615 95.1357 8.93668C95.5295 9.36473 95.7273 9.91755 95.7273 10.5932ZM94.6583 10.6258C94.6583 10.2084 94.5658 9.85037 94.3817 9.55188C94.1641 9.19101 93.857 9.01058 93.4554 9.01058C93.042 9.01058 92.727 9.19101 92.5094 9.55188C92.3244 9.85037 92.2328 10.2141 92.2328 10.6441C92.2328 11.0616 92.3253 11.4196 92.5094 11.7181C92.7329 12.0789 93.0439 12.2594 93.4456 12.2594C93.8393 12.2594 94.1494 12.076 94.3718 11.7085C94.5618 11.4042 94.6583 11.0433 94.6583 10.6258Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M101.274 12.8923H100.244V10.3009C100.244 9.5024 99.9326 9.10314 99.3075 9.10314C99.0014 9.10314 98.7543 9.21255 98.5634 9.43233C98.3724 9.65212 98.2769 9.91125 98.2769 10.2078V12.8913H97.2443V9.66076C97.2443 9.26342 97.2325 8.83249 97.2079 8.36605H98.1135L98.1617 9.07339H98.1903C98.3114 8.8536 98.4905 8.67221 98.7248 8.52728C99.0053 8.35837 99.3174 8.27295 99.6609 8.27295C100.093 8.27295 100.453 8.40923 100.74 8.68277C101.097 9.01772 101.274 9.51775 101.274 10.1819V12.8923Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M108.217 9.12186H107.081V11.3197C107.081 11.8783 107.283 12.1576 107.683 12.1576C107.868 12.1576 108.021 12.1422 108.142 12.1105L108.169 12.8735C107.965 12.9484 107.697 12.9858 107.368 12.9858C106.96 12.9858 106.643 12.8649 106.414 12.6231C106.183 12.3812 106.069 11.9743 106.069 11.4032V9.12186H105.391V8.36845H106.069V7.53922L107.08 7.2417V8.36749H108.216V9.12186H108.217Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M113.678 12.8922H112.646V10.3201C112.646 9.50909 112.335 9.10312 111.712 9.10312C111.233 9.10312 110.906 9.33826 110.727 9.80854C110.697 9.90739 110.679 10.0283 110.679 10.1704V12.8913H109.648V6.29199H110.679V9.01866H110.699C111.023 8.52247 111.489 8.27485 112.093 8.27485C112.52 8.27485 112.873 8.41113 113.154 8.68466C113.503 9.02538 113.678 9.53213 113.678 10.202V12.8922Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
      <path d="M119.312 10.4165C119.312 10.5969 119.298 10.7485 119.273 10.8724H116.18C116.193 11.3196 116.341 11.6603 116.627 11.8964C116.889 12.1076 117.227 12.2131 117.64 12.2131C118.098 12.2131 118.515 12.1421 118.891 11.9991L119.053 12.6978C118.613 12.884 118.095 12.9771 117.496 12.9771C116.777 12.9771 116.211 12.7708 115.802 12.3581C115.39 11.9454 115.186 11.3916 115.186 10.6967C115.186 10.0143 115.376 9.44616 115.759 8.99315C116.159 8.50944 116.699 8.26758 117.381 8.26758C118.049 8.26758 118.556 8.50944 118.898 8.99315C119.175 9.37706 119.312 9.85213 119.312 10.4165ZM118.327 10.1564C118.335 9.85789 118.267 9.60068 118.128 9.38377C117.948 9.10449 117.676 8.96436 117.307 8.96436C116.97 8.96436 116.695 9.10065 116.486 9.37418C116.314 9.59204 116.213 9.85213 116.18 10.1564H118.327Z" fill="white" data-sentry-element="path" data-sentry-source-file="app-store-icon.tsx" />
    </svg>;
};
export default AppStoreIcon;