import React, { useEffect, useRef, useState } from "react";
import ArrowIcon from "../../../assets/icons/ArrowIcon";
import { Col, Dropdown, Input, InputRef, Row, Tabs } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { favouritesReducerActions } from "../../../store/reducers/favouritesReducer";
import { useRouter } from "next/router";
import { localeFormatter } from "utils/helpers/localeFormatter";
import styles from "./filterPriceModal.module.scss";
import { layoutLang } from "utils/language/layout";
import { maxInitPrice, maxPerPrice, maxPrice, minInitPrice, minPerPrice, minPrice, maxMonthlyPrice, minMonthlyPrice, maxUSDInitPrice, minUSDPrice, maxUSDPrice, minUSDPerPrice, maxUSDPerPrice, minUSDInitPrice, minUSDMonthlyPrice, maxUSDMonthlyPrice } from "../../../pages/main/components/main-filter/mainFilterItems";
import { filterPriceSeparator } from "utils/helpers/filterPriceSeparator";
import { USD } from "utils/constants/locales";
import { parseLocaledString } from "utils/helpers/parseLocaledString";
const FilterPriceModal = ({
  type
}: {
  type: "main" | "complex";
}) => {
  const dispatch = useAppDispatch();
  const {
    query,
    locale,
    pathname
  } = useRouter();
  const filter = useAppSelector(state => state.favouritesReducer.filter);
  const currency = useAppSelector(state => state.localeReducer.currency);
  const usdValue = useAppSelector(state => state.localeReducer.usdValue);
  const [activeTab, setActiveTab] = useState('1');
  const [prices, setPrices] = useState<{
    minVal: number;
    maxVal: number;
  }>({
    minVal: 0,
    maxVal: 0
  });
  const [activeInput, setActiveInput] = useState<string>('input2');
  const [openDrop, setOpenDrop] = useState(false);
  const inputRef1 = useRef<InputRef>(null);
  const inputRef2 = useRef<InputRef>(null);
  const inputRef3 = useRef<InputRef>(null);
  const inputRef4 = useRef<InputRef>(null);
  const inputRef5 = useRef<InputRef>(null);
  const inputRef6 = useRef<InputRef>(null);
  const inputRef7 = useRef<InputRef>(null);
  const inputRef8 = useRef<InputRef>(null);
  const {
    setMaxTotalPrice,
    setMinTotalPrice,
    setMinAreaPrice,
    setMaxAreaPrice,
    setMinPrePayment,
    setMaxPrePayment,
    setMinMonthlyPayment,
    setMaxMonthlyPayment,
    setClearFilterPrices
  } = favouritesReducerActions;
  const setQueryParams = {
    min_total_price: setMinTotalPrice,
    max_total_price: setMaxTotalPrice,
    min_prepayment: setMinPrePayment,
    max_prepayment: setMaxPrePayment,
    min_price: setMinAreaPrice,
    max_price: setMaxAreaPrice,
    min_monthly_price: setMinMonthlyPayment,
    max_monthly_price: setMaxMonthlyPayment
  };
  useEffect(() => {
    Object.entries(query).forEach(([key, value]) => {
      const action = setQueryParams[key as keyof typeof setQueryParams];
      if (action && value) {
        dispatch(action(Number(value)));
      }
    });
    const minPrice = Number(query?.min_total_price) || Number(query?.min_price) || Number(query?.min_monthly_price) || Number(query?.min_prepayment) || 0;
    const maxPrice = Number(query?.max_total_price) || Number(query?.max_price) || Number(query?.max_monthly_price) || Number(query?.max_prepayment) || 0;
    const finalMinPrice = currency === USD && minPrice !== 0 ? minPrice / usdValue : minPrice;
    const finalMaxPrice = currency === USD && maxPrice !== 0 ? maxPrice / usdValue : maxPrice;
    setPrices({
      minVal: finalMinPrice,
      maxVal: finalMaxPrice
    });
    if (currency === USD) {
      query?.min_price && dispatch(setMinAreaPrice(Number(query?.min_price) / usdValue));
      query?.max_price && dispatch(setMaxAreaPrice(Number(query?.max_price) / usdValue));
      query?.min_total_price && dispatch(setMinTotalPrice(Number(query?.min_total_price) / usdValue));
      query?.max_total_price && dispatch(setMaxTotalPrice(Number(query?.max_total_price) / usdValue));
      query?.min_prepayment && dispatch(setMinPrePayment(Number(query?.min_prepayment) / usdValue));
      query?.max_prepayment && dispatch(setMaxPrePayment(Number(query?.max_prepayment) / usdValue));
      query?.min_monthly_price && dispatch(setMinMonthlyPayment(Number(query?.min_monthly_price) / usdValue));
      query?.max_monthly_price && dispatch(setMaxMonthlyPayment(Number(query?.max_monthly_price) / usdValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  useEffect(() => {
    if (query?.min_total_price || query?.max_total_price) {
      setActiveTab('1');
      setActiveInput("input2");
    } else if (query?.max_price || query?.min_price) {
      setActiveTab('2');
      setActiveInput("input4");
    } else if (query?.min_prepayment || query?.max_prepayment) {
      setActiveTab('3');
      setActiveInput("input6");
    } else if (query?.min_monthly_price || query?.max_monthly_price) {
      setActiveTab('4');
      setActiveInput("input8");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrop]);
  useEffect(() => {
    dispatch(setClearFilterPrices());
    if (query?.min_total_price) {
      dispatch(setMinTotalPrice(parseLocaledString(query?.min_total_price as string)));
    }
    if (query?.max_total_price) {
      dispatch(setMaxTotalPrice(parseLocaledString(query?.max_total_price as string)));
    }
    if (query?.min_price) {
      dispatch(setMinAreaPrice(parseLocaledString(query?.min_price as string)));
    }
    if (query?.max_price) {
      dispatch(setMaxAreaPrice(parseLocaledString(query?.max_price as string)));
    }
    if (query?.min_prepayment) {
      dispatch(setMinPrePayment(parseLocaledString(query?.min_prepayment as string)));
    }
    if (query?.max_prepayment) {
      dispatch(setMaxPrePayment(parseLocaledString(query?.max_prepayment as string)));
    }
    if (query?.min_monthly_price) {
      dispatch(setMinMonthlyPayment(parseLocaledString(query?.min_monthly_price as string)));
    }
    if (query?.max_monthly_price) {
      dispatch(setMaxMonthlyPayment(parseLocaledString(query?.max_monthly_price as string)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const tabItems = [{
    key: "1",
    label: layoutLang[String(locale)]["narxi"],
    children: <Row gutter={6}>
          <Col span={12}>
            <Input onChange={e => {
          dispatch(setMinTotalPrice(Number(e.target.value?.replace(/\s/g, ""))));
          setPrices(state => ({
            ...state,
            minVal: Number(e.target.value?.replace(/\s/g, ""))
          }));
        }} value={filter?.min_total_price ? localeFormatter(String(filter?.min_total_price)) : ""} placeholder={layoutLang[String(locale)]["dan"]} onFocus={() => setActiveInput("input1")} ref={inputRef1} suffix={currency.toUpperCase()} />
            <div className={`${activeInput === "input1" ? 'flex flex-col gap-1' : 'hidden'}`}>
              {(currency === USD ? minUSDPrice : minPrice)?.map((item, index) => <div className="rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium flex items-center gap-1 group" onClick={() => {
            dispatch(setMinTotalPrice(item));
            setPrices(state => ({
              ...state,
              minVal: Number(item)
            }));
          }} key={index}>
                  <div className={`w-3.5 h-3.5 rounded-full border-2 border-solid group-hover:border-[#00A389] transition-all duration-300 ${filter?.min_total_price === item ? "border-[#00A389]" : "border-[#e9e9e9]"}`} />
                  {item?.toLocaleString("ru")}
                </div>)}
            </div>
          </Col>
          <Col span={12}>
            <Input onChange={e => {
          dispatch(setMaxTotalPrice(Number(e.target.value?.replace(/\s/g, ""))));
          setPrices(state => ({
            ...state,
            maxVal: Number(e.target.value?.replace(/\s/g, ""))
          }));
        }} value={filter?.max_total_price ? localeFormatter(String(filter?.max_total_price)) : ""} placeholder={layoutLang[String(locale)]["gacha"]} onFocus={() => setActiveInput("input2")} ref={inputRef2} suffix={currency.toUpperCase()} />
            <div className={`${activeInput === "input2" ? 'flex flex-col gap-1' : 'hidden'}`}>
              {(currency === USD ? maxUSDPrice : maxPrice)?.map((item, index) => <div className="rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium flex items-center gap-1 group" onClick={() => {
            dispatch(setMaxTotalPrice(item));
            setPrices(state => ({
              ...state,
              maxVal: Number(item)
            }));
          }} key={index}>
                  <div className={`w-3.5 h-3.5 rounded-full border-2 border-solid group-hover:border-[#00A389] transition-all duration-300 ${filter?.max_total_price === item ? "border-[#00A389]" : "border-[#e9e9e9]"}`} />
                  {item?.toLocaleString("ru")}
                </div>)}
            </div>
          </Col>
        </Row>
  }, {
    key: "2",
    label: layoutLang[String(locale)]["narxi_m2"],
    children: <Row gutter={6}>
          <Col span={12}>
            <Input onChange={e => {
          dispatch(setMinAreaPrice(Number(e.target.value?.replace(/\s/g, ""))));
          setPrices(state => ({
            ...state,
            minVal: Number(e.target.value?.replace(/\s/g, ""))
          }));
        }} value={filter?.min_price ? localeFormatter(String(Number(filter?.min_price))) : ""} placeholder={layoutLang[String(locale)]["dan"]} onFocus={() => setActiveInput("input3")} ref={inputRef3} suffix={currency.toUpperCase()} />
            <div className={`${activeInput === "input3" ? 'flex flex-col gap-1' : 'hidden'}`}>
              {(currency === USD ? minUSDPerPrice : minPerPrice)?.map((item, index) => <div className="rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium flex items-center gap-1 group" onClick={() => {
            dispatch(setMinAreaPrice(item));
            setPrices(state => ({
              ...state,
              minVal: Number(item)
            }));
          }} key={index}>
                  <div className={`w-3.5 h-3.5 rounded-full border-2 border-solid group-hover:border-[#00A389] transition-all duration-300 ${filter?.min_price === item ? "border-[#00A389]" : "border-[#e9e9e9]"}`} />
                  {item?.toLocaleString("ru")}
                </div>)}
            </div>
          </Col>
          <Col span={12}>
            <Input onChange={e => {
          dispatch(setMaxAreaPrice(Number(e.target.value?.replace(/\s/g, ""))));
          setPrices(state => ({
            ...state,
            maxVal: Number(e.target.value?.replace(/\s/g, ""))
          }));
        }} value={filter?.max_price ? localeFormatter(String(filter?.max_price)) : ""} placeholder={layoutLang[String(locale)]["gacha"]} onFocus={() => setActiveInput("input4")} ref={inputRef4} suffix={currency.toUpperCase()} />
            <div className={`${activeInput === "input4" ? 'flex flex-col gap-1' : 'hidden'}`}>
              {(currency === USD ? maxUSDPerPrice : maxPerPrice)?.map((item, index) => <div className="rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium flex items-center gap-1 group" onClick={() => {
            dispatch(setMaxAreaPrice(item));
            setPrices(state => ({
              ...state,
              maxVal: Number(item)
            }));
          }} key={index}>
                  <div className={`w-3.5 h-3.5 rounded-full border-2 border-solid group-hover:border-[#00A389] transition-all duration-300 ${filter?.max_price === item ? "border-[#00A389]" : "border-[#e9e9e9]"}`} />
                  {item?.toLocaleString("ru")}
                </div>)}
            </div>
          </Col>
        </Row>
  }, {
    key: "3",
    label: layoutLang[String(locale)]["bosh_tolov"],
    children: <Row gutter={6}>
          <Col span={12}>
            <Input onChange={e => {
          dispatch(setMinPrePayment(Number(e.target.value?.replace(/\s/g, ""))));
          setPrices(state => ({
            ...state,
            minVal: Number(e.target.value?.replace(/\s/g, ""))
          }));
        }} value={filter?.min_prepayment ? localeFormatter(String(Number(filter?.min_prepayment))) : ""} placeholder={layoutLang[String(locale)]["dan"]} onFocus={() => setActiveInput("input5")} ref={inputRef5} suffix={currency.toUpperCase()} />
            <div className={`${activeInput === "input5" ? 'flex flex-col gap-1' : 'hidden'}`}>
              {(currency === USD ? minUSDInitPrice : minInitPrice)?.map((item, index) => <div className="rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium flex items-center gap-1 group" onClick={() => {
            dispatch(setMinPrePayment(item));
            setPrices(state => ({
              ...state,
              minVal: Number(item)
            }));
          }} key={index}>
                  <div className={`w-3.5 h-3.5 rounded-full border-2 border-solid group-hover:border-[#00A389] transition-all duration-300 ${filter?.min_prepayment === item ? "border-[#00A389]" : "border-[#e9e9e9]"}`} />
                  {item?.toLocaleString("ru")}
                </div>)}
            </div>
          </Col>
          <Col span={12}>
            <Input onChange={e => {
          dispatch(setMaxPrePayment(Number(e.target.value?.replace(/\s/g, ""))));
          setPrices(state => ({
            ...state,
            maxVal: Number(e.target.value?.replace(/\s/g, ""))
          }));
        }} value={filter?.max_prepayment ? localeFormatter(String(filter?.max_prepayment)) : ""} placeholder={layoutLang[String(locale)]["gacha"]} onFocus={() => setActiveInput("input6")} ref={inputRef6} suffix={currency.toUpperCase()} />
            <div className={`${activeInput === "input6" ? 'flex flex-col gap-1' : 'hidden'}`}>
              {(currency === USD ? maxUSDInitPrice : maxInitPrice)?.map((item, index) => <div className="rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium flex items-center gap-1 group" onClick={() => {
            dispatch(setMaxPrePayment(item));
            setPrices(state => ({
              ...state,
              maxVal: Number(item)
            }));
          }} key={index}>
                  <div className={`w-3.5 h-3.5 rounded-full border-2 border-solid group-hover:border-[#00A389] transition-all duration-300 ${filter?.max_prepayment === item ? "border-[#00A389]" : "border-[#e9e9e9]"}`} />
                  {item?.toLocaleString("ru")}
                </div>)}
            </div>
          </Col>
        </Row>
  }, {
    key: "4",
    label: layoutLang[String(locale)]["Oylik tolov"],
    children: <Row gutter={6}>
          <Col span={12}>
            <Input onChange={e => {
          dispatch(setMinMonthlyPayment(Number(e.target.value?.replace(/\s/g, ""))));
          setPrices(state => ({
            ...state,
            minVal: Number(e.target.value?.replace(/\s/g, ""))
          }));
        }} value={filter?.min_monthly_price ? localeFormatter(String(filter?.min_monthly_price)) : ""} placeholder={layoutLang[String(locale)]["dan"]} onFocus={() => setActiveInput("input7")} ref={inputRef7} suffix={currency.toUpperCase()} />
            <div className={`${activeInput === "input7" ? 'flex flex-col gap-1' : 'hidden'}`}>
              {(currency === USD ? minUSDMonthlyPrice : minMonthlyPrice)?.map((item, index) => <div className="rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium flex items-center gap-1 group" onClick={() => {
            dispatch(setMinMonthlyPayment(item));
            setPrices(state => ({
              ...state,
              minVal: Number(item)
            }));
          }} key={index}>
                  <div className={`w-3.5 h-3.5 rounded-full border-2 border-solid group-hover:border-[#00A389] transition-all duration-300 ${filter?.min_monthly_price === item ? "border-[#00A389]" : "border-[#e9e9e9]"}`} />
                  {item?.toLocaleString("ru")}
                </div>)}
            </div>
          </Col>
          <Col span={12}>
            <Input onChange={e => {
          dispatch(setMaxMonthlyPayment(Number(e.target.value?.replace(/\s/g, ""))));
          setPrices(state => ({
            ...state,
            maxVal: Number(e.target.value?.replace(/\s/g, ""))
          }));
        }} value={filter?.max_monthly_price ? localeFormatter(String(filter?.max_monthly_price)) : ""} placeholder={layoutLang[String(locale)]["gacha"]} onFocus={() => setActiveInput("input8")} ref={inputRef8} suffix={currency.toUpperCase()} />
            <div className={`${activeInput === "input8" ? 'flex flex-col gap-1' : 'hidden'}`}>
              {(currency === USD ? maxUSDMonthlyPrice : maxMonthlyPrice)?.map((item, index) => <div className="rounded-[5px] transition-all duration-300 cursor-pointer px-2 py-1 text-[#1D2939] font-medium flex items-center gap-1 group" onClick={() => {
            dispatch(setMaxMonthlyPayment(item));
            setPrices(state => ({
              ...state,
              maxVal: Number(item)
            }));
          }} key={index}>
                  <div className={`w-3.5 h-3.5 rounded-full border-2 border-solid group-hover:border-[#00A389] transition-all duration-300 ${filter?.max_monthly_price === item ? "border-[#00A389]" : "border-[#e9e9e9]"}`} />
                  {item?.toLocaleString("ru")}
                </div>)}
            </div>
          </Col>
        </Row>
  }];
  const priceRange = prices?.minVal === 0 && prices?.maxVal === 0 ? layoutLang[String(locale)]["narxi"] : `${prices?.maxVal > 0 ? filterPriceSeparator(prices?.minVal, locale)?.toString()?.replace(layoutLang[String(locale)]["mln"], '')?.replace(layoutLang[String(locale)]["ming"], '')?.replace(layoutLang[String(locale)]["mlrd"], '')?.replace(layoutLang[String(locale)]["trln"], '') : filterPriceSeparator(prices?.minVal, locale)} - ${filterPriceSeparator(prices?.maxVal, locale)} (${currency === USD ? '$' : layoutLang[String(locale)]["sum"]})`;
  const borderColor = prices?.minVal || prices?.maxVal ? "border-[#00A389]" : "border-[#d9d9d9]";
  return <Dropdown overlay={<div className={styles.dropdownOverlay}>
          <Tabs activeKey={activeTab} items={tabItems} className="pb-3" onChange={e => {
      setTimeout(() => {
        if (e === '1' && inputRef2.current) {
          inputRef2?.current?.focus();
        } else if (e === '2' && inputRef4.current) {
          inputRef4.current.focus();
        } else if (e === '3' && inputRef6.current) {
          inputRef6.current.focus();
        } else if (e === '4' && inputRef8.current) {
          inputRef8.current.focus();
        }
      }, 100);
      if (e === '1') {
        setActiveInput(`input2`);
      } else if (e === "2") {
        setActiveInput(`input4`);
      } else if (e === "3") {
        setActiveInput(`input6`);
      } else if (e === "4") {
        setActiveInput(`input8`);
      }
      setActiveTab(e);
    }} />
        </div>} onOpenChange={() => {
    setOpenDrop(!openDrop);
  }} open={openDrop} trigger={["click", "hover"]} mouseEnterDelay={1000} mouseLeaveDelay={0.3} data-sentry-element="Dropdown" data-sentry-component="FilterPriceModal" data-sentry-source-file="FilterPriceModal.tsx">
      <div className={`${query?.id ? 'min-w-[235px]' : 'min-w-[200px]'} ${type === "main" ? 'flex items-center justify-between w-full h-full' : `${styles.dropDownBtn} border border-solid ${borderColor}`}`} onClick={() => {
      setTimeout(() => {
        if (activeTab === '1' && inputRef2.current) {
          inputRef2?.current?.focus();
        } else if (activeTab === '2' && inputRef4.current) {
          inputRef4.current.focus();
        } else if (activeTab === '3' && inputRef6.current) {
          inputRef6.current.focus();
        } else if (activeTab === '4' && inputRef8.current) {
          inputRef8.current.focus();
        }
      }, 100);
    }}>
        <span className={`${type === "complex" ? "placeholder" : 'text-[#1D2939] font-medium'}`}>{priceRange}</span>
        <ArrowIcon data-sentry-element="ArrowIcon" data-sentry-source-file="FilterPriceModal.tsx" />
      </div>
    </Dropdown>;
};
export default FilterPriceModal;