import { Form, Modal } from "antd";
import React, { useState } from "react";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { userReducerActions } from "../../../../store/reducers/userReducer";
import { phoneNumberFixer } from "../../../../utils/helpers/phoneNumberFixer";
import { useLogin, useRegister } from "../../services/mutations";
import Confirmation from "./confirmation/Confirmation";
import Phone from "./phone/Phone";
import styles from "./loginModal.module.scss";
import { useRouter } from "next/router";
import { layoutLang } from "../../../../utils/language/layout";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "pages/bonuses/utils/constants/queryKeys";
type FormFields = {
  phone: string;
  sms_code: string;
};
const LoginModal: React.FC = () => {
  const qc = useQueryClient();
  const {
    locale,
    query,
    pathname
  } = useRouter();
  const dispatch = useAppDispatch();
  const [formInstance] = Form.useForm();
  const [step, setStep] = useState(1);
  const {
    setVisibleLoginModal,
    setIsAuth,
    setOpenBonus
  } = userReducerActions;
  const register = useRegister(handleAfterSuccessRegister);
  const login = useLogin(handleAfterSuccesslogin);
  const visibleLoginModal = useAppSelector(state => state.userReducer?.visibleLoginModal);
  const bonusId = useAppSelector(state => state.userReducer?.bonusId);
  const complexId = useAppSelector(state => state.userReducer?.complexId);
  const brandId = useAppSelector(state => state.userReducer?.brandId);

  // handle cancel
  const handleCancel = () => {
    dispatch(setVisibleLoginModal(false));
    dispatch(setOpenBonus(false));
  };

  // handle after close
  const handleAfterClose = () => {
    step !== 1 && setStep(1);
    formInstance.resetFields();
  };

  // handle after success register
  function handleAfterSuccessRegister() {
    setStep(2);
  }

  // handle after succes login
  function handleAfterSuccesslogin() {
    handleCancel();
    formInstance.resetFields();
    dispatch(setIsAuth(true));
  }

  // on finish
  const onFinish = (fields: FormFields) => {
    if (step === 1) register.mutate({
      phone: phoneNumberFixer(fields?.phone)
    });else {
      if (!login.isLoading) {
        if (bonusId !== null) {
          login.mutateAsync({
            phone: phoneNumberFixer(fields?.phone),
            sms_code: +fields.sms_code,
            bonus_id: bonusId,
            complex_id: complexId!
          }).then((data: any) => {
            if (query?.builderId) {
              qc.invalidateQueries({
                queryKey: [queryKeys.BUILDER_BONUSES, brandId]
              });
            } else {
              qc.invalidateQueries({
                queryKey: [queryKeys.ONE_COMPLEX, String(complexId)]
              });
            }
            localStorage.setItem("token", data?.Authorization);
            localStorage.removeItem("anonymous_user_id");
          });
        } else {
          login.mutateAsync({
            phone: phoneNumberFixer(fields?.phone),
            sms_code: +fields.sms_code
          }).then((data: any) => {
            // qc.invalidateQueries([queryKeys.FAVOURITES]).then((res: any) => {
            //   dispatch(setFavoritesCount(Number(res?.data?.apartments?.length) + Number(res?.data?.complexes?.length)))
            // })
            qc.invalidateQueries({
              queryKey: [queryKeys.UYSOT_COIN]
            });
            localStorage.setItem("token", data?.Authorization);
            localStorage.removeItem("anonymous_user_id");
          });
        }
      }
    }
  };
  return <Modal centered title={null} footer={false} closable={false} onCancel={() => {
    if (!pathname?.includes('referral')) {
      handleCancel();
    }
  }} afterClose={handleAfterClose} open={visibleLoginModal} className={`${styles.login_modal} custom_styles_modal`} zIndex={1000000} data-sentry-element="Modal" data-sentry-component="LoginModal" data-sentry-source-file="LoginModal.tsx">
      <div className="custom_styles_modal_header">
        <h2>
          {step === 1 ? layoutLang[String(locale)]["Войти или создать личный кабинет"] : layoutLang[String(locale)]["Введите код из СМС"]}
        </h2>
        <CloseIcon onClick={() => {
        if (!pathname?.includes('referral')) {
          handleCancel();
        }
      }} data-sentry-element="CloseIcon" data-sentry-source-file="LoginModal.tsx" />
      </div>
      <Form layout="vertical" form={formInstance} onFinish={onFinish} autoComplete="off" className={styles.login_modal_body} data-sentry-element="Form" data-sentry-source-file="LoginModal.tsx">
        <Phone isActive={step === 1} isLoading={register.isLoading} formInstance={formInstance} data-sentry-element="Phone" data-sentry-source-file="LoginModal.tsx" />
        <Confirmation isLoading={login.isLoading} setStep={setStep} step={step} isActive={step === 2} formInstance={formInstance} data-sentry-element="Confirmation" data-sentry-source-file="LoginModal.tsx" />
      </Form>
    </Modal>;
};
export default LoginModal;