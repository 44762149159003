import $api from "../../../services/RequestService"
import { endPoints } from "../utils/constants/endPoints"

// get faq-view
export const fetchFaqView = async () => {
  const res = await $api.get(endPoints.FAQ_VIEW)
  return res.data.data
}
// get faq-select
export const fetchFaqViewByType = async (type:string) => {
  const res = await $api.get(`${endPoints.FAQ_SELECT}?type=${type}`)
  return res.data.data
}
