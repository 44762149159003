import { Button } from "antd";
import JKArrowLeftIcon from "assets/icons/JKArrowLeftIcon";
import { useRouter } from "next/router";
import { initialRooms } from "pages/main/components/main-filter/mainFilterItems";
import React from "react";
import { layoutLang } from "utils/language/layout";
import { QuizStateModel } from "../utils/models/quizStateModel";
import { itemStyle } from "../Quiz";
interface IProps {
  quizState: QuizStateModel;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  roomsCount: number[];
  setRoomsCount: React.Dispatch<React.SetStateAction<number[]>>;
}
const QuizComponent2: React.FC<IProps> = ({
  page,
  quizState,
  setPage,
  roomsCount,
  setRoomsCount
}) => {
  const {
    locale
  } = useRouter();
  const handleChangeRoomsCount = (roomCount: number) => {
    if (roomsCount?.includes(roomCount)) {
      const newRoomCount = roomsCount?.filter(item => item !== roomCount);
      setRoomsCount(newRoomCount);
    } else {
      setRoomsCount(state => [...state, roomCount]);
    }
  };
  return <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0" data-sentry-component="QuizComponent2" data-sentry-source-file="QuizComponent2.tsx">
      <div className="min-h-[305px]">
        <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${quizState?.payment_method_id === 2 ? 6 : 5} ${layoutLang[String(locale)]["savol"]}`}</p>
        <h2 className="text-[#344054] text-2xl font-medium">
          {layoutLang[String(locale)]["siz_nechi_xonalik_kvartirani_qidiryapsiz"]}
        </h2>
        <p className="text-[#98A2B3] text-base font-medium">
          {layoutLang[String(locale)]["multi_select_rooms"]}
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5">
          {initialRooms?.map(item => <div key={item?.key} className={`${roomsCount?.includes(Number(item?.key)) ? "bg-[#EEFBF6]" : ""} ${roomsCount?.includes(Number(item?.key)) ? "!border-[#00A389]" : ""} ${itemStyle}`} onClick={() => handleChangeRoomsCount(Number(item?.key))}>
              <span>{`${item?.label} ${item?.key === "0" ? "" : layoutLang[String(locale)]["xonalik"]}`}</span>
              {item?.isTop && <div className="bg-[#00A389] px-2 py-0.5 rounded-2xl text-[#FFFFFF] text-xs font-medium">
                  {layoutLang[String(locale)]["TOP"]}
                </div>}
            </div>)}
        </div>
      </div>
      <div className="flex items-center gap-3 w-full mt-[68px]">
        <div onClick={() => setPage(page - 1)} className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base">
          <JKArrowLeftIcon fill="#475467" width={18} height={14} data-sentry-element="JKArrowLeftIcon" data-sentry-source-file="QuizComponent2.tsx" />
          <span className="text-base text-[#475467] font-medium">
            {layoutLang[String(locale)]["ortga"]}
          </span>
        </div>
        <Button type="primary" onClick={() => setPage(page + 1)} className="w-full h-[47px] rounded-xl flex items-center justify-center text-base" disabled={roomsCount?.length === 0} data-sentry-element="Button" data-sentry-source-file="QuizComponent2.tsx">
          {layoutLang[String(locale)]["keyingisi"]}
        </Button>
      </div>
    </div>;
};
export default QuizComponent2;