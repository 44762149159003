import React from "react";
import MainMagazines from "./components/magazines/MainMagazines";
import MainNewComplexes from "./components/new-complexes/NewComplexes";
import MainTopBuilders from "./components/top-builders/MainTopBuilders";
import MainTop from "./components/top/MainTop";
import MainUsefulLinks from "./components/useful-links/MainUsefulLinks";
import SeoText from "./components/seo-text/SeoText";
import HotOffers from "./components/hot-offers/HotOffers";
import styles from "./Index.module.scss";
import { cx } from "utils/cx";
import { BannerImageType, BannerPosition, IBanner, IBannerImage } from "pages/app/utils/models/banner";
import { Pagination } from "utils/models/pagination";
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel";
import CustomFAQ from "common/custom-faq";
import LatestAddedComplexes from "./components/latest-added-complexes";
import ReadyComplexes from "./components/ready-complexes";
type Props = {
  banner: Partial<Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>>;
  topComplexes: Pagination<NewResidentalComplexModel>;
  latestAddedComplexes: Pagination<NewResidentalComplexModel>;
  readyComplexes: NewResidentalComplexModel[];
};
const Index: React.FC<Props> = ({
  banner,
  topComplexes,
  latestAddedComplexes,
  readyComplexes
}) => {
  return <div className="relative" data-sentry-component="Index" data-sentry-source-file="Index.tsx">
      <MainTop banner={banner} data-sentry-element="MainTop" data-sentry-source-file="Index.tsx" />
      <div className={cx("flex justify-center gap-6  w-full", styles.box)}>
        <div className="desctop_hot_offers">
          <HotOffers data-sentry-element="HotOffers" data-sentry-source-file="Index.tsx" />
        </div>
        <div>
          <MainNewComplexes banner={banner} topComplexes={topComplexes} data-sentry-element="MainNewComplexes" data-sentry-source-file="Index.tsx" />
          <LatestAddedComplexes latestAddedComplexes={latestAddedComplexes} data-sentry-element="LatestAddedComplexes" data-sentry-source-file="Index.tsx" />
          {readyComplexes && readyComplexes?.length > 0 && <ReadyComplexes readyComplexes={readyComplexes} />}
          <MainUsefulLinks data-sentry-element="MainUsefulLinks" data-sentry-source-file="Index.tsx" />
          <MainTopBuilders data-sentry-element="MainTopBuilders" data-sentry-source-file="Index.tsx" />
          <MainMagazines data-sentry-element="MainMagazines" data-sentry-source-file="Index.tsx" />
          <SeoText data-sentry-element="SeoText" data-sentry-source-file="Index.tsx" />
        </div>
      </div>
      <div className="flex justify-center items-center w-full mb-5 md:ml-4">
        <div className="wrapper p-4 md:p-0">
          <CustomFAQ type="home" data-sentry-element="CustomFAQ" data-sentry-source-file="Index.tsx" />
        </div>
      </div>
    </div>;
};
export default Index;