import { Modal } from "antd";
import React from "react";
import SuccessMessageIcon from "../../../../assets/icons/SuccessMessageIcon";
import { layoutLang } from "utils/language/layout";
import { useRouter } from "next/router";
import styles from "./callBackMessage.module.scss";
type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
};
const CallBackMessage: React.FC<Props> = ({
  open,
  setOpen
}) => {
  const {
    locale
  } = useRouter();
  const handleClose = () => {
    setOpen(false);
  };
  return <Modal open={open} onCancel={handleClose} footer={null} width={400} centered className={styles.messageModal} title={<div className={styles.messageTitle}>
          <SuccessMessageIcon />
          <div className={styles.messageTitle_right}>
            <h4>{layoutLang[String(locale)]['Ваша заявка принята.']}</h4>
            <span>{layoutLang[String(locale)]['Ваш запрос успешно получен. Наши менеджеры свяжутся с вами в ближайшее время.']}</span>
          </div>
        </div>} data-sentry-element="Modal" data-sentry-component="CallBackMessage" data-sentry-source-file="CallBackMessage.tsx" />;
};
export default CallBackMessage;