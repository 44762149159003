import { useRouter } from 'next/router';
import Header from 'pages/main-layout/components/header/Header';
import React, { useEffect, useState } from 'react';
import styles from './layoutHeader.module.scss';
import { isBrowser } from 'utils/helpers/isBrowser';
import FilterDesktop from 'common/filter-desktop/FilterDesktop';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
const LayoutHeader = () => {
  const [position, setPosition] = useState(isBrowser() ? window.pageYOffset : 0);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const filterVisible = useAppSelector(state => state.favouritesReducer.filterVisible);
  const {
    setFilterVisible
  } = favouritesReducerActions;
  const isPad = router?.pathname === "/[location]/kvartiri" || router?.pathname === "/[location]/novostroyki";
  const isChild = router?.query?.id || router?.query?.builderId || router?.query?.apartmentId || router?.pathname?.includes("referral") ? true : false;
  useEffect(() => {
    const handleScroll = () => {
      const moving = window.pageYOffset;
      dispatch(setFilterVisible(position > moving));
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const header = () => {
    return <header className={`${isChild ? `${styles.header} md:flex hidden` : styles.header} ${filterVisible ? styles.visible : styles.hidden}`} data-sentry-component="header" data-sentry-source-file="LayoutHeader.tsx">
        <Header data-sentry-element="Header" data-sentry-source-file="LayoutHeader.tsx" />
      </header>;
  };
  const headerTop = () => {
    return <div className={`${styles.distance} ${isPad ? "md:pb-[96px]" : isChild ? "pb-0 md:pb-[70px]" : "md:pb-[70px] pb-[55px]"} removed_item`} data-sentry-component="headerTop" data-sentry-source-file="LayoutHeader.tsx" />;
  };
  return <>
      {headerTop()}
      <div className='w-full bg-white'>
        {header()}
      </div>
      <div className={`desctop_only transition-all duration-[400ms] sticky ${filterVisible ? "top-[70px]" : "top-0"} z-[1000]`}>
        {(router.pathname === "/[location]/novostroyki" || router.pathname === "/[location]/kvartiri" || router.pathname === "/karta") && <FilterDesktop />}
      </div>
    </>;
};
export default LayoutHeader;