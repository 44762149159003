import React from "react";
import { Dropdown } from "antd";
import styles from "./filterRoomModal.module.scss";
import ArrowIcon from "../../../assets/icons/ArrowIcon";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { favouritesReducerActions } from "../../../store/reducers/favouritesReducer";
import { isEmptyArr } from "utils/helpers/isEmptyArr";
import { layoutLang } from "utils/language/layout";
import { useRouter } from "next/router";
const roomCount = [{
  key: "1",
  value: "1"
}, {
  key: "2",
  value: "2"
}, {
  key: "3",
  value: "3"
}, {
  key: "4",
  value: "4+"
}];
const FilterRoomModal = () => {
  const dispatch = useAppDispatch();
  const {
    locale
  } = useRouter();
  const {
    setChangeFiltersValue
  } = favouritesReducerActions;
  const rooms_count = useAppSelector(state => state.favouritesReducer.filter.rooms_count);
  return <>
      <Dropdown overlay={<div className={styles.container}>
            <div className={styles.container_top} onClick={() => dispatch(setChangeFiltersValue({
        type: "rooms_count",
        value: '0'
      }))} style={{
        color: rooms_count?.includes("0") ? "#1B815F" : "",
        backgroundColor: rooms_count?.includes("0") ? "#DAF3EB" : ""
      }}>
              <span>{layoutLang[String(locale)]['studiya']}</span>
            </div>
            <div className={styles.container_bottom}>
              {roomCount?.map(item => <div key={item?.key} onClick={() => dispatch(setChangeFiltersValue({
          type: "rooms_count",
          value: item?.value
        }))} style={{
          color: rooms_count?.includes(String(item?.key)) ? "#1B815F" : "",
          backgroundColor: rooms_count?.includes(String(item?.key)) ? "#DAF3EB" : ""
        }}>
                  {item?.value}
                </div>)}
            </div>
          </div>} trigger={["click", "hover"]} mouseEnterDelay={1000} mouseLeaveDelay={0.3} data-sentry-element="Dropdown" data-sentry-source-file="FilterRoomModal.tsx">
        <div className={`${styles.dropDownBtn} ${!isEmptyArr(rooms_count) ? "border-[#00A389]" : '#d9d9d9'} border border-solid min-w-[188px]`}>
          <span className="placeholder">
            {Array.isArray(rooms_count) && rooms_count?.length > 0 ? rooms_count?.map(item => {
            return item === "0" ? layoutLang[String(locale)]['studiya'] : `${item}`;
          })?.join() : layoutLang[String(locale)]['xonalar_soni']}
          </span>
          <ArrowIcon data-sentry-element="ArrowIcon" data-sentry-source-file="FilterRoomModal.tsx" />
        </div>
      </Dropdown>
    </>;
};
export default FilterRoomModal;