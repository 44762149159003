import React, { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { favouritesReducerActions } from "../../../../store/reducers/favouritesReducer";
import { COMPLEX_ID } from "../../../../utils/constants/queryParamsKeys";
import { NewResidentalComplexModel } from "../../../../utils/models/residentialComplexModel";
import { useDeleteFavorites, useUpdateFavourites } from "../../service/mutations";
import { layoutLang } from "../../../../utils/language/layout";
import { useRouter } from "next/router";
import styles from "../../Index.module.scss";
import { userReducerActions } from "store/reducers/userReducer";
import FavouriteIcon from "assets/icons/FavouriteIcon";
import { useCreateUserInteractions } from "pages/detailed-magazine/service/mutations";
import ComplexHeartIcon from "assets/icons/ComplexHeartIcon";
type Props = {
  data: NewResidentalComplexModel;
  type?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
  fill?: string;
  parent?: "card";
};
const ComplexHeart: React.FC<Props> = ({
  data,
  type,
  parent
}) => {
  const {
    locale
  } = useRouter();
  const dispatch = useAppDispatch();
  const updateFavourites = useUpdateFavourites();
  const deleteFavourites = useDeleteFavorites();
  const userInteraction = useCreateUserInteractions();
  const {
    appendComplex,
    removeComplex
  } = favouritesReducerActions;
  const {
    setFavoritesCount
  } = userReducerActions;
  const favourites = useAppSelector(state => state.favouritesReducer?.complex);

  // checked
  const checked = useMemo(() => favourites?.some(item => item.id === data?.id),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [favourites]);

  // prevent default
  const handlePreventDefault = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.stopPropagation();
  };

  // change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      dispatch(setFavoritesCount(-1));
      deleteFavourites.mutate({
        key: COMPLEX_ID,
        id: data?.id
      });
    } else {
      userInteraction.mutate({
        interaction_type: "like",
        complex_id: data?.id
      });
      dispatch(setFavoritesCount(1));
      updateFavourites.mutate({
        key: COMPLEX_ID,
        id: data?.id
      });
    }
    if (e.target.checked) {
      dispatch(appendComplex(data));
    } else {
      dispatch(removeComplex(data?.id));
    }
  };
  return <label className={`${styles.heart} ${checked ? styles.checked : styles.unChecked}`} onClick={handlePreventDefault} aria-hidden data-sentry-component="ComplexHeart" data-sentry-source-file="ComplexHeart.tsx">
      <input type="checkbox" checked={checked} onChange={handleChange} />
      {parent === "card" ? <ComplexHeartIcon width={26} height={26} /> : <FavouriteIcon />}
      {type && <span>{layoutLang[String(locale)]["Saqlanganlar"]}</span>}
    </label>;
};
export default ComplexHeart;