const DocumentsIcon = () => {
  return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="DocumentsIcon" data-sentry-source-file="DocumentsIcon.tsx">
      <g clipPath="url(#clip0_13285_2294)" data-sentry-element="g" data-sentry-source-file="DocumentsIcon.tsx">
        <path d="M12.8337 1.83337H5.50033C4.48741 1.83337 3.67616 2.65379 3.67616 3.66671L3.66699 18.3334C3.66699 19.3463 4.47824 20.1667 5.49116 20.1667H16.5003C17.5132 20.1667 18.3337 19.3463 18.3337 18.3334V7.33337L12.8337 1.83337ZM14.667 16.5H7.33366V14.6667H14.667V16.5ZM14.667 12.8334H7.33366V11H14.667V12.8334ZM11.917 8.25004V3.20837L16.9587 8.25004H11.917Z" fill="white" data-sentry-element="path" data-sentry-source-file="DocumentsIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="DocumentsIcon.tsx">
        <clipPath id="clip0_13285_2294" data-sentry-element="clipPath" data-sentry-source-file="DocumentsIcon.tsx">
          <rect width="22" height="22" fill="white" data-sentry-element="rect" data-sentry-source-file="DocumentsIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default DocumentsIcon;