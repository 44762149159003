import React from 'react';
import { Tooltip } from 'antd';
import { useRouter } from 'next/router';
import { rootPaths } from 'utils/constants/rootPaths';
import { makeUrl } from 'utils/helpers/makeUrl';
import { layoutLang } from 'utils/language/layout';
import { NewResidentalComplexModel } from 'utils/models/residentialComplexModel';
import BuildingIcon from 'assets/icons/BuildingIcon';
import DocumentsIcon from 'assets/icons/DocumentsIcon';
import GenplanIcon from 'assets/icons/GenplanIcon';
import CustomLink from 'common/custom-link';
import NonSSRWrapper from 'common/non-ssr/NonSSRWrapper';
import Lottie from 'lottie-react';
import animationData from "../../../assets/lottie/Animation.json";
interface IProps {
  data: NewResidentalComplexModel;
}
const ComplexAvailability: React.FC<IProps> = ({
  data
}) => {
  const {
    locale
  } = useRouter();
  return <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-component="ComplexAvailability" data-sentry-source-file="complex-availability.tsx">
      <div className="absolute left-3 top-[-2.8rem] z-[1] flex w-auto items-center gap-1.5">
        {data?.availability_construction_document && <Tooltip overlay={layoutLang[String(locale)]["qurulish_hujjatlari"]}>
            <span className="flex h-[30px] w-[30px] items-center justify-center rounded-[7px] bg-[#0000004D]" style={{
          backdropFilter: "blur(4px)"
        }}>
              <CustomLink href={`/${data?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES}/${makeUrl(data?.name || data?.name)}-${data?.id}#documents`}>
                <a className="flex h-full w-full items-center justify-center">
                  <DocumentsIcon />
                </a>
              </CustomLink>
            </span>
          </Tooltip>}
        {data?.availability_construction_process && <Tooltip overlay={layoutLang[String(locale)]["qurulish_jarayoni"]}>
            <span className="flex h-[30px] w-[30px] items-center justify-center rounded-[7px] bg-[#0000004D]" style={{
          backdropFilter: "blur(4px)"
        }}>
              <CustomLink href={`/${data?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES}/${makeUrl(data?.name || data?.name)}-${data?.id}#proccess`}>
                <a className="flex h-full w-full items-center justify-center">
                  <BuildingIcon />
                </a>
              </CustomLink>
            </span>
          </Tooltip>}
        {data?.availability_visual && <Tooltip overlay={layoutLang[String(locale)]["genplan"]}>
            <span className="flex h-[30px] w-[30px] items-center justify-center rounded-[7px] bg-[#0000004D]" style={{
          backdropFilter: "blur(4px)"
        }}>
              <CustomLink href={`/${data?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES}/${makeUrl(data?.name || data?.name)}-${data?.id}#genplan`}>
                <a className="flex h-full w-full items-center justify-center">
                  <GenplanIcon />
                </a>
              </CustomLink>
            </span>
          </Tooltip>}
        {data?.availability_bonus && <div className="relative flex h-[30px] w-[30px] items-center justify-center">
            <div className="absolute left-[50%] top-[50%] h-full w-full translate-x-[-50%] translate-y-[-50%] rounded-[7px] bg-[#0000004D]" style={{
          backdropFilter: "blur(4px)"
        }} />
            <div className="absolute h-[49px] w-[49px]">
              <Lottie animationData={animationData} />
            </div>
          </div>}
      </div>
    </NonSSRWrapper>;
};
export default ComplexAvailability;