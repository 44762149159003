import React, { useEffect, useRef } from 'react';
import { NewResidentalComplexModel } from 'utils/models/residentialComplexModel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper as SwiperType } from "swiper/types";
import { cx } from 'utils/cx';
import { viewTypes } from 'utils/constants/viewTypes';
import { useAppSelector } from 'hooks/reduxHooks';
import ComplexCard from 'common/common-card/complex/ComplexCard';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from './NewComplexes.module.scss';
type ComplexType = 'new_complexes' | 'latest_complexes' | 'ready_complexes';
const MobileNewComplexes: React.FC<{
  data: NewResidentalComplexModel[];
  type: ComplexType;
}> = ({
  data,
  type
}) => {
  const paginationClass = `custom_${type}_pagination`;
  const prevNavId = `navigation_${type}_prev`;
  const nextNavId = `navigation_${type}_next`;
  const callBackModal = useAppSelector(state => state.userReducer?.callBackModal);
  const swiperRef = useRef<SwiperType | null>(null);
  useEffect(() => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      if (swiperInstance?.clickedSlide && callBackModal?.type === 'complex' && callBackModal?.visible) {
        swiperInstance.autoplay.stop();
      } else {
        swiperInstance.autoplay.start();
      }
    }
  }, [callBackModal]);
  return <div className={styles.container} data-sentry-component="MobileNewComplexes" data-sentry-source-file="mobile-new-complexes.tsx">
      <Swiper spaceBetween={10} slidesPerView={1} pagination={{
      clickable: true,
      el: `.${paginationClass}`
    }} navigation={{
      prevEl: `#${prevNavId}`,
      nextEl: `#${nextNavId}`
    }} modules={[Pagination, Navigation, Autoplay]} autoplay={{
      delay: 5000,
      disableOnInteraction: false
    }} onTouchStart={e => e?.autoplay?.stop()} onTouchEnd={e => e?.autoplay?.start()} className={styles.mobile_complexes_swiper} onSwiper={swiper => {
      swiperRef.current = swiper;
    }} data-sentry-element="Swiper" data-sentry-source-file="mobile-new-complexes.tsx">
        {data?.map((item, index) => <SwiperSlide key={index}>
            <ComplexCard data={item} key={item?.id} viewType={viewTypes.GRID} />
          </SwiperSlide>)}
      </Swiper>
      {data && data?.length > 1 && <div className={cx(styles.custom_pagination_container, 'wrapper')}>
          <div className={cx(paginationClass, type === "new_complexes" ? styles.custom_new_complexes_pagination : type === "latest_complexes" ? styles.custom_latest_complexes_pagination : styles.custom_ready_complexes_pagination)}></div>
        </div>}
    </div>;
};
export default MobileNewComplexes;