import { useQuery } from "@tanstack/react-query"
import { useRouter } from "next/router"

import { errorHandler } from "utils/helpers/errorHandler"
import { NewApartmentCardModel } from "utils/models/apartmentModel"
import { Pagination } from "utils/models/pagination"
import { ErrorRes } from "utils/models/responseType"
import { queryKeys } from "../utils/constants/queryKeys"
import { PaymentMethodsAndClassesType } from "../utils/models/models"
import { fetchApartments, fetchNewPaymentMethods } from "./queryFunctions"

// get apartments
export function useGetApartments(id?: number) {
  const { query } = useRouter()
  const { url, fetch } = fetchApartments(query, id as number)

  return useQuery<Pagination<NewApartmentCardModel>, ErrorRes>({
    queryKey: [queryKeys.APARTMENTS, url],
    queryFn: fetch,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get classes for select
export function useGetNewPaymentMethods() {
  return useQuery<PaymentMethodsAndClassesType[], ErrorRes>({
    queryKey: [queryKeys.PAYMENT_METHODS],
    queryFn: fetchNewPaymentMethods,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}
