export const initializeVoiceFlowChat = () => {
    const loadChat = () => {
        window?.voiceflow?.chat?.load({
          verify: { projectID: "67853dfe1e8832a46c6e3ff1" },
          url: "https://general-runtime.voiceflow.com",
          versionID: "production",
        })
      
    };
  
    if (!window?.voiceflow || !window?.voiceflow?.chat) {
      const script = document.createElement("script");
      script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
      script.type = "text/javascript";
      script.async = true;
    
      
  
      script.onload = loadChat; 
  
      document.body.appendChild(script);
    } 
  };
  