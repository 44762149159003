import React from "react";
interface IProps {
  svg?: React.SVGProps<SVGSVGElement>;
}
const BuildersIcon: React.FC<IProps> = ({
  svg
}) => {
  return <svg {...svg} width="171" height="144" viewBox="0 0 171 144" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="BuildersIcon" data-sentry-source-file="BuildersIcon.tsx">
      <path d="M164.008 131.382C164.008 130.483 164.107 129.631 164.282 128.863H155.329C155.477 129.577 155.56 130.36 155.56 131.18C155.56 134.537 154.192 137.259 152.504 137.259C150.816 137.259 149.448 134.537 149.448 131.18C149.448 130.36 149.53 129.577 149.678 128.863H134.097V137.46H167.065C165.377 137.461 164.008 134.739 164.008 131.382Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M154.048 119.023C154.048 118.124 154.147 117.271 154.322 116.504H134.096V125.101H157.104C155.417 125.102 154.048 122.38 154.048 119.023Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M20.1149 27.709H6.76953V30.959H20.1149V27.709Z" fill="#C1C7D6" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M80.1297 97.5605H61.5723V137.259H80.1297V97.5605Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M44.6688 128.932H27.125V137.259H44.6688V128.932Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M37.5202 36.5653V30.959H32.7458C31.0723 30.959 29.7168 32.5141 29.7168 34.4311V46.2915H48.3461V40.664H41.0958C39.1207 40.664 37.5202 38.8293 37.5202 36.5653Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M24.9535 16.1934H6.76953V26.1342H24.9535V16.1934Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M50.5734 126.883H44.6699V137.259H50.5734V126.883Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M97.1661 64.293H87.4834V75.9949H97.1661V64.293Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M97.1661 86.0137H87.4834V97.5608H97.1661V86.0137Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M118.708 86.0137H109.025V97.5608H118.708V86.0137Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M118.708 107.68H109.025V119.292H118.708V107.68Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M112.117 57.8223H104.028V76.2762H112.117V57.8223Z" fill="#C1C7D6" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M80.5987 82.7196H79.6602V57.2852H102.577V63.075H101.638V58.361H80.5987V82.7196Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M124.556 120.699H123.616V76.8155H101.638V66.293H102.577V75.7396H124.556V120.699Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M124.555 124.768H123.616V131.193H124.555V124.768Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M80.5987 89.6035H79.6602V131.192H80.5987V89.6035Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M97.6336 76.534H84.6846V61.6895H97.6336V76.534ZM85.6231 75.4582H96.6951V62.7653H85.6231V75.4582Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M87.4832 62.2285H85.1543V75.996H87.4832V62.2285Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M87.4832 83.793H85.1543V97.5604H87.4832V83.793Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M109.026 83.793H106.697V97.5604H109.026V83.793Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M109.026 105.525H106.697V119.293H109.026V105.525Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M97.6336 98.0992H84.6846V83.2559H97.6336V98.0992ZM85.6231 97.0233H96.6951V84.3317H85.6231V97.0233Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M119.177 98.0992H106.228V83.2559H112.702V84.3317H107.166V97.0233H118.238V84.3317H115.921V83.2559H119.177V98.0992Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M119.177 119.9H106.228V105.057H119.177V119.9ZM107.166 118.824H118.238V106.132H107.166V118.824Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M124.556 76.2768H123.617V53.1824C123.617 52.9653 123.732 52.7693 123.907 52.6857C124.082 52.6021 124.284 52.6483 124.419 52.8018L134.213 64.0294L133.55 64.7908L124.556 54.4804V76.2768Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M133.761 64.423L123.752 75.8965L124.416 76.6572L134.425 65.1837L133.761 64.423Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M134.565 76.2775H133.626V54.4824L124.632 64.7927L123.969 64.0314L133.765 52.8037C133.899 52.6502 134.1 52.6041 134.276 52.6877C134.452 52.7713 134.566 52.9672 134.566 53.1844V76.2775H134.565Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M124.416 64.4229L123.752 65.1836L133.761 76.6571L134.425 75.8964L124.416 64.4229Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M62.6827 78.7522L60.0772 75.7656V89.3459H59.1387V74.4676C59.1387 74.2504 59.253 74.0545 59.4283 73.9709C59.6036 73.8873 59.8061 73.9334 59.94 74.0869L69.7348 85.3146L69.0718 86.0759L62.6827 78.7522Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M60.0772 92.3555H59.1387V97.5599H60.0772V92.3555Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M69.2847 85.7062L59.2754 97.1797L59.939 97.9404L69.9483 86.4669L69.2847 85.7062Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M70.0875 97.5609H69.1489V75.7659L60.1544 86.0762L59.4902 85.3149L69.285 74.0872C69.419 73.9325 69.6215 73.8875 69.7968 73.9712C69.9721 74.0548 70.0864 74.2507 70.0864 74.4679V97.5609H70.0875Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M59.939 85.7061L59.2754 86.4668L69.2847 97.9403L69.9483 97.1796L59.939 85.7061Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M112.586 76.2771H111.648V58.361H102.106V57.2852H112.586V76.2771Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M134.096 76.2773H126.089V131.193H134.096V76.2773Z" fill="#C1C7D6" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M134.565 131.728H124.085V130.652H133.626V76.8141H124.085V75.7383H134.565V131.728Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M120.139 57.8228H119.2V52.6445H124.086V53.7204H120.139V57.8228Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M120.139 61.1582H119.2V76.2761H120.139V61.1582Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M74.5037 81.2197H73.5652V75.0055H69.6172V73.9297H74.5037V81.2197Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M74.504 84.5547H73.5654V97.5596H74.504V84.5547Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M83.6219 98.0993H82.6758V97.0234H99.6414V98.0993H83.6219Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M74.3004 119.574H65.4365V110.479C65.4365 107.743 67.3713 105.525 69.758 105.525H69.9779C72.3645 105.525 74.2993 107.743 74.2993 110.479V119.574H74.3004Z" fill="white" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M71.1254 105.735C70.7269 105.6 70.3055 105.525 69.8689 105.525C67.4213 105.525 65.4375 107.799 65.4375 110.605V119.574H67.9504V110.605C67.9493 108.3 69.2897 106.357 71.1254 105.735Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M74.7692 110.478V120.11H64.9668V110.478C64.9668 107.45 67.1161 104.986 69.7575 104.986H69.9774C72.6199 104.986 74.7692 107.45 74.7692 110.478ZM65.9064 119.035H73.8307V110.478C73.8307 108.043 72.1028 106.062 69.9785 106.062H69.7586C67.6344 106.062 65.9064 108.043 65.9064 110.478V119.035Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M76.1294 119.035H63.6094V120.111H76.1294V119.035Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M87.4832 105.805H85.1543V119.572H87.4832V105.805Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M85.6231 119.573H84.6846V105.268H97.6336V112.408H96.6951V106.343H85.6231V119.573Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M99.6414 119.035H82.6758V120.111H99.6414V119.035Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M121.184 97.0234H104.219V98.0993H121.184V97.0234Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M121.184 118.824H104.219V119.9H121.184V118.824Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M99.6414 75.459H82.6758V76.5348H99.6414V75.459Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M139.853 131.189H129.409V137.258H139.853V131.189Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M140.322 137.796H128.939V130.652H140.322V137.796ZM129.878 136.72H139.383V131.728H129.878V136.72Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M33.8448 50.6973H32.9062V126.882H33.8448V50.6973Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M42.1339 50.6973H41.1953V126.882H42.1339V50.6973Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M45.1387 137.797H24.9688V126.344H45.1387V137.797ZM25.9073 136.721H44.2002V127.421H25.9073V136.721Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M51.0422 137.797H44.2002V126.344H51.0422V137.797ZM45.1387 136.721H50.1037V127.421H45.1387V136.721Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M42.7587 31.4966H32.2812V26.0688L32.2932 26.0088L36.3261 6.40039L37.2396 6.64626L33.2198 26.1948V30.4208H41.8202V26.1948L37.8004 6.64626L38.7139 6.40039L42.7587 26.0688V31.4966Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M37.5202 7.16144C35.7978 7.16144 34.3965 5.55517 34.3965 3.58072C34.3965 1.60627 35.7978 0 37.5202 0C39.2427 0 40.644 1.60627 40.644 3.58072C40.6429 5.55517 39.2416 7.16144 37.5202 7.16144ZM37.5202 1.07584C36.3149 1.07584 35.335 2.1991 35.335 3.58072C35.335 4.96233 36.3149 6.0856 37.5202 6.0856C38.7255 6.0856 39.7055 4.96233 39.7055 3.58072C39.7055 2.1991 38.7245 1.07584 37.5202 1.07584Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M105.67 26.672H3.85352V25.5962H42.1378L51.1127 18.2051H96.4154L96.5385 18.3062L105.669 25.8121V26.672H105.67ZM43.747 25.5949H103.795L96.1138 19.2809H51.4154L43.747 25.5949Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M46.11 51.2342H29.4873V45.7539H46.11V51.2342ZM30.4258 50.1584H45.1714V46.8297H30.4258V50.1584Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M25.4229 13.9141H24.4844V26.1327H25.4229V13.9141Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M7.23932 13.9141H6.30078V26.1327H7.23932V13.9141Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M27.868 13.375H3.85352V14.4508H27.868V13.375Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M24.9541 19.4844H10.8193V20.5602H24.9541V19.4844Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M77.0414 26.671C76.9805 26.671 76.9195 26.6572 76.8607 26.6298C76.6854 26.5462 76.5722 26.3502 76.5722 26.133V20.035L70.8593 26.515C70.7243 26.6685 70.5228 26.7134 70.3486 26.6285C70.1733 26.5449 70.0601 26.349 70.0601 26.1318V20.0312L64.3439 26.515C64.2089 26.6685 64.0075 26.7134 63.8333 26.6285C63.658 26.5449 63.5447 26.349 63.5447 26.1318V20.1136L57.9026 26.5137C57.7676 26.6672 57.5661 26.7122 57.3919 26.6273C57.2166 26.5437 57.1034 26.3477 57.1034 26.1306V20.1136L51.5941 26.3627C51.4591 26.515 51.2577 26.5599 51.0835 26.4763C50.9082 26.3926 50.7949 26.1967 50.7949 25.9795V18.8156H51.7335V24.6903L57.2428 18.4412C57.3778 18.2889 57.5792 18.2427 57.7534 18.3276C57.9287 18.4112 58.0419 18.6072 58.0419 18.8243V24.8413L63.6841 18.4412C63.8191 18.2889 64.0205 18.244 64.1947 18.3276C64.37 18.4112 64.4833 18.6072 64.4833 18.8243V24.8425L70.1995 18.3588C70.3345 18.2065 70.5359 18.1604 70.7101 18.2452C70.8854 18.3289 70.9986 18.5248 70.9986 18.742V24.8438L76.7115 18.3638C76.8466 18.2115 77.048 18.1654 77.2222 18.2502C77.3975 18.3338 77.5107 18.5298 77.5107 18.747V24.845L83.154 18.4437C83.289 18.2914 83.4904 18.2465 83.6646 18.3301C83.8388 18.4137 83.9531 18.6097 83.9531 18.8268V24.8463L89.5964 18.4449C89.7314 18.2914 89.9328 18.2477 90.107 18.3314C90.2812 18.415 90.3955 18.6109 90.3955 18.8281V24.8475L95.9887 18.5023C96.1237 18.3501 96.3251 18.3039 96.4993 18.3888C96.6735 18.4724 96.7879 18.6683 96.7879 18.8855V26.1368H95.8493V20.1748L90.2562 26.5199C90.1212 26.6722 89.9197 26.7171 89.7455 26.6335C89.5713 26.5499 89.457 26.354 89.457 26.1368V20.1173L83.8138 26.5187C83.6788 26.6722 83.4773 26.7159 83.3031 26.6323C83.1289 26.5487 83.0146 26.3527 83.0146 26.1355V20.1161L77.3714 26.5175C77.2821 26.6161 77.1634 26.671 77.0414 26.671Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M33.7066 116.851L33.043 117.611L41.4609 127.261L42.1246 126.5L33.7066 116.851Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M33.7055 107.306L33.04 108.064L41.3294 117.618L41.9948 116.859L33.7055 107.306Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M33.7081 97.7789L33.0439 98.5391L41.3344 108.056L41.9985 107.296L33.7081 97.7789Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M33.7066 88.3701L33.043 89.1309L41.2546 98.5437L41.9182 97.783L33.7066 88.3701Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M33.7066 78.9072L33.043 79.668L41.2954 89.1276L41.959 88.3669L33.7066 78.9072Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M33.7089 69.3153L33.042 70.0723L41.3317 79.6692L41.9986 78.9122L33.7089 69.3153Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M33.7066 59.8623L33.043 60.623L41.2862 70.0721L41.9498 69.3114L33.7066 59.8623Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M33.7078 50.3199L33.042 51.0781L41.3167 60.6252L41.9825 59.8669L33.7078 50.3199Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M43.7637 59.707H31.2773V60.7829H43.7637V59.707Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M41.6271 69.2285H33.4121V70.3044H41.6271V69.2285Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M43.7637 78.752H31.2773V79.8278H43.7637V78.752Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M41.6271 88.2754H33.4121V89.3512H41.6271V88.2754Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M43.7637 97.7969H31.2773V98.8727H43.7637V97.7969Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M41.6271 107.32H33.4121V108.396H41.6271V107.32Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M59.6081 116.844H50.5732V117.92H59.6081V116.844Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M47.6213 116.844H31.2773V117.92H47.6213V116.844Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M25.4378 136.721H12.8066V137.797H25.4378V136.721Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M63.4994 136.721H50.5732V137.797H63.4994V136.721Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M167.163 136.721H130.243V137.797H167.163V136.721Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M157.105 112.404H151.429V113.48H157.105V112.404Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M147.516 112.404H134.096V113.48H147.516V112.404Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M55.8428 8.53009L55.5674 9.55859L83.3477 19.335L83.6232 18.3065L55.8428 8.53009Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M40.3116 3.0633L40.0361 4.0918L50.4357 7.75159L50.7111 6.72309L40.3116 3.0633Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M34.7213 3.07L23.373 7.24219L23.6597 8.2666L35.008 4.09441L34.7213 3.07Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M19.8591 8.53525L6.62598 13.4004L6.91261 14.4248L20.1457 9.55966L19.8591 8.53525Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M34.5511 3.17876L24.6387 13.5137L25.2702 14.3096L35.1826 3.97466L34.5511 3.17876Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M102.977 50.6973H112.236V46.2928H102.977V50.6973Z" fill="#C1C7D6" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M67.4196 50.6973L102.978 50.6973V46.2928L67.4196 46.2928V50.6973Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M103.448 51.2332H66.9512V43.6699H80.13V44.7458H67.8897V50.1574H102.509V44.7458H86.3187V43.6699H103.448V51.2332Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M112.706 51.2345H102.509V43.6699H112.706V51.2345ZM103.447 50.1586H111.768V44.7458H103.447V50.1586Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M89.8286 38.5865C88.3315 38.5865 87.1143 37.1899 87.1143 35.4751V32.4473H92.0748V33.5231H88.0539V35.4751C88.0539 36.5971 88.8509 37.5107 89.8297 37.5107C90.8085 37.5107 91.6055 36.5971 91.6055 35.4751H92.5441C92.543 37.1912 91.3246 38.5865 89.8286 38.5865Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M87.4105 35.7111L69.9717 43.707L70.3203 44.7059L87.7591 36.71L87.4105 35.7111Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M92.3945 35.6456L92.0459 36.6445L109.633 44.7086L109.982 43.7097L92.3945 35.6456Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M90.2979 26.1328H89.3594V32.986H90.2979V26.1328Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M48.3465 41.2019H41.1038C38.8565 41.2019 37.0273 39.1051 37.0273 36.5291V30.959H37.9659V36.5291C37.9659 38.5123 39.3737 40.1261 41.1038 40.1261H48.3465V41.2019Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M48.8155 46.8296H27.1484V34.4312C27.1484 32.2196 28.7174 30.4199 30.6467 30.4199H44.8076L48.8144 38.4812V46.8296H48.8155ZM28.0881 45.7537H47.877V38.7683L44.2632 31.497H30.6478C29.2357 31.497 28.0881 32.8137 28.0881 34.4312V45.7537Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M35.3614 38.1738H34.4229V41.128H35.3614V38.1738Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M80.5989 137.798H59.1387V97.0234H80.5989V137.798ZM60.0772 136.722H79.6592V98.0993H60.0772V136.722Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M109.025 131.191H74.8066V137.26H109.025V131.191Z" fill="white" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M129.878 137.796H74.3369V130.652H89.052V131.728H75.2755V136.72H128.939V131.728H94.2194V130.652H129.878V137.796Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M66.7473 124.229H63.6094V125.304H66.7473V124.229Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M99.6416 124.844H94.3271V125.92H99.6416V124.844Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M20.5847 31.497H6.30078V26.1328H7.23932V30.4212H19.6462V26.1328H20.5847V31.497Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M10.4415 27.709H9.50293V30.959H10.4415V27.709Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M13.9122 27.709H12.9736V30.959H13.9122V27.709Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M17.3829 27.709H16.4443V30.959H17.3829V27.709Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M157.105 125.639C155.127 125.639 153.579 122.732 153.579 119.022C153.579 115.311 155.127 112.404 157.105 112.404C159.082 112.404 160.63 115.311 160.63 119.022C160.631 122.732 159.082 125.639 157.105 125.639ZM157.105 113.48C155.702 113.48 154.518 116.017 154.518 119.022C154.518 122.026 155.702 124.563 157.105 124.563C158.507 124.563 159.692 122.026 159.692 119.022C159.692 116.017 158.507 113.48 157.105 113.48Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M152.503 137.797C150.526 137.797 148.978 134.89 148.978 131.18C148.978 127.469 150.526 124.562 152.503 124.562C154.48 124.562 156.029 127.469 156.029 131.18C156.03 134.89 154.48 137.797 152.503 137.797ZM152.503 125.64C151.101 125.64 149.916 128.177 149.916 131.181C149.916 134.185 151.101 136.722 152.503 136.722C153.905 136.722 155.09 134.185 155.09 131.181C155.09 128.177 153.905 125.64 152.503 125.64Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M167.163 137.797C165.186 137.797 163.638 134.89 163.638 131.18C163.638 127.469 165.187 124.562 167.163 124.562C169.139 124.562 170.689 127.469 170.689 131.18C170.689 134.89 169.14 137.797 167.163 137.797ZM167.163 125.64C165.761 125.64 164.576 128.177 164.576 131.181C164.576 134.185 165.761 136.722 167.163 136.722C168.566 136.722 169.75 134.185 169.75 131.181C169.75 128.177 168.566 125.64 167.163 125.64Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M167.164 124.562H137.455V125.638H167.164V124.562Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M154.047 115.965H145.125V117.041H154.047V115.965Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M142.368 115.965H139.411V117.041H142.368V115.965Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M146.863 128.326H140.156V129.402H146.863V128.326Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
      <path d="M164.107 128.326H159.439V129.402H164.107V128.326Z" fill="#2C2C2C" data-sentry-element="path" data-sentry-source-file="BuildersIcon.tsx" />
    </svg>;
};
export default BuildersIcon;