import React, { useEffect, useState } from "react";
import { isAndroid, isMobile } from "react-device-detect";
import { useRouter } from "next/router";
import ArrowRightIcon from "../../../../assets/icons/ArrowRightIcon";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { localeReducerActions } from "../../../../store/reducers/localeReducer";
import { userReducerActions } from "../../../../store/reducers/userReducer";
import { RU, UZ } from "../../../../utils/constants/locales";
import { menuLinks } from "../../utils/constants/menuLinks";
import styles from "./mobileMenu.module.scss";
import { layoutLang } from "../../../../utils/language/layout";
import { cx } from "utils/cx";
import CurrencyIcon from "assets/icons/CurrencyIcon";
import LoginModal from "../login-modal/LoginModal";
import YouTubeIcon from "assets/icons/YouTubeIcon";
import LinkedinIcon from "assets/icons/LinkedinIcon";
import MenuTelegramIcon from "assets/icons/MenuTelegramIcon";
import MenuInstagrammIcon from "assets/icons/MenuInstagrammIcon";
import MenuFacebookIcon from "assets/icons/MenuFacebookIcon";
import CloseIcon from "assets/icons/CloseIcon";
import { isBrowser } from "utils/helpers/isBrowser";
import UserIcon from "assets/icons/UserIcon";
import { Button } from "antd";
import { useLogout } from "pages/main-layout/services/mutations";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import CustomLink from "common/custom-link";
import Favourites2Icon from "assets/icons/Favourites2Icon";
import { rootPaths } from "utils/constants/rootPaths";
import PlusIcon from "assets/icons/plus-icon";
import { localStorageKeys } from "utils/constants/localStorageKeys";
export const sotialItems = [{
  id: 4,
  icon: YouTubeIcon,
  link: "https://www.youtube.com/@uysot_uz",
  platform: null
}, {
  id: 5,
  icon: LinkedinIcon,
  link: "https://www.linkedin.com/company/uysot/",
  platform: null
}, {
  id: 3,
  icon: MenuFacebookIcon,
  link: "https://www.facebook.com/uysot.uz",
  platform: "facebook",
  shareUrl: "https://www.facebook.com/sharer/sharer.php?u="
}, {
  id: 1,
  icon: MenuTelegramIcon,
  link: "https://t.me/uysot_uz",
  platform: "telegram",
  shareUrl: "https://telegram.me/share/url?url="
}, {
  id: 2,
  icon: MenuInstagrammIcon,
  link: "https://www.instagram.com/uysot_uz/",
  platform: "instagram",
  shareUrl: "https://www.instagram.com/?url="
}];
const MobileMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const visibleMobileMenu = useAppSelector(state => state.userReducer?.visibleMobileMenu);
  const userAddress = useAppSelector(state => state.userReducer?.userAddress);
  const menuIcon = useAppSelector(state => state.userReducer?.menuIcon);
  const favorites_count = useAppSelector(state => state.userReducer?.favorites_count);
  const [isAppSuggestionCanceled, setIsAppSuggestionCanceled] = useState(false);
  const currency = useAppSelector(state => state.localeReducer?.currency);
  const isAuth = useAppSelector(state => state.userReducer.isAuth);
  const {
    locale
  } = useRouter();
  const {
    changeLanguage
  } = localeReducerActions;
  const {
    setChangeFilterType
  } = favouritesReducerActions;
  const {
    setVisibleLoginModal,
    setOpenCurrency,
    setVisibleMobileMenu,
    setChangeMenu
  } = userReducerActions;

  // const isAuth = isBrowser() && localStorage.getItem("is_auth");
  const phone = isBrowser() && localStorage.getItem(localStorageKeys.PHONE);
  const logout = useLogout();

  // close mobile menu
  const handleCloseMobileMenu = () => {
    dispatch(setVisibleMobileMenu(false));
    dispatch(setChangeMenu(!menuIcon));
  };
  useEffect(() => {
    dispatch(changeLanguage(locale === RU ? RU : UZ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateAppSuggestionStatus = () => {
    const appSuggestion = localStorage.getItem("appSuggestionCancelledAt");
    setIsAppSuggestionCanceled(!appSuggestion);
  };
  useEffect(() => {
    window.addEventListener("storage", updateAppSuggestionStatus);
    const intervalId = setInterval(updateAppSuggestionStatus, 500);
    return () => {
      window.removeEventListener("storage", updateAppSuggestionStatus);
      clearInterval(intervalId);
    };
  }, []);
  const handleOpen = () => {
    dispatch(setVisibleLoginModal(true));
  };
  const handleLogout = () => {
    logout.mutate();
    handleCloseMobileMenu();
  };
  return <div className={cx(`${styles.mobile_menu} ${visibleMobileMenu ? styles.active : ""} ${userAddress ? styles.has_address : ""}`, isAndroid && isMobile && isAppSuggestionCanceled ? styles.mobile_menu_with_app_suggestion : "")} data-sentry-component="MobileMenu" data-sentry-source-file="MobileMenu.tsx">
      <div className={`${styles.content} relative bg-[#f2f4f6]`}>
        <div className="relative flex flex-col items-center gap-6 pt-6">
          <div className="absolute right-0 top-[-2px] flex h-[30px] w-[30px] items-center justify-center rounded-full bg-white" onClick={handleCloseMobileMenu}>
            <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="MobileMenu.tsx" />
          </div>
          {isAuth ? <div className="flex flex-col items-center gap-4">
              <UserIcon />
              <span className="text-lg font-semibold text-[#344054]">{phone}</span>
            </div> : <div className="w-full items-center gap-7 flex flex-col">
              <div className="flex items-center justify-center relative w-full pt-5">
                <span className="text-[#2C2C2C] font-bold text-xl">
                  {layoutLang[String(locale)]["Profilingizga kiring"]}
                </span>
              </div>
              <div className="flex h-[46px] w-full cursor-pointer items-center justify-center rounded-xl bg-[#00A389] py-[14px] text-[15px] font-semibold text-white" onClick={handleOpen}>
                {layoutLang[String(locale)]["Kirish"]}
              </div>
            </div>}
          <div className="flex flex-col gap-4 w-full mb-[6px]">
            <div className="flex w-full items-center gap-2">
              <div className="flex w-full cursor-pointer items-center justify-between rounded-xl bg-white p-3" onClick={() => dispatch(setOpenCurrency(true))}>
                <div className="flex flex-col gap-0.5">
                  <p className="text-base font-medium text-[#344054]">{layoutLang[String(locale)]["Valyuta"]}</p>
                  <span className="text-sm text-[#667085]">{currency?.toUpperCase()}</span>
                </div>
                <CurrencyIcon data-sentry-element="CurrencyIcon" data-sentry-source-file="MobileMenu.tsx" />
              </div>
              <CustomLink className="flex w-full h-full cursor-pointer items-center justify-between rounded-xl bg-white p-3" href={rootPaths.FAVOURITES} rel="noopener noreferrer" onClick={handleCloseMobileMenu} data-sentry-element="CustomLink" data-sentry-source-file="MobileMenu.tsx">
                <div className="flex flex-col rounded-xl">
                  <span className="text-[#344054] text-base font-medium">{layoutLang[String(locale)]["Избранное"]}</span>
                  {favorites_count! > 0 && <span className="text-[#667085] text-sm">{favorites_count} {layoutLang[String(locale)]["ta"]}</span>}
                </div>
                <Favourites2Icon data-sentry-element="Favourites2Icon" data-sentry-source-file="MobileMenu.tsx" />
              </CustomLink>
            </div>
            <CustomLink href="https://t.me/uysot_admin" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center gap-2 bg-[#00A389] py-2 px-3 w-full rounded-xl  h-[46px]" data-sentry-element="CustomLink" data-sentry-source-file="MobileMenu.tsx">
              <PlusIcon data-sentry-element="PlusIcon" data-sentry-source-file="MobileMenu.tsx" />
              <span className="text-sm font-semibold text-white">
                {locale === 'ru' ? 'Объявления' : "E'lon berish"}
              </span>
            </CustomLink>
          </div>
        </div>


        <div className={`${styles.content_body} ${visibleMobileMenu ? styles.active : ""}  rounded-xl bg-white p-3 pb-0`}>
          <nav>
            <ul className={styles.menu}>
              {menuLinks().map((item, index) => <li key={index}>
                  <CustomLink href={item.path} onClick={() => {
                handleCloseMobileMenu();
                item?.path?.includes("kvartiri") ? dispatch(setChangeFilterType("apartment")) : item?.path?.includes("novostroyki") ? dispatch(setChangeFilterType("complex")) : "";
              }}>
                    <div className="flex justify-between items-center w-full">
                      <span>
                        {item.label === "EXPO 2024" ? item.label : layoutLang[String(locale) as any][item.label]}
                      </span>
                      <ArrowRightIcon />
                    </div>
                  </CustomLink>
                </li>)}
              <li>
                <CustomLink href={"/faq"} data-sentry-element="CustomLink" data-sentry-source-file="MobileMenu.tsx">
                  <div className="flex justify-between items-center w-full">
                    <span>{layoutLang[String(locale)].Tez_tez_beriladigan_savollar}</span>
                    <ArrowRightIcon data-sentry-element="ArrowRightIcon" data-sentry-source-file="MobileMenu.tsx" />
                  </div>
                </CustomLink>
              </li>
              {isAuth && <CustomLink href={"/bonuses"} onClick={handleCloseMobileMenu}>
                  <div className="flex justify-between items-center w-full">
                    <span>{layoutLang[String(locale)]["Bonuslar"]}</span>
                    <ArrowRightIcon />
                  </div>
                </CustomLink>}
            </ul>
          </nav>
        </div>

        <div className="flex flex-col gap-3 rounded-xl bg-white p-3">
          <span className="text-sm font-medium text-[#344054]">{layoutLang[String(locale)]["Ijtimoiy tarmoqlar"]}</span>
          <div className="flex items-center justify-between">
            {sotialItems?.map((item, index) => <CustomLink className="w-[58px] h-[58px] bg-[#F2F4F7] rounded-full flex items-center justify-center" href={item?.link} target="_blank" key={index}>
                {item?.icon()}
              </CustomLink>)}
          </div>
        </div>
        {isAuth && <Button className="h-[46px] rounded-xl border-none font-semibold !text-[#344054]" onClick={handleLogout} loading={logout.isLoading}>
            {layoutLang[String(locale)]["Chiqish"]}
          </Button>}
      </div>
      <LoginModal data-sentry-element="LoginModal" data-sentry-source-file="MobileMenu.tsx" />
    </div>;
};
export default MobileMenu;