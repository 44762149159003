import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { fetchFaqView, fetchFaqViewByType } from "./queryFunction"
import { ErrorRes } from "../../../utils/models/responseType"
import { IFaq, IFaqCategory } from "../utils/models/IFaq"

// get faq-view
export function useFaqView() {
  return useQuery<IFaqCategory[], ErrorRes>({
    queryKey: [queryKeys.FAQ_VIEW],
    queryFn: fetchFaqView,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}
// get faq select
export function useFaqViewByType(type:string) {
  return useQuery<IFaq[], ErrorRes>({
    queryKey: [queryKeys.FAQ_VIEW_BY_TYPE,type],
    queryFn: ()=>fetchFaqViewByType(type),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    enabled:!!type
  })
}

