import React from "react";
const ArrowRightIcon: React.FC<{
  stroke?: string;
  width?: number;
  height?: number;
}> = ({
  stroke = "white",
  width,
  height
}) => {
  return <svg width={width || "20"} height={height || "20"} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ArrowRightIcon" data-sentry-source-file="ArrowRightIcon.tsx">
      <path d="M5.93994 13.78L10.2866 9.4333C10.7999 8.91997 10.7999 8.07997 10.2866 7.56664L5.93994 3.21997" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ArrowRightIcon.tsx" />
    </svg>;
};
export default ArrowRightIcon;