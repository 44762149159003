import { Button, Form, FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { InputMask } from "../../../../../common/inputMask/InputMask";
import { useAppSelector } from "../../../../../hooks/reduxHooks";
import { PHONE_MASK } from "../../../../../utils/constants/inputMasks";
import { lengthValidator } from "../../../../../utils/helpers/lengthValidator";
import styles from "../loginModal.module.scss";
import { useRouter } from "next/router";
import { layoutLang } from "../../../../../utils/language/layout";
import { isBrowser } from "utils/helpers/isBrowser";
import { localStorageKeys } from "utils/constants/localStorageKeys";
type Props = {
  isLoading: boolean;
  isActive: boolean;
  formInstance: FormInstance<any>;
};
const {
  Item
} = Form;
const Phone: React.FC<Props> = ({
  isLoading,
  isActive,
  formInstance
}) => {
  const {
    locale
  } = useRouter();
  const [disableNext, setDisableNext] = useState(true);
  const visibleLoginModal = useAppSelector(state => state.userReducer?.visibleLoginModal);

  // handle  success
  const handleSuccess = () => {
    if (disableNext) setDisableNext(false);
  };

  // handle error
  const handleError = () => {
    if (!disableNext) setDisableNext(true);
  };
  useEffect(() => {
    if (!visibleLoginModal) setDisableNext(true);
  }, [visibleLoginModal]);
  const mainPhone = isBrowser() && localStorage.getItem(localStorageKeys.MAIN_PHONE);
  useEffect(() => {
    formInstance.setFieldValue("phone", mainPhone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPhone]);
  return <div className={`${styles.login_modal_phone} ${isActive ? styles.active : ""}`} data-sentry-component="Phone" data-sentry-source-file="Phone.tsx">
      <Item name="phone" label={layoutLang[String(locale)]["Номер телефона"]} rules={[lengthValidator(12, handleSuccess, handleError)]} data-sentry-element="Item" data-sentry-source-file="Phone.tsx">
        {InputMask({
        mask: PHONE_MASK
      })}
      </Item>
      <Button disabled={disableNext} type="primary" htmlType="submit" className={styles.next_btn} loading={isLoading} data-sentry-element="Button" data-sentry-source-file="Phone.tsx">
        {layoutLang[String(locale)]["Продолжить"]}
      </Button>
    </div>;
};
export default Phone;