import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  FilterBrandType,
  FilterComplexType,
  FilterDistrictsType,
  FilterNearestPlasesType,
  MetroLinesType,
} from "pages/main-layout/components/region-city/utils/models/ComplexFilterModel"
import { sliceNames } from "utils/constants/sliceNames"

type initialState = {
  complexes: FilterComplexType[] | undefined
  metro_lines: MetroLinesType[] | undefined
  nearest_places: FilterNearestPlasesType[] | undefined
  brands: FilterBrandType[] | undefined
  districts: FilterDistrictsType[] | undefined
  filterSearchValue: string
}

const initialState: initialState = {
  complexes: undefined,
  metro_lines: undefined,
  nearest_places: undefined,
  brands: undefined,
  districts: undefined,
  filterSearchValue: ''
}

const filterReducer = createSlice({
  name: sliceNames.FILTER,
  initialState: initialState,
  reducers: {
    setComplexes: (state, action: PayloadAction<FilterComplexType[] | undefined>) => {
      state.complexes = action.payload
    },
    setMetroLines: (state, action: PayloadAction<MetroLinesType[] | undefined>) => {
      state.metro_lines = action.payload
    },
    setNearestPlaces: (
      state,
      action: PayloadAction<FilterNearestPlasesType[] | undefined>
    ) => {
      state.nearest_places = action.payload
    },
    setBrands: (
      state,
      action: PayloadAction<FilterBrandType[] | undefined>
    ) => {
      state.brands = action.payload
    },
    setDistricts: (state, action: PayloadAction<FilterDistrictsType[] | undefined>) => {
      state.districts = action.payload
    },
    setFilterSearch: (state, action: PayloadAction<string>) => {
      state.filterSearchValue = action.payload
    },
    clearPlaceFilters: (state) => {
      state.complexes = []
      state.metro_lines = []
      state.nearest_places = []
      state.districts = []
      state.filterSearchValue = ''
    },
  },
})

export default filterReducer.reducer

export const filterReducerActions = filterReducer.actions
