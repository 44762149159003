import { Grid, Tooltip } from "antd";
import RegCheckIcon from "assets/icons/RegCheckIcon";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import { layoutLang } from "utils/language/layout";
import uysotCoin from "../../../../assets/images/uysot-coin.png";
import { OneConditionCoin } from "pages/main-layout/utils/models/uysotCoinModel";
interface IProps {
  data: OneConditionCoin[] | undefined;
  coinCounts: number | undefined;
}
const UysotCoinContent: React.FC<IProps> = ({
  coinCounts,
  data
}) => {
  const {
    locale
  } = useRouter();
  const {
    md
  } = Grid.useBreakpoint();
  return <div className="flex flex-col w-full items-center" data-sentry-component="UysotCoinContent" data-sentry-source-file="uysot-coin-content.tsx">
      <div className="flex items-center gap-3">
        <span className="text-[#475467] font-bold text-[56px] leading-[73px]">
          {coinCounts}
        </span>
        <Image width={40} height={44} alt="err" src={uysotCoin} data-sentry-element="Image" data-sentry-source-file="uysot-coin-content.tsx" />
      </div>
      <div className="w-full flex flex-col gap-3 mt-8">
        {data?.map((item, index) => <Tooltip overlay={item?.description[locale as keyof typeof item.description]} key={item?.id}>
            <a href={item?.link} target="_blank" className="flex items-center justify-between bg-[#F2F4F7] p-3 pr-3.5 rounded-2xl cursor-pointer">
              <div className={`flex items-center gap-2 ${item?.type !== "registered" ? 'w-full' : 'max-w-[76%]'}`}>
                {item?.done ? <div className="md:w-8 min-w-6 md:h-8 min-h-6 flex items-center justify-center rounded-full bg-[#12B76A]">
                    <RegCheckIcon size={md ? 24 : 16} stroke="#fff" />
                  </div> : <div className="md:w-8 min-w-6 md:h-8 h-6 flex items-center justify-center rounded-full bg-white text-[#0DC683] text-lg font-medium">
                    {index + 1}
                  </div>}
                <div className="flex flex-col">
                  <span className="text-[#475467] font-semibold md:text-[15px] text-[13px]">
                    {item?.name[locale as keyof typeof item.name]}
                  </span>
                  {index === 1 && (locale === 'uz' ? <div className="text-[11px] md:text-[13px] font-medium">
                      <span className="text-[#98A2B3]">Har bir do‘st </span>
                      <span className="text-[#00A389]">+{item?.amount} U</span>
                      <span className="text-[#1D2939]">Coin</span>
                      <span className="text-[#98A2B3] ml-2"> • Do‘stlar: {item?.referral_users_count} ta</span>
                    </div> : <div className="text-[11px] md:text-[13px] font-medium">
                      <span className="text-[#00A389]">+{item?.amount} U</span>
                      <span className="text-[#1D2939]">Coin</span>
                      <span className="text-[#98A2B3]"> за каждого друга </span>
                      <span className="text-[#98A2B3] ml-2"> • Друзья: {item?.referral_users_count}</span>
                    </div>)}
                </div>
              </div>
              <div className={`flex items-center justify-end gap-[5px] ${item?.count_coin > 0 ? 'w-[24%]' : 'w-max'}`}>
                {item?.type === "invited_friends" ? item?.count_coin > 0 && <p className="text-[#00A389] font-bold md:text-xl text-[17px]">
                    + {item?.count_coin}
                  </p> : <p className="text-[#00A389] font-bold md:text-xl text-[17px]">
                  + {item?.amount}
                </p>}
                <Image width={26} height={27} alt="err" src={uysotCoin} />
              </div>
            </a>
          </Tooltip>)}
      </div>
      <a href="https://docs.google.com/document/d/1ze17hWnxBKRvzkrnYFnondKDqV90XcTgLbSZ177FSys/edit?usp=sharing" target="_blank" className="w-full text-start mt-3 underline text-[#98A2B3] text-[13px] font-medium cursor-pointer">
        {layoutLang[String(locale)]["foydalanish_shartlari"]}
      </a>
    </div>;
};
export default UysotCoinContent;