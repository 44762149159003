import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { BannerImageType, IBanner, IBannerImage } from "pages/app/utils/models/banner";
import React from "react";
import styles from "./banner.module.scss";
import { urls } from "utils/constants/urls";
import CustomImage from "common/custom-image";
interface IProps {
  banner?: IBanner<Record<BannerImageType, IBannerImage>> | undefined;
}
const Banner: React.FC<IProps> = ({
  banner
}) => {
  const t = useTranslations("body.mainTop");
  const {
    locale
  } = useRouter();
  return <div data-sentry-component="Banner" data-sentry-source-file="Banner.tsx">
      {(banner?.title[locale as keyof typeof banner.title] || banner?.subtitle[locale as keyof typeof banner.subtitle]) && <a className={`flex w-full flex-col items-start gap-4 rounded-2xl bg-[#FFFFFF4D] p-3 sm:w-max sm:flex-row sm:px-4 sm:py-3 ${styles.bannerMain}`} style={{
      backdropFilter: "blur(20px)"
    }} target={banner?.blank ? "_blank" : "_self"} href={`${urls.BASE_ADS_UYSOT}/api/banner/view?uuid=${banner?.uuid}&lang=${locale}`}>
            <div className={`flex w-max items-center justify-center overflow-hidden rounded-xl text-base font-medium text-white ${styles.bannerLogo}`}>
              {banner?.logo &&
        // eslint-disable-next-line @next/next/no-img-element
        <CustomImage src={banner?.logo} alt="banner logo" className="w-12 h-12  object-contain" title="banner logo"
        // loading="lazy"
        />}
            </div>
            <div className="flex w-full flex-col gap-1.5 sm:w-max">
              <div className="flex items-center justify-between gap-20">
                <p className="text-base font-semibold text-white">{banner?.title[locale as keyof typeof banner.title]}</p>
                <div className="rounded-[15px] bg-[#FFFFFFB2] px-1.5 py-1 text-xs text-[#2C2C2C]">{t("Реклама")}</div>
              </div>
              <p className="font-medium text-white">{banner?.subtitle[locale as keyof typeof banner.subtitle]}</p>
            </div>
          </a>}
    </div>;
};
export default Banner;