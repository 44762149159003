export function querySelectorAllShadows(selector: string, el: HTMLElement | ShadowRoot = document.body): HTMLElement[] {
    // Recurse on child shadows
    const childShadows = Array.from(el.querySelectorAll("*"))
      .map(el => el.shadowRoot)
      .filter(Boolean) as ShadowRoot[];
    
    const childResults = childShadows.flatMap(child => querySelectorAllShadows(selector, child));
    
    // Find matching elements in the current scope
    const result = Array.from(el.querySelectorAll(selector)) as HTMLElement[];
    
    // Combine results from the current scope and child shadows
    return result.concat(childResults);
  }