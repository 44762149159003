import React from "react";
interface IProps {
  fill?: string;
  width?: number;
  height?: number;
}
const JKArrowLeftIcon: React.FC<IProps> = ({
  fill = "black",
  width = 22,
  height = 18
}) => {
  return <svg width={width} height={height} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="JKArrowLeftIcon" data-sentry-source-file="JKArrowLeftIcon.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.10219 0.522689C9.53141 0.0929193 10.2279 0.0924801 10.6578 0.5217C11.0876 0.95103 11.088 1.64755 10.6588 2.07743L4.84343 7.90006H20.9C21.5075 7.90006 22 8.39253 22 9.00006C22 9.60759 21.5075 10.1001 20.9 10.1001H4.86301L10.6784 15.9227C11.1076 16.3526 11.1072 17.0491 10.6774 17.4784C10.2475 17.9076 9.55099 17.9072 9.12166 17.4774L1.42166 9.76764L0.645386 8.99027L1.42166 8.2129L9.10219 0.522689Z" fill={fill} data-sentry-element="path" data-sentry-source-file="JKArrowLeftIcon.tsx" />
    </svg>;
};
export default JKArrowLeftIcon;