import React, { useEffect } from "react";
import { favouritesReducerActions } from "../../store/reducers/favouritesReducer";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useRouter } from "next/router";
const AppWrapper = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const {
    setWindowPosition
  } = favouritesReducerActions;
  useEffect(() => {
    if (router?.pathname !== "/[location]/kvartiri/[apartmentId]" && router?.pathname !== "/[location]/novostroyki/[id]" && localStorage.getItem("positionY")) {
      dispatch(setWindowPosition({
        positionY: 0,
        activeKey: ""
      }));
      localStorage.removeItem("positionY");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);
  return <></>;
};
export default AppWrapper;