import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { MagazineType } from 'pages/magazines/utils/models/magazineModel';
import React from 'react';
import { dayjsFormats } from 'utils/constants/dayjsFormats';
import { imageLayouts } from 'utils/constants/imageAttributes';
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/ru";
import "dayjs/locale/uz-latn";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import CustomLink from 'common/custom-link';
import { rootPaths } from 'utils/constants/rootPaths';
import { makeUrl } from 'utils/helpers/makeUrl';
import CustomImage from 'common/custom-image';
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
interface IProps {
  item: MagazineType;
}
const ArticleItem: React.FC<IProps> = ({
  item
}) => {
  const {
    locale
  } = useRouter();
  return <CustomLink href={`${rootPaths.MAGAZINES}/${makeUrl(item.slug ? item.slug : item.name)}-${item.id}`} className='flex flex-col sm:flex-row gap-4 p-4 bg-white rounded-xl' data-sentry-element="CustomLink" data-sentry-component="ArticleItem" data-sentry-source-file="ArticleItem.tsx">
      <div className='min-w-[320px] h-[165px] relative rounded-[10px] overflow-hidden'>
        <CustomImage src={item?.image} alt={item?.name} layout={imageLayouts.FILL} title={item?.name} data-sentry-element="CustomImage" data-sentry-source-file="ArticleItem.tsx" />
      </div>
      <div className='flex flex-col w-full my-2'>
        <p className='text-[#00A389] text-[20px] font-semibold line-clamp-2' title={item?.name}>{item?.name}</p>
        <div className='flex flex-col justify-between flex-1'>
          <p className='mt-4 text-[#344054] !line-clamp-2 md:block hidden'>{item?.short_description}</p>
          <div className='flex items-end justify-between mt-4 md:mt-0'>
            <div className='bg-[#F6F6F6] px-3 py-1 rounded-[20px] text-[#98A2B3] text-[13px] font-medium w-max'>
              {item?.category?.name[locale as keyof typeof item.category.name]}
            </div>
            <span className='text-[#98A2B3] font-medium'>{locale === "uz" ? dayjs(item.created_time, dayjsFormats.DATE).locale("uz-latn").format("DD MMMM YYYY") : dayjs(item.created_time, dayjsFormats.DATE).locale("ru").format("DD MMMM YYYY")}</span>
          </div>
        </div>
      </div>
    </CustomLink>;
};
export default ArticleItem;