import React, { useEffect } from "react";
import SearchIcon from "../../assets/icons/SearchIcon";
import FilterDesktopModal from "./filter-desktop-modal/FilterDesktopModal";
import FilterRoomModal from "./filter-room-modal/FilterRoomModal";
import FilterPriceModal from "./filter-price-modal/FilterPriceModal";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useRouter } from "next/router";
import { LOCATION } from "../../utils/constants/queryParamsKeys";
import { favouritesReducerActions } from "../../store/reducers/favouritesReducer";
import qs from "query-string";
import { clearObj } from "../../utils/helpers/clearObj";
import FilterMetro from "pages/main/components/filter-metro/FilterMetro";
import { isEmptyArr } from "utils/helpers/isEmptyArr";
import { USD } from "utils/constants/locales";
import SearchLocation from "pages/main/components/search-location/SearchLocation";
import { layoutLang } from "utils/language/layout";
const FilterDesktop = () => {
  const dispatch = useAppDispatch();
  const {
    push,
    query: getQuery,
    pathname,
    locale
  } = useRouter();
  const filter = useAppSelector(state => state.favouritesReducer?.filter);
  const currency = useAppSelector(state => state.localeReducer.currency);
  const usdValue = useAppSelector(state => state.localeReducer.usdValue);
  const filterSearchValue = useAppSelector(state => state.filterReducer.filterSearchValue);
  const regionValue = useAppSelector(state => state.favouritesReducer.location.regionValue);
  const {
    setChangeComplexName,
    setOpenMapDrawer
  } = favouritesReducerActions;
  const location = String(getQuery?.location).split("&")[0];
  const viewType = pathname.includes("karta");
  const {
    complexes,
    metro_lines,
    nearest_places,
    brands
  } = useAppSelector(state => state.filterReducer);
  const complex_ids = complexes && !isEmptyArr(complexes) ? complexes?.map(item => item?.id) : null;
  const metro_ids = metro_lines && !isEmptyArr(metro_lines) ? metro_lines?.map(item => item?.id) : null;
  const nearest_place_ids = nearest_places && !isEmptyArr(nearest_places) ? nearest_places?.map(item => item?.id) : null;
  const brand_ids = brands && !isEmptyArr(brands) ? brands?.map(item => item?.id) : null;
  const query = {
    ...(clearObj(qs.parse(getQuery?.location as any)) as any),
    location
  };
  useEffect(() => {
    if (getQuery?.complex_name) {
      dispatch(setChangeComplexName(String(getQuery?.complex_name)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuery]);
  const onFinish = () => {
    const newValues = {
      ...filter,
      ...getQuery,
      search: filterSearchValue,
      city_id: regionValue?.city ? regionValue?.city?.id : regionValue?.district ? regionValue?.district?.city?.id : null,
      district_id: regionValue?.district?.id,
      min_total_price: currency === USD ? filter?.min_total_price && filter?.min_total_price * usdValue : filter?.min_total_price,
      max_total_price: currency === USD ? filter?.max_total_price && filter?.max_total_price * usdValue : filter?.max_total_price,
      min_prepayment: currency === USD ? filter?.min_prepayment && filter?.min_prepayment * usdValue : filter?.min_prepayment,
      max_prepayment: currency === USD ? filter?.max_prepayment && filter?.max_prepayment * usdValue : filter?.max_prepayment,
      min_price: currency === USD ? filter?.min_price && filter?.min_price * usdValue : filter?.min_price,
      max_price: currency === USD ? filter?.max_price && filter?.max_price * usdValue : filter?.max_price,
      max_monthly_price: currency === USD ? filter?.max_monthly_price && filter?.max_monthly_price * usdValue : filter?.max_monthly_price,
      min_monthly_price: currency === USD ? filter?.min_monthly_price && filter?.min_monthly_price * usdValue : filter?.min_monthly_price,
      complex_name: filter?.complex_name,
      rooms_count: filter?.rooms_count,
      complex_ids: complex_ids,
      metro_line_ids: metro_ids,
      nearest_place_ids: nearest_place_ids,
      brand_ids: brand_ids,
      page: filter?.min_total_price || filter?.max_total_price || filter?.min_prepayment || filter?.max_prepayment || filter?.min_monthly_price || filter?.max_monthly_price || filter?.min_price || filter?.max_price || filter?.complex_name || filter?.rooms_count || complex_ids || metro_ids || brand_ids || nearest_place_ids ? '1' : query?.page
    };
    let key: keyof typeof newValues;
    const updatedFields = {
      ...newValues
    };
    for (key in updatedFields) {
      !updatedFields[key] && delete updatedFields[key];
      updatedFields[key] === "undefined" && delete updatedFields[key];
    }
    const getNewQuery = viewType ? updatedFields : {
      ...updatedFields,
      [LOCATION]: regionValue?.city ? regionValue?.city?.url : regionValue?.district ? regionValue?.district?.city?.url : 'gorod-tashkent'
    };
    push({
      query: getNewQuery
    });
  };
  return <div className={`flex items-center justify-between max-w-[1254px] gap-8 mx-auto p-2 bg-white rounded-[20px] border border-solid border-[#EAECF0] ${pathname?.includes("karta") ? 'shadow-md absolute top-4 left-[50%] translate-x-[-50%]' : 'mt-0'}`} style={{
    boxShadow: "0 -2px 72px rgba(0,0,0,.05)"
  }} onClick={() => {
    dispatch(setOpenMapDrawer({
      id: null,
      open: false
    }));
  }} data-sentry-component="FilterDesktop" data-sentry-source-file="FilterDesktop.tsx">
      <div className="flex items-center gap-4">
        <FilterRoomModal data-sentry-element="FilterRoomModal" data-sentry-source-file="FilterDesktop.tsx" />
        <FilterPriceModal type="complex" data-sentry-element="FilterPriceModal" data-sentry-source-file="FilterDesktop.tsx" />
        <SearchLocation type="desktop" data-sentry-element="SearchLocation" data-sentry-source-file="FilterDesktop.tsx" />
        <FilterMetro type="header" data-sentry-element="FilterMetro" data-sentry-source-file="FilterDesktop.tsx" />
      </div>
      <div className="flex items-center justify-end gap-2">
        <FilterDesktopModal data-sentry-element="FilterDesktopModal" data-sentry-source-file="FilterDesktop.tsx">
          <div className='flex justify-center items-center h-10 bg-[#F2F4F7] rounded-xl cursor-pointer border border-solid border-[#EAECF0] px-2 text-[#344054] text-[15px] font-medium'>
            {layoutLang[String(locale)]['Фильтр']}
          </div>
        </FilterDesktopModal>
        <div onClick={onFinish} className="flex items-center rounded-xl gap-2 p-[10px] bg-[#00A389] text-[15px] font-medium leading-[18px] text-white cursor-pointer">
          <SearchIcon size="20" color="#fff" data-sentry-element="SearchIcon" data-sentry-source-file="FilterDesktop.tsx" />
          <span>{layoutLang[String(locale)]['Найти']}</span>
        </div>
      </div>
    </div>;
};
export default FilterDesktop;