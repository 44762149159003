import React from "react";
const ArrowIcon: React.FC<React.SVGProps<SVGSVGElement> & {
  isRotated?: boolean;
}> = ({
  isRotated,
  ...props
}) => {
  return <svg {...props} width={props.width || "16"} height={props.height || "16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
    transition: "transform 0.3s ease",
    transform: isRotated ? "rotate(-90deg)" : "rotate(0deg)"
  }} data-sentry-element="svg" data-sentry-component="ArrowIcon" data-sentry-source-file="ArrowIcon.tsx">
      <path d="M2.7193 5.94L7.06596 10.2867C7.5793 10.8 8.4193 10.8 8.93263 10.2867L13.2793 5.94" stroke={props.stroke || "#616161"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ArrowIcon.tsx" />
    </svg>;
};
export default ArrowIcon;