import { Dropdown, Input } from "antd";
import ArrowBottom from "assets/icons/ArrowBottom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { layoutLang } from "utils/language/layout";
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper";
import LocationOverlay from "./location-overlay/LocationOverlay";
import MiniCloseIcon from "assets/icons/MiniCloseIcon";
import { useGetRegionDistricts } from "pages/main-layout/services/queries";
interface IProps {
  type?: "filter" | "desktop" | "main";
}
const SearchLocation: React.FC<IProps> = ({
  type
}) => {
  const dispatch = useAppDispatch();
  const {
    locale,
    query,
    pathname
  } = useRouter();
  const [visible, setVisible] = useState(false);
  const [time, setTime] = useState<any>();
  const [val, setVal] = useState("");
  const {
    setOpenRegionSelect,
    setSearchValue,
    setCurrentRegion
  } = favouritesReducerActions;
  const locationReg = useAppSelector(state => state.favouritesReducer?.location?.regionValue);
  const locationMain = useAppSelector(state => state.favouritesReducer?.location?.mainRegionValue);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    data: districts
  } = useGetRegionDistricts(Number(query?.city_id), 1);
  const city = query?.city_id ? districts && districts![0]?.city : null;
  const district = query?.district_id ? districts?.find(item => item?.id === Number(query?.district_id)) : null;
  const handleChangeSearch = (value: string) => {
    setVal(value);
    clearTimeout(time);
    if (value) {
      setTime(setTimeout(() => {
        dispatch(setSearchValue(value));
      }, 800));
    } else {
      dispatch(setSearchValue(value));
    }
  };
  useEffect(() => {
    if (type === "main") {
      dispatch(setCurrentRegion({
        type: "main",
        value: {
          city: null,
          district: null
        }
      }));
    }
    dispatch(setCurrentRegion({
      type: "location",
      value: {
        city: city!,
        district: district!
      }
    }));
    dispatch(setSearchValue(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, type, query, city, district, openDesktopModal]);
  const finalLocation = type === "main" ? locationMain : locationReg;
  const locationName = finalLocation?.district ? finalLocation?.district?.name[locale as keyof typeof finalLocation.district.name] : finalLocation?.city ? finalLocation?.city?.name[locale as keyof typeof finalLocation.city.name] : '';
  useEffect(() => {
    setVal(locationName!);
  }, [locationName]);
  return <div className="cursor-pointer flex-1" data-sentry-component="SearchLocation" data-sentry-source-file="SearchLocation.tsx">
      <div className="md:block hidden">
        <Dropdown overlay={<LocationOverlay val={val} type={type} setVisible={setVisible} visible={visible} handleChangeSearch={handleChangeSearch} />} placement="bottom" trigger={["click", "hover"]} mouseEnterDelay={1000} mouseLeaveDelay={0.3} open={visible} onOpenChange={() => {
        setVisible(!visible);
      }} data-sentry-element="Dropdown" data-sentry-source-file="SearchLocation.tsx">
          {type === "filter" ? <Input placeholder={`${finalLocation === undefined ? layoutLang[String(locale)]["Butun_Ozbekiston_boylab"] : layoutLang[String(locale)]["Qidirish"]} ...`} onChange={e => {
          handleChangeSearch(e?.target?.value);
        }} value={val} className="h-[43px] rounded-xl shadow-none px-3" suffix={(finalLocation?.city || finalLocation?.district) && <div onClick={() => dispatch(setCurrentRegion({
          type: "location",
          value: undefined
        }))} className="w-4 h-4 rounded-full flex items-center justify-center bg-[#D0D5DD]">
                <MiniCloseIcon />
              </div>} /> : <div className={`flex items-center gap-1 w-max md:w-auto px-4 ${type === "desktop" && `h-10 border border-solid ${locationName?.length > 0 ? 'border-[#00A389]' : 'border-[#d9d9d9]'} rounded-xl min-w-[250px] max-w-[250px]`}`}>
              <span className={`truncate w-[95%] ${type === "main" ? "text-[#1D2939] font-medium" : 'text-[#344054] font-normal'}`}>
                {locationName?.length > 0 ? locationName : layoutLang[String(locale)]["Butun_Ozbekiston_boylab"]}
              </span>
              <ArrowBottom stroke="#616161" />
            </div>}
        </Dropdown>
      </div>
      <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="SearchLocation.tsx">
        <div className="md:hidden flex items-center gap-1 w-max md:w-auto" onClick={() => dispatch(setOpenRegionSelect({
        type: "hot_location",
        value: true
      }))}>
          <span className="text-[#1E90FF] leading-[19px] font-medium truncate w-[95%]">
            {locationName}
          </span>
          <ArrowBottom stroke="#1E90FF" data-sentry-element="ArrowBottom" data-sentry-source-file="SearchLocation.tsx" />
        </div>
      </NonSSRWrapper>
    </div>;
};
export default SearchLocation;