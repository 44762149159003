import { useEffect, useRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Dropdown, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks";
import { userReducerActions } from "../../../../../store/reducers/userReducer";
import { eventTypes } from "../../../../../utils/constants/eventTypes";
import { PHONE_MASK } from "../../../../../utils/constants/inputMasks";
import { localStorageKeys } from "../../../../../utils/constants/localStorageKeys";
import { useLogout } from "../../../services/mutations";
import { layoutLang } from "utils/language/layout";
import { useRouter } from "next/router";
import ReactInputMask from "react-input-mask";
import LogoutIcon from "../../../assets/icon/LogoutIcon";
import HeaderDropDown from "../header-dropdown/HeaderDropDown";
import BonusesIcon from "assets/icons/BonusesIcon";
import CustomLink from "common/custom-link";
import User1Icon from "assets/icons/User1Icon";
import Favourites1Icon from "assets/icons/Favourites1Icon";
import Favourites2Icon from "assets/icons/Favourites2Icon";
import { rootPaths } from "utils/constants/rootPaths";
import PlusIcon from "assets/icons/plus-icon";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "pages/bonuses/utils/constants/queryKeys";
import style from "./HeaderTop.module.scss";
import UysotCoinModal from "../../uysot-coin/uysot-coin-modal";
function HeaderTop() {
  const qc = useQueryClient();
  const {
    locale,
    pathname,
    replace,
    query
  } = useRouter();
  const logout = useLogout();
  const dispatch = useAppDispatch();
  const {
    setVisibleLoginModal
  } = userReducerActions;
  const setUserAddresBtnRef = useRef<HTMLButtonElement>(null);
  const favorites_count = useAppSelector(state => state?.userReducer?.favorites_count);
  const complexId = useAppSelector(state => state.userReducer?.complexId);
  const brandId = useAppSelector(state => state.userReducer?.brandId);
  const isAuth = useAppSelector(state => state?.userReducer?.isAuth);

  // open login modal
  const openLoginModal = () => {
    dispatch(setVisibleLoginModal(true));
  };

  // handle set user address
  const handleSetUserAddress = () => {
    setUserAddresBtnRef.current?.click();
  };

  // add event listener
  useEffect(() => {
    window.addEventListener(eventTypes.CLICK, handleSetUserAddress);
    return () => {
      window.removeEventListener(eventTypes.CLICK, handleSetUserAddress);
    };
  }, []);

  // logout
  const handleLogout = () => {
    logout.mutateAsync().then(() => {
      localStorage.removeItem("token");
    });
    if (pathname.includes("bonuses")) {
      replace("/");
    } else {
      logout.mutateAsync().then(() => {
        if (query?.builderId) {
          qc.invalidateQueries({
            queryKey: [queryKeys.BUILDER_BONUSES, brandId]
          });
        } else {
          qc.invalidateQueries({
            queryKey: [queryKeys.ONE_COMPLEX, String(complexId)]
          });
        }
        qc.invalidateQueries({
          queryKey: [queryKeys.UYSOT_COIN]
        });
      });
    }
  };
  return <div className={style.header_right} data-sentry-component="HeaderTop" data-sentry-source-file="HeaderTop.tsx">
      <CustomLink href={rootPaths.FAVOURITES} rel="noopener noreferrer" className="flex items-center gap-3 px-3 py-2.5 cursor-pointer relative" data-sentry-element="CustomLink" data-sentry-source-file="HeaderTop.tsx">
        {favorites_count! > 0 ? <Favourites1Icon /> : <Favourites2Icon />}
      </CustomLink>
      <div className="flex items-center gap-3">
        <div className="desctop_only">
          <UysotCoinModal data-sentry-element="UysotCoinModal" data-sentry-source-file="HeaderTop.tsx" />
        </div>
        <HeaderDropDown type="lang" data-sentry-element="HeaderDropDown" data-sentry-source-file="HeaderTop.tsx" />
      </div>
      <>
        {isAuth ? <div className={style.header_right_login}>
            <ReactInputMask mask={PHONE_MASK} value={localStorage.getItem(localStorageKeys.PHONE) ?? ""} disabled />
            <Dropdown trigger={["click", "hover"]} mouseEnterDelay={1000} mouseLeaveDelay={0.3} overlay={<div className={`bg-white rounded-lg  select-none border border-solid border-[#F9FAFB]`} style={{
          boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814"
        }}>
                  <CustomLink href="/bonuses" className="flex items-center gap-3 px-4 py-2.5 border-b border-solid border-[#F9FAFB] cursor-pointer relative">
                    <BonusesIcon />
                    <p className="text-[#000000D9]">
                      {layoutLang[String(locale)]["Bonuslar"]}
                    </p>
                  </CustomLink>
                  <p onClick={handleLogout} aria-hidden className="flex items-center gap-3 px-4 py-2.5 cursor-pointer relative">
                    {logout.isLoading ? <Spin indicator={<LoadingOutlined style={{
              fontSize: 20
            }} spin />} /> : <LogoutIcon />}
                    <p>{layoutLang[String(locale)]["Выйти"]}</p>
                  </p>
                </div>}>
              <div className="ml-3 flex items-center justify-center gap-2.5 rounded-full h-9 w-9 cursor-pointer bg-[#F2F4F7]">
                <User1Icon />
              </div>
            </Dropdown>
          </div> : <div className={`${style.header_right_login} h-[36px] px-4 bg-[#EAECF0] text-[#344054] text-sm font-semibold rounded-xl ml-3`} onClick={openLoginModal}>
            {layoutLang[String(locale)]["login"]}
          </div>}
      </>

      <CustomLink href="https://t.me/uysot_admin" target="_blank" rel="noopener noreferrer" className="flex items-center gap-2 bg-[#00A389] py-2 px-3 w-max rounded-xl ml-4" data-sentry-element="CustomLink" data-sentry-source-file="HeaderTop.tsx">
        <PlusIcon data-sentry-element="PlusIcon" data-sentry-source-file="HeaderTop.tsx" />
        <span className="text-sm font-semibold text-white hidden md:block">
          {layoutLang[String(locale)]['Объявления']}
        </span>
      </CustomLink>
    </div>;
}
export default HeaderTop;