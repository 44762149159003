import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { viewTypes } from "../../../utils/constants/viewTypes";
import styles from "../commonCard.module.scss";
import { SwiperSlide, Swiper } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { ClassModel, NewResidentalComplexModel, PaymentMethods } from "utils/models/residentialComplexModel";
import ComplexHeart from "pages/heart/components/complex/ComplexHeart";
import CustomImage from "common/custom-image";
import { Tooltip } from "antd";
type Props = {
  viewType: viewTypes;
  images: {
    medium: string;
    central: string;
    small: string;
  }[];
  classes: ClassModel | undefined;
  alt: string;
  is_top?: boolean;
  payments?: PaymentMethods[] | undefined;
  data?: NewResidentalComplexModel;
  type?: "complex" | "apartment";
};
const CommonCardTop: React.FC<Props> = ({
  images,
  viewType,
  alt,
  payments,
  data,
  type
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [localeImages, setLocalImages] = useState<typeof images>([]);
  const {
    locale
  } = useRouter();
  // handle mouse enter
  const handleMouseEnterSlider = (index: number, image: (typeof images)[number]) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setActiveImageIndex(index);
    setLocalImages(prev => {
      const updatedOne = [...prev];
      const foundOne = updatedOne?.find(el => el?.central === image?.central);
      if (!foundOne) {
        updatedOne[index] = image;
      }
      return updatedOne;
    });
  };
  // handle mouse leave
  const handleMouseLeave = () => {
    setActiveImageIndex(0);
  };

  // top tags container
  const topTagsCont = () => {
    if (viewType === viewTypes.GRID) {
      return <div className="flex items-center gap-1">
          {payments && type === "complex" ? payments?.map(item => <div key={item?.id} className={styles.tag} style={{
          boxShadow: "0px 4px 9.8px 0px #0000001A"
        }}>
              <span>{item?.name[locale as keyof typeof item.name]}</span>
            </div>) : ''}
          {data?.max_discount_amount ? <Tooltip overlay={locale === 'uz' ? `${data?.max_discount_amount}% gacha chegirma` : `Скидка до ${data?.max_discount_amount}%`}>
                <div className={`bg-[#F04438] text-white rounded-[15px] py-1 px-2 text-[13px] h-6 flex items-center leading-3`} style={{
            boxShadow: "0px 4px 9.8px 0px #0000001A"
          }}>
                  <span>-{data?.max_discount_amount}%</span>
                </div>
              </Tooltip> : ''}
        </div>;
    }
    return null;
  };

  // image slider container
  const imageSliderCont = () => {
    if (viewType === viewTypes.GRID) {
      return <div className={styles.image_slider}>
          {images?.slice(0, 4)?.map((image, index) => <div onMouseEnter={handleMouseEnterSlider(index, image)} key={index} />)}
        </div>;
    }
    return null;
  };

  // slider bullets container
  const sliderBulletsCont = () => {
    if (viewType === viewTypes.GRID) {
      return <div className={styles.slider_bullets}>
          {images?.slice(0, 4)?.map((_, index) => <span className={index === activeImageIndex ? styles.active : ""} key={index} />)}
        </div>;
    }
    return null;
  };

  // count of images
  const countOfImages = (index: number) => {
    if (viewType === viewTypes.LIST) {
      if (index === 3 && images?.length > 4) {
        return <div className={styles.count_of_images}>
            <p>+{images?.length - 4}</p>
          </div>;
      }
    }
    return null;
  };
  useEffect(() => {
    if (images?.length > 0) {
      setLocalImages([images[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);
  return <div className={styles.complex_card_top} onMouseLeave={handleMouseLeave} data-sentry-component="CommonCardTop" data-sentry-source-file="CommonCardTop.tsx">
      <div className={`${styles.container} group`}>
        <div className="flex items-center justify-between absolute top-[7px] px-3 z-[2]  w-full">
          {topTagsCont()}
          {type === "complex" && <ComplexHeart parent="card" data={data!} />}
        </div>
        <div className={styles.image_cont}>
          {images?.slice(0, 4)?.map((item, index) => <div className={`${index === activeImageIndex ? styles.active : ""} ${styles.image} ${styles[`image${index}`]}`} key={index}>
              <CustomImage src={localeImages[index]?.central || localeImages[index]?.medium || localeImages[activeImageIndex]?.central || localeImages[activeImageIndex]?.medium || ''} alt={alt} title={alt} />
              {countOfImages(index)}
            </div>)}
        </div>
        {imageSliderCont()}
        <div className="hidden group-hover:block">
          {images?.length > 1 && sliderBulletsCont()}
        </div>
      </div>
      <Swiper lazy pagination={true} modules={[Pagination]} className={styles.mySwiper} data-sentry-element="Swiper" data-sentry-source-file="CommonCardTop.tsx">
        {images?.slice(0, 8)?.map((item, index) => <SwiperSlide key={index}>
            <CustomImage src={item?.central ? item?.central : item?.medium} alt={alt} title={alt} />
            <div className={styles.mySwiper_card}>
            </div>
          </SwiperSlide>)}
      </Swiper>
      <div className="flex items-center justify-between absolute top-[7px] px-3 z-[2]  w-full">
        {topTagsCont()}
        {type === "complex" && <ComplexHeart parent="card" data={data!} />}
      </div>
    </div>;
};
export default CommonCardTop;