import queryString from "query-string"

export const queryKeys = {
  NEW_SIMILAR_COMPLEXES: "new_similar_complexes",
  NEW_SIMILAR_APARTMENTS: "new_similar_apartments",
  NEW_COMPLEXES_OF_BUILDER: "new_complexes_of_builder",
  PAYMENT_METHODS_SELECT: "payment_methods_select",
  HOT_FILTERS: "HOT_FILTERS",
  MAIN_REGIONS: "MAIN_REGIONS",
  FAVOURITES: "favourites",
  CONSTRUCTION_PROCESS: "construction_process",
  ONE_COMPLEX: "one_complex",
  DYNAMIC_PRICE: "dynamic-price",
  METROS: "METROS",
  SIMILAR_ARTICLES:"SIMILAR_ARTICLES",
  NEW_COMPLEXES_BUILDER: "new-complexes-builder",
  FAQ_VIEW_BY_TYPE:"faq-view-by-type",
  LATEST_ADDED_COMPLEXES:"LATEST_ADDED_COMPLEXES",
  READY_COMPLEXES:"READY_COMPLEXES",
  BANNER: (query?: { page_type?: string; uuid?: string; lang?: string }) =>
    `banner-${queryString.stringify(query || {})}`,
  BANNER_VIEW: (uuid?: string) => `banner-view-${uuid}`,
}
