import Image from "next/image";
import PhonePng from "../../assets/images/phone.webp";
import styles from "./MobileAppDownload.module.scss";
import { useRouter } from "next/router";
import { layoutLang } from "../../../../utils/language/layout";
import AppStoreIcon from "assets/icons/app-store-icon";
import GooglePlayIcon from "assets/icons/google-play-icon";
import QrCodeIcon from "assets/icons/qr-code-icon";
import CustomLink from "common/custom-link";
const MobileAppDownload = () => {
  const {
    locale
  } = useRouter();
  return <section className={styles.app_download} data-sentry-component="MobileAppDownload" data-sentry-source-file="MobileAppDownload.tsx">
      <div className="wrapper">
        <div className={styles.app_download__body}>
          <div className={styles.app_links}>
            <h3>
              {layoutLang[String(locale)]["Загрузите сейчас! Не пропустите акции и скидки"]}
            </h3>
            <div className="flex">
              <CustomLink href="https://apps.apple.com/us/app/uysot-yangi-uylar-bozori/id6451233026?itsct=apps_box_CustomLink&itscg=30200" target="_blank" data-sentry-element="CustomLink" data-sentry-source-file="MobileAppDownload.tsx">
                <div>
                  <AppStoreIcon data-sentry-element="AppStoreIcon" data-sentry-source-file="MobileAppDownload.tsx" />
                </div>
              </CustomLink>
              <CustomLink href="https://play.google.com/store/apps/details?id=uz.uysot.marketplace&utm_source=uysot_uz&utm_campaign=footer_CustomLink&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" data-sentry-element="CustomLink" data-sentry-source-file="MobileAppDownload.tsx">
                <div>
                  <GooglePlayIcon data-sentry-element="GooglePlayIcon" data-sentry-source-file="MobileAppDownload.tsx" />
                </div>
              </CustomLink>
            </div>
          </div>
          <div className={styles.app_phone}>
            <Image src={PhonePng} alt="phone-img" title="phone-img" data-sentry-element="Image" data-sentry-source-file="MobileAppDownload.tsx" />
          </div>
          <div className={styles.qr_link}>
            <QrCodeIcon data-sentry-element="QrCodeIcon" data-sentry-source-file="MobileAppDownload.tsx" />
            <p>
              {layoutLang[String(locale)]["Наведите камеру на QR-код, чтобы скачать"]}
            </p>
          </div>
        </div>
      </div>
    </section>;
};
export default MobileAppDownload;