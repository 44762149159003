import React from "react";
import { useRouter } from "next/router";
import FooterMapIcon from "assets/icons/FooterMapIcon";
import ComplexIcon from "assets/icons/ComplexIcon";
import ApartmentIcon from "assets/icons/ApartmentIcon";
import ArticleIcon from "assets/icons/ArticleIcon";
import CustomLink from "common/custom-link";
import { useAppDispatch } from "hooks/reduxHooks";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { querySelectorAllShadows } from "utils/helpers/query-selector-all-shadows";
import ChatIcon from "assets/icons/ChatIcon";
const MobileFooterMenu = () => {
  const dispatch = useAppDispatch();
  const {
    setChangeFilterType
  } = favouritesReducerActions;
  const {
    locale,
    pathname,
    query
  } = useRouter();
  // location
  const location: string = query["location"] ? query["location"] as string : "gorod-tashkent";
  const city_id: number | undefined = query?.city_id ? Number(query?.city_id) : undefined;
  const district_id: number | undefined = query?.district_id ? Number(query?.district_id) : undefined;
  const cityId = city_id ? `?city_id=${city_id}` : "";
  const districtId = district_id ? `?city_id=${city_id}&district_id=${district_id}` : "";
  const menuItems = [{
    text: {
      uz: "Yangi binolar",
      ru: "Новостройки"
    },
    children: <ComplexIcon fill={pathname?.includes("novostroyki") ? "#00A389" : "#475467"} />,
    link: `/${location}/novostroyki${districtId ? districtId : cityId}`,
    url: "novostroyki"
  }, {
    text: {
      uz: "Xonadonlar",
      ru: "Квартиры"
    },
    children: <ApartmentIcon fill={pathname?.includes("kvartiri") ? "#00A389" : "#475467"} />,
    link: `/${location}/kvartiri${districtId ? districtId : cityId}`,
    url: "kvartiri"
  }, {
    text: {
      uz: "Jurnal",
      ru: "Журнал"
    },
    children: <ArticleIcon fill={pathname?.includes("jurnal") ? "#00A389" : "#475467"} width={20} height={20} />,
    link: "/jurnal",
    url: "jurnal"
  }, {
    text: {
      uz: "Xarita",
      ru: "Карта"
    },
    children: <FooterMapIcon fill={pathname?.includes("karta") ? "#00A389" : "#475467"} />,
    link: "/karta",
    url: "karta"
  }];
  const active = "bg-[#00A3891A] flex items-center justify-center h-8 w-[64px] rounded-2xl";
  const handleOpenChat = () => {
    const elementToHide = querySelectorAllShadows('.c-bQoszf')?.[0];
    if (elementToHide) elementToHide?.click();
  };
  return <div className="fixed bottom-0 left-0 bg-white z-[100] w-full sm:hidden grid grid-cols-4 items-center justify-between border border-solid border-[#EAECF0] p-2" data-sentry-component="MobileFooterMenu" data-sentry-source-file="MobileFooterMenu.tsx">
      {menuItems?.map(item => {
      return <CustomLink href={item?.link} key={item?.text[String(locale) as keyof typeof item.text]} className={"flex flex-col items-center gap-1"} onClick={() => item?.url && dispatch(setChangeFilterType(item?.url === "novostroyki" ? "complex" : "apartment"))}>
            <div className={pathname.includes(item?.url) ? active : "flex items-center justify-center h-8 w-[64px]"}>
              {item?.children}
            </div>
            <p className={`${pathname.includes(item?.url) ? "text-[#1D2939]" : "text-[#667085]"}  text-xs font-medium`}>
              {item?.text[String(locale) as keyof typeof item.text]}
            </p>
          </CustomLink>;
    })}
      <div className={"flex flex-col items-center gap-1"} onClick={handleOpenChat}>
        <div className="flex items-center justify-center h-8 w-[64px]">
          <ChatIcon data-sentry-element="ChatIcon" data-sentry-source-file="MobileFooterMenu.tsx" />
        </div>
        <p className={`text-xs font-medium text-[#667085]`}>
          {locale === "uz" ? "Chat" : "Чат"}
        </p>
      </div>
    </div>;
};
export default MobileFooterMenu;