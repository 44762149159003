import ListIcon from "assets/icons/ListIcon";
import MapIcon from "assets/icons/MapIcon";
import CustomLink from "common/custom-link";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useRouter } from "next/router";
import React from "react";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { rootPaths } from "utils/constants/rootPaths";
import { removeObjProperties } from "utils/helpers/removeObjProperties";
import { layoutLang } from "utils/language/layout";
const NavigateButton = () => {
  const {
    query,
    pathname,
    locale
  } = useRouter();
  const {
    setChangeFilterType
  } = favouritesReducerActions;
  const dispatch = useAppDispatch();
  const filterType = useAppSelector(state => state.favouritesReducer?.filterType);

  // location
  const newLocation: string = query["location"] ? query["location"] as string : "gorod-tashkent";
  const isMap = pathname?.includes("karta");
  const path_name = isMap ? `/${newLocation}${filterType === "apartment" ? rootPaths.APARTMENTS : rootPaths.RESIDENTIAL_COMPLEXES}` : rootPaths.MAP;
  const handleNavigate = () => {
    pathname.includes("kvartiri") ? dispatch(setChangeFilterType("apartment")) : dispatch(setChangeFilterType("complex"));
  };
  return <CustomLink href={{
    pathname: path_name,
    query: removeObjProperties(query, "location")
  } as never} className="fixed bottom-[110px] left-4 z-[99] bg-[#00A389] h-11 px-4 rounded-xl flex items-center justify-center cursor-pointer text-base font-semibold" onClick={handleNavigate} data-sentry-element="CustomLink" data-sentry-component="NavigateButton" data-sentry-source-file="NavigateButton.tsx">
      {isMap ? <div className="flex items-center gap-2.5 !text-white">
          <ListIcon />
          <span>{layoutLang[String(locale)]["royxat"]}</span>
        </div> : <div className="flex items-center gap-2.5 !text-white">
          <MapIcon stroke="#fff" />
          <span>{layoutLang[String(locale)]["Xarita"]}</span>
        </div>}
    </CustomLink>;
};
export default NavigateButton;