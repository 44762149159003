import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import ComplexCard from "../../../../common/common-card/complex/ComplexCard";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { userReducerActions } from "../../../../store/reducers/userReducer";
import { rootPaths } from "../../../../utils/constants/rootPaths";
import { viewTypes } from "../../../../utils/constants/viewTypes";
import { urls } from "utils/constants/urls";
import ViewAdv from "../../../../common/view-adv/ViewAdv";
import HotOffersMobile from "../hot-offers/HotOffersMobile";
import styles from "./NewComplexes.module.scss";
import CustomLink from "common/custom-link";
import { BannerImageType, BannerPosition, IBanner, IBannerImage } from "pages/app/utils/models/banner";
import { Pagination } from "utils/models/pagination";
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel";
import MobileNewComplexes from "./mobile-new-complexes";
import { cx } from "utils/cx";
import PaginationNextIcon from "assets/icons/PaginationNextIcon";
import { layoutLang } from "utils/language/layout";
import CustomImage from "common/custom-image";
type Props = {
  banner: Partial<Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>>;
  topComplexes: Pagination<NewResidentalComplexModel>;
};
const MainNewComplexes: React.FC<Props> = ({
  banner: banners,
  topComplexes: data
}) => {
  const t = useTranslations("body.newComplexes");
  const dispatch = useAppDispatch();
  const tMainTop = useTranslations("body.mainTop");
  const {
    locale
  } = useRouter();
  const {
    setUserAddress
  } = userReducerActions;
  const userAddress = useAppSelector(state => state.userReducer?.userAddress);
  useEffect(() => {
    dispatch(setUserAddress(userAddress));
  }, [userAddress, dispatch, setUserAddress]);

  // location
  const location = useMemo(() => userAddress?.url_name, [userAddress]);
  return <section className={styles.new_buildings} data-sentry-component="MainNewComplexes" data-sentry-source-file="NewComplexes.tsx">
      <div className="wrapper">
        <div className="mobile_hot_offers">
          <HotOffersMobile data-sentry-element="HotOffersMobile" data-sentry-source-file="NewComplexes.tsx" />
        </div>
        <div className="flex justify-between items-center">
          <h2 className={styles.title}>{t("Популярные новостройки")}</h2>
          <div className="mobile_only">
            <CustomLink href={`/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`} data-sentry-element="CustomLink" data-sentry-source-file="NewComplexes.tsx">
              <span className={styles.all_link}>
                {layoutLang[String(locale)]['Hammasi']} <PaginationNextIcon data-sentry-element="PaginationNextIcon" data-sentry-source-file="NewComplexes.tsx" />
              </span>
            </CustomLink>
          </div>
        </div>
        <div className={cx(styles.body, 'desctop_only')}>
          {banners?.right_side?.images?.web?.url && <ViewAdv uuid={banners?.right_side?.uuid}>
              <a className={styles.reklama_right} target={banners?.right_side?.blank ? "_blank" : "_self"} href={`${urls.BASE_ADS_UYSOT}/api/banner/view?uuid=${banners?.right_side?.uuid}&lang=${locale}`}>
                <div className={styles.reklama_tag}>
                  <span>{tMainTop("Реклама")}</span>
                </div>
                <div className={styles.image_container}>
                  <CustomImage src={banners?.right_side?.images?.web?.url} alt="banner logo" title="banner logo" />
                </div>
              </a>
            </ViewAdv>}
          {data?.data?.map(item => <ComplexCard data={item} key={item?.id} viewType={viewTypes.GRID} />)}
        </div>
        <div className="mobile_only">
          <MobileNewComplexes data={data?.data} type="new_complexes" data-sentry-element="MobileNewComplexes" data-sentry-source-file="NewComplexes.tsx" />
        </div>
        <div className={cx(styles.bottom, 'desctop_only')}>
          <CustomLink href={`/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`} data-sentry-element="CustomLink" data-sentry-source-file="NewComplexes.tsx">
            {t("Показать еще")}
          </CustomLink>
        </div>
      </div>
    </section>;
};
export default MainNewComplexes;