import React, { useEffect, useMemo } from 'react';
import { Pagination } from 'utils/models/pagination';
import { NewResidentalComplexModel } from 'utils/models/residentialComplexModel';
import { layoutLang } from 'utils/language/layout';
import { useRouter } from 'next/router';
import { rootPaths } from 'utils/constants/rootPaths';
import { viewTypes } from 'utils/constants/viewTypes';
import { userReducerActions } from 'store/reducers/userReducer';
import { useAppSelector } from 'hooks/reduxHooks';
import { useDispatch } from 'react-redux';
import { cx } from 'utils/cx';
import CustomLink from 'common/custom-link';
import ComplexCard from 'common/common-card/complex/ComplexCard';
import PaginationNextIcon from 'assets/icons/PaginationNextIcon';
import MobileNewComplexes from '../new-complexes/mobile-new-complexes';
import styles from '../new-complexes/NewComplexes.module.scss';
type Props = {
  latestAddedComplexes: Pagination<NewResidentalComplexModel>;
};
const LatestAddedComplexes: React.FC<Props> = ({
  latestAddedComplexes
}) => {
  const dispatch = useDispatch();
  const {
    locale
  } = useRouter();
  const {
    setUserAddress
  } = userReducerActions;
  const userAddress = useAppSelector(state => state.userReducer?.userAddress);
  useEffect(() => {
    dispatch(setUserAddress(userAddress));
  }, [userAddress, dispatch, setUserAddress]);
  const location = useMemo(() => userAddress?.url_name, [userAddress]);
  return <div className={styles.new_buildings} data-sentry-component="LatestAddedComplexes" data-sentry-source-file="index.tsx">
      <div className='wrapper'>
        <div className='flex items-center justify-between'>
          <h2 className={styles.title}>{layoutLang[String(locale)]['Новое в Uysot']}</h2>
          <div className={styles.all_link}>
            <CustomLink href={`/${location}${rootPaths.RESIDENTIAL_COMPLEXES}?sort_value=desc&sort_key=created_time`} data-sentry-element="CustomLink" data-sentry-source-file="index.tsx">
              {layoutLang[String(locale)]['Hammasi']}
            </CustomLink>
            <PaginationNextIcon data-sentry-element="PaginationNextIcon" data-sentry-source-file="index.tsx" />
          </div>
        </div>
        <div className={cx(styles.body, 'desctop_only')}>
          {latestAddedComplexes?.data?.map(item => <ComplexCard data={item} key={item?.id} viewType={viewTypes.GRID} />)}
        </div>
        <div className="mobile_only">
          <MobileNewComplexes data={latestAddedComplexes?.data} type='latest_complexes' data-sentry-element="MobileNewComplexes" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </div>;
};
export default LatestAddedComplexes;