import React from "react";
import { rootPaths } from "../../utils/constants/rootPaths";
import { makeUrl } from "../../utils/helpers/makeUrl";
import { OneBuilderModel } from "pages/builders/utils/models/oneBuilderModel";
import CustomLink from "common/custom-link";
import styles from "./builderCard.module.scss";
import CustomImage from "common/custom-image";
import { useRouter } from "next/router";
const BuilderCard: React.FC<OneBuilderModel> = props => {
  const {
    locale
  } = useRouter();
  const builderName = props?.name;
  const builderAboutTitle = locale === 'uz' ? `Quruvchi ${builderName}` : `Застройщик ${builderName}`;
  return <CustomLink href={`${rootPaths.BUILDERS}/${makeUrl(props?.slug || props?.name)}`} className={`${styles.builder_card}`} data-sentry-element="CustomLink" data-sentry-component="BuilderCard" data-sentry-source-file="BuilderCard.tsx">
      <div className={styles.builder_card_left}>
        <h3 className="!mb-0">{props.name}</h3>
      </div>
      <div className={styles.builder_card_right}>
        {props?.logo && <CustomImage src={props.logo} alt={builderAboutTitle} className="object-contain" title={props.name} />}
      </div>
    </CustomLink>;
};
export default BuilderCard;