import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "services/RequestService"
import { ErrorRes, SuccessRes } from "utils/models/responseType"
import { UserInteractionModel } from "../utils/models/userInteractionModel"
import { isBrowser } from "utils/helpers/isBrowser"
import { localStorageKeys } from "utils/constants/localStorageKeys"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { userReducerActions } from "store/reducers/userReducer"
import { queryKeys } from "pages/main-layout/utils/constants/queryKeys"

export function useCreateUserInteractions() {
  const dispatch = useAppDispatch()
  const qc = useQueryClient()
  const { setIsAuth } = userReducerActions
  const is_auth = useAppSelector((state) => state.userReducer.isAuth)
  const is_token = isBrowser() && localStorage.getItem(localStorageKeys.TOKEN)

  return useMutation<SuccessRes, ErrorRes, UserInteractionModel>({
    mutationFn: async (data) => {
      const headers: Record<string, any> = {};
      if (is_auth || is_token) {
        headers.isAuth = true;
      }

      const res = await $api.post(`/user-interaction/create`, data, { headers })
      return res.data.data
    },
    onError: (res) => {
      if (res?.response?.status === 401) {
        qc.prefetchQuery([queryKeys.CHECK_AUTH])
        qc.invalidateQueries([queryKeys.CHECK_AUTH])
        localStorage.removeItem(localStorageKeys.IS_AUTH)
        localStorage.removeItem(localStorageKeys.TOKEN)
        dispatch(setIsAuth(false))
      }
    },
  })
}

export function useSendUserPhone() {
  const qc = useQueryClient()
  const dispatch = useAppDispatch()
  const { setIsAuth } = userReducerActions
  const is_auth = useAppSelector((state) => state.userReducer.isAuth)
  const is_token = isBrowser() && localStorage.getItem(localStorageKeys.TOKEN)

  return useMutation<SuccessRes, ErrorRes, {
    article_id: number;
    key: string;
    phone: string;
    interaction_type: string;
  }>({
    mutationFn: async (data) => {
      const headers: Record<string, any> = {};
      if (is_auth || is_token) {
        headers.isAuth = true;
      }

      const res = await $api.post(`/user-interaction/create`, data, { headers })

      return res.data.data
    },
    onError: (res) => {
      if (res?.response?.status === 401) {
        qc.prefetchQuery([queryKeys.CHECK_AUTH])
        localStorage.removeItem(localStorageKeys.IS_AUTH)
        localStorage.removeItem(localStorageKeys.TOKEN)
        dispatch(setIsAuth(false))
      }
    },
  })
}