import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { isMobile } from "react-device-detect";
import LogoIcon from "assets/icons/LogoIcon";
import styles from "./Index.module.scss";
import CloseIcon from "assets/icons/CloseIcon";
const hasOneDayPassed = () => {
  const lastCancelledTime = localStorage.getItem("appSuggestionCancelledAt");
  if (!lastCancelledTime) return false;
  const now = new Date().getTime();
  const oneDayInMillis = 24 * 60 * 60 * 1000;
  return now - parseInt(lastCancelledTime, 10) > oneDayInMillis;
};
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
// ...

const AppSuggestion: React.FC = () => {
  const [isCanceled, setIsCanceled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    locale,
    push
  } = useRouter();
  // new thing

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (hasOneDayPassed()) {
        setIsCanceled(false);
        localStorage.removeItem("appSuggestionCancelledAt");
      } else {
        // Initialize isCanceled state based on localStorage
        const lastCancelledTime = localStorage.getItem("appSuggestionCancelledAt");
        if (lastCancelledTime) {
          setIsCanceled(true);
        }
      }
      setIsLoading(false);
    }, 0);
    return () => clearTimeout(timeoutId); // Cleanup timeout
  }, []);
  const installApp = () => {
    push('/dl');
  };
  const cancelSmartbanner = () => {
    setIsCanceled(true);
    localStorage.setItem("appSuggestionCancelledAt", new Date().getTime().toString());
  };
  if (isLoading || isCanceled) return null;
  if (isMobile && !isCanceled) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
        <div className={styles.container}>
          <div className={styles.logoAndInfoContainer}>
            <CloseIcon className={styles.cancelBtn} fill="rgba(255, 255, 255, 0.7)" onClick={cancelSmartbanner} />
            <div className={styles.logo}>
              <LogoIcon />
            </div>
            <div className={styles.info}>
              <h2>Uysot Marketplace</h2>
              <h5>{layoutLang[String(locale) as any]["Рынок_новых_домов"]}</h5>
            </div>
          </div>
          <Button className={styles.installAppBtn} type="primary" color="green" onClick={installApp}>
            {layoutLang[String(locale) as any]["Скачать"]}
          </Button>
        </div>
      </div>;
  } else {
    return null;
  }
};
export default AppSuggestion;