import React, { cloneElement, useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import styles from "./filterDesktopModal.module.scss";
import { localeFormatter } from "../../../utils/helpers/localeFormatter";
import { PAGE } from "../../../utils/constants/queryParamsKeys";
import { useRouter } from "next/router";
import RefreshIcon from "../../../assets/icons/RefreshIcon";
import { useTranslations } from "next-intl";
import { layoutLang } from "utils/language/layout";
import { deadlineItems, delayItems, filterTypes, repairedItems } from "./filterItems";
import FilterItem from "../filter-item/FilterItem";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { useGetClasses } from "pages/residential-complexes/services/queries";
import { useGetNewPaymentMethods } from "pages/apartments/services/queries";
import Lottie from "lottie-react";
import animationData from "../../../assets/lottie/Animation.json";
import { localStorageKeys } from "utils/constants/localStorageKeys";
import SearchLocation from "pages/main/components/search-location/SearchLocation";
import FilterMetro from "pages/main/components/filter-metro/FilterMetro";
import { isEmptyArr } from "utils/helpers/isEmptyArr";
import { filterReducerActions } from "store/reducers/filterReducer";
import { bodyOverflowAuto } from "utils/helpers/changeBodyOverflow";
import { parseLocaledString } from "utils/helpers/parseLocaledString";
interface IProps {
  children?: JSX.Element;
}
export const discounts = [{
  id: 1,
  text: {
    uz: "Chegirmali",
    ru: "Со скидкой"
  },
  name: "discount",
  value: "true"
}, {
  id: 2,
  text: {
    uz: "UYSOT bonus",
    ru: "UYSOT бонус"
  },
  name: "uysot_bonus",
  value: "true"
}];
const FilterDesktopModal = ({
  children
}: IProps) => {
  const t = useTranslations("body.Top");
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const {
    data: classes
  } = useGetClasses();
  const {
    data: paymentMethods
  } = useGetNewPaymentMethods();
  const [loading, setLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const {
    push,
    query,
    locale,
    pathname
  } = useRouter();
  const filter = useAppSelector(state => state.favouritesReducer.filter);
  const location = useAppSelector(state => state.favouritesReducer.location);
  const filterType = useAppSelector(state => state.favouritesReducer.filterType);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    clearFilters,
    setChangeFilterType,
    setCurrentRegion,
    setOpenDesktopModal
  } = favouritesReducerActions;
  const {
    clearPlaceFilters
  } = filterReducerActions;
  const [typeFilter, setTypeFilter] = useState('');
  const [discount, setDiscount] = useState<{
    name: string;
    value: string;
  } | undefined>(undefined);
  useEffect(() => {
    if (pathname.includes("kvartiri")) {
      setTypeFilter("apartment");
    } else if (pathname.includes("novostroyki")) {
      setTypeFilter("complex");
    } else {
      setTypeFilter(filterType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, openDesktopModal]);
  const viewType = pathname.includes("karta");
  const handleClose = () => {
    setLoading(false);
    dispatch(setOpenDesktopModal(false));
    dispatch(setCurrentRegion({
      type: "location",
      value: undefined
    }));
    bodyOverflowAuto();
  };
  const handleOpen = () => {
    dispatch(setOpenDesktopModal(true));
  };
  const handlePriceFields = (field: string, value: string) => {
    // const value = form.getFieldValue(field)
    form.setFieldValue(field, localeFormatter(value));
  };
  const complexes = useAppSelector(state => state.filterReducer.complexes);
  const metro_lines = useAppSelector(state => state.filterReducer.metro_lines);
  const nearest_places = useAppSelector(state => state.filterReducer.nearest_places);
  const brands = useAppSelector(state => state.filterReducer.brands);
  const filterSearchValue = useAppSelector(state => state.filterReducer.filterSearchValue);
  const complex_ids = complexes && !isEmptyArr(complexes) ? complexes?.map(item => item?.id) : null;
  const metro_ids = metro_lines && !isEmptyArr(metro_lines) ? metro_lines?.map(item => item?.id) : null;
  const nearest_place_ids = nearest_places && !isEmptyArr(nearest_places) ? nearest_places?.map(item => item?.id) : null;
  const brand_ids = brands && !isEmptyArr(brands) ? brands?.map(item => item?.id) : null;
  const onFinish = (values: any) => {
    setLoading(true);
    const updatedItems = discount !== undefined ? {
      ...filter,
      ...values,
      city_id: location?.regionValue?.district ? location?.regionValue?.district?.city?.id : location?.regionValue?.city?.id,
      district_id: location?.regionValue?.district?.id,
      complex_ids,
      metro_line_ids: metro_ids,
      nearest_place_ids: nearest_place_ids,
      brand_ids: brand_ids,
      search: filterSearchValue,
      ...query,
      [discount?.name]: discount?.value,
      sort_key: query?.sort_key,
      sort_value: query?.sort_value,
      max_area: parseLocaledString(String(values?.max_area)),
      min_area: parseLocaledString(String(values?.max_area)),
      max_floor: parseLocaledString(String(values?.max_floor)),
      min_floor: parseLocaledString(String(values?.min_floor))
    } : {
      ...filter,
      ...values,
      city_id: location?.regionValue?.district ? location?.regionValue?.district?.city?.id : location?.regionValue?.city?.id,
      district_id: location?.regionValue?.district?.id,
      complex_ids: complex_ids,
      metro_line_ids: metro_ids,
      nearest_place_ids: nearest_place_ids,
      brand_ids: brand_ids,
      sort_key: query?.sort_key,
      sort_value: query?.sort_value,
      search: filterSearchValue,
      max_area: parseLocaledString(String(values?.max_area)),
      min_area: parseLocaledString(String(values?.max_area)),
      max_floor: parseLocaledString(String(values?.max_floor)),
      min_floor: parseLocaledString(String(values?.min_floor))
    };
    let key: keyof typeof updatedItems;
    for (key in updatedItems) {
      if (!updatedItems[key]) {
        delete updatedItems[key];
      }
    }
    const currentLocation = location?.regionValue && location?.regionValue?.city ? location?.regionValue?.city?.url : location?.regionValue?.district ? location?.regionValue?.district?.city?.url : "gorod-tashkent";
    dispatch(setChangeFilterType(typeFilter as "complex" | "apartment"));
    localStorage.setItem(localStorageKeys.CURRENT_REGION, JSON.stringify({
      city: location?.regionValue?.city,
      district: location?.regionValue?.district
    }));
    bodyOverflowAuto();
    push({
      pathname: `${viewType ? "" : `/${currentLocation}`}/${viewType ? "karta" : typeFilter === "apartment" ? "kvartiri" : "novostroyki"}`,
      query: updatedItems
    }).then(() => {
      dispatch(setCurrentRegion({
        type: 'hot_location',
        value: location?.regionValue
      }));
      handleClose();
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      ...query,
      delays: query?.delays ? Array.isArray(query?.delays) ? query?.delays?.map(item => item) : [query?.delays] : null,
      class_ids: query?.class_ids ? Array.isArray(query?.class_ids) ? query?.class_ids?.map(item => Number(item)) : [Number(query?.class_ids)] : null,
      deadline_years: query?.deadline_years ? Array.isArray(query?.deadline_years) ? query?.deadline_years : [query?.deadline_years] : null,
      repaired: query?.repaired ? Array.isArray(query?.repaired) ? query?.repaired : [query?.repaired] : null,
      payment_method_ids: query?.payment_method_ids ? Array.isArray(query?.payment_method_ids) ? query?.payment_method_ids?.map(item => Number(item)) : [Number(query?.payment_method_ids)] : null,
      city_id: query?.city_id ? Number(query?.city_id) : null,
      district_id: query?.district_id ? Number(query?.district_id) : null,
      filter_type: query?.filter_type ? Number(query?.filter_type) : null
    });
    if (query?.discount) {
      setDiscount({
        name: "discount",
        value: String(query?.discount)
      });
    } else if (query?.uysot_bonus) {
      setDiscount({
        name: "uysot_bonus",
        value: String(query?.uysot_bonus)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal]);
  const handleRefreshFilter = () => {
    const freshQuery: {
      page?: string;
      location: string;
    } = {
      location: "gorod-tashkent"
    };
    if (pathname.includes("kvartiri")) {
      setTypeFilter("apartment");
    } else {
      setTypeFilter("complex");
    }

    // check  page
    if (query[PAGE]) {
      freshQuery[PAGE] = query[PAGE] as string;
    }
    setClearLoading(true);
    push({
      query: freshQuery
    }).then(() => {
      dispatch(setOpenDesktopModal(false));
      setClearLoading(false);
      form.resetFields();
      setDiscount(undefined);
      dispatch(clearFilters());
      dispatch(clearPlaceFilters());
      dispatch(setCurrentRegion({
        type: "location",
        value: undefined
      }));
    });
  };
  const completed_complex = [{
    id: 1,
    value: "true",
    name: {
      uz: "Tayyor",
      ru: "Готовый"
    }
  }];
  return <>
      {children && cloneElement(children, {
      onClick: handleOpen
    })}
      <Modal title={layoutLang[String(locale)]["Фильтр"]} footer={false} open={openDesktopModal} onCancel={handleClose} width={1000} wrapClassName={styles.modal} centered data-sentry-element="Modal" data-sentry-source-file="FilterDesktopModal.tsx">
        <Form className={styles.form} form={form} onFinish={onFinish} autoComplete="off" data-sentry-element="Form" data-sentry-source-file="FilterDesktopModal.tsx">
          <div className={styles.row}>
            {layoutLang[String(locale)]["Расположение"]}
            <div className="w-[378px]">
              <SearchLocation type="filter" data-sentry-element="SearchLocation" data-sentry-source-file="FilterDesktopModal.tsx" />
            </div>
            <div className="w-[378px]">
              <FilterMetro type="filter" data-sentry-element="FilterMetro" data-sentry-source-file="FilterDesktopModal.tsx" />
            </div>
          </div>
          <div className={styles.row}>
            {t("Этаж")}
            <Form.Item name="min_floor" data-sentry-element="unknown" data-sentry-source-file="FilterDesktopModal.tsx">
              <Input onChange={e => handlePriceFields("min_floor", e.target.value)} size="large" placeholder={t("от")} data-sentry-element="Input" data-sentry-source-file="FilterDesktopModal.tsx" />
            </Form.Item>
            <Form.Item name="max_floor" data-sentry-element="unknown" data-sentry-source-file="FilterDesktopModal.tsx">
              <Input onChange={e => handlePriceFields("max_floor", e.target.value)} size="large" placeholder={t("до")} data-sentry-element="Input" data-sentry-source-file="FilterDesktopModal.tsx" />
            </Form.Item>
          </div>
          <div className={styles.row}>
            {t("Тип жилья")}
            <FilterItem data={repairedItems} name="repaired" data-sentry-element="FilterItem" data-sentry-source-file="FilterDesktopModal.tsx" />
          </div>
          <div className={styles.row}>
            {layoutLang[String(locale)]["korinish_turi"]}
            <div className="flex items-center gap-2">
              {filterTypes?.map(item => <div key={item?.id} className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#00A389] transition-all duration-300 ${typeFilter === item?.value ? "bg-[#e7fbf5]" : "bg-[#f2f4f7]"} ${typeFilter === item?.value ? "text-[#00A389]" : "text-[#000000D9]"}`} onClick={() => setTypeFilter(item?.value as "apartment" | "complex")}>
                  {item?.name[locale as keyof typeof item.name]}
                </div>)}
            </div>
          </div>
          <div className={styles.row}>
            {t("Площад от")}
            <Form.Item name="min_area" data-sentry-element="unknown" data-sentry-source-file="FilterDesktopModal.tsx">
              <Input onChange={e => handlePriceFields("min_area", e.target.value)} size="large" placeholder={t("от")} suffix={layoutLang[String(locale)]["м²"]} data-sentry-element="Input" data-sentry-source-file="FilterDesktopModal.tsx" />
            </Form.Item>
            <Form.Item name="max_area" data-sentry-element="unknown" data-sentry-source-file="FilterDesktopModal.tsx">
              <Input onChange={e => handlePriceFields("max_area", e.target.value)} size="large" placeholder={t("до")} suffix={layoutLang[String(locale)]["м²"]} data-sentry-element="Input" data-sentry-source-file="FilterDesktopModal.tsx" />
            </Form.Item>
          </div>

          <div className={styles.row}>
            {t("Условия оплаты")}
            <FilterItem data={delayItems} name="delays" data-sentry-element="FilterItem" data-sentry-source-file="FilterDesktopModal.tsx" />
          </div>
          <div className={styles.row}>
            {t("Класс жилья")}
            <FilterItem data={classes!} name="class_ids" data-sentry-element="FilterItem" data-sentry-source-file="FilterDesktopModal.tsx" />
          </div>
          <div className={styles.row}>
            {t("Дата окончания")}
            <div className="flex gap-2">
              <FilterItem data={completed_complex} name="completed_complex" data-sentry-element="FilterItem" data-sentry-source-file="FilterDesktopModal.tsx" />
              <FilterItem data={deadlineItems} name="deadline_years" data-sentry-element="FilterItem" data-sentry-source-file="FilterDesktopModal.tsx" />
            </div>
          </div>
          <div className={styles.row}>
            {layoutLang[String(locale)]["tolov_turi"]}
            <FilterItem data={paymentMethods!} name="payment_method_ids" data-sentry-element="FilterItem" data-sentry-source-file="FilterDesktopModal.tsx" />
          </div>
          <div className={styles.row}>
            {layoutLang[String(locale)]["Chegirma va bonuslar"]}
            <div className="flex items-center gap-2">
              {discounts?.map(item => <div key={item?.id} className={`relative h-[37px] px-4 rounded-[4px] flex items-center justify-center text-base font-medium cursor-pointer hover:text-[#00A389] transition-all duration-300 ${item?.name === discount?.name ? "text-[#00A389] bg-[#e7fbf5]" : "bg-[#F2F4F7]"} ${item?.name === "uysot_bonus" ? "pr-14" : ""}`} onClick={() => {
              if (item?.name === discount?.name) {
                setDiscount(undefined);
              } else {
                setDiscount({
                  name: item?.name,
                  value: item?.value
                });
              }
            }}>
                  <span>{item?.text[locale as keyof typeof item.text]}</span>
                  {item?.name === "uysot_bonus" && <div className="w-14 h-14 absolute right-0">
                      <Lottie animationData={animationData} />
                    </div>}
                </div>)}
            </div>
          </div>

          <div className={styles.footer}>
            <Button loading={clearLoading} size="large" icon={<RefreshIcon />} className={styles.footer_clear} onClick={handleRefreshFilter} data-sentry-element="Button" data-sentry-source-file="FilterDesktopModal.tsx">
              {t("Сбросить")}
            </Button>
            <Button htmlType="submit" size="large" className="rounded-lg" type="primary" loading={loading} data-sentry-element="Button" data-sentry-source-file="FilterDesktopModal.tsx">
              {t("Сохранить")}
            </Button>
          </div>
        </Form>
      </Modal>
    </>;
};
export default FilterDesktopModal;