import React, { useEffect } from "react";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { useGetUyotCoin } from "pages/main-layout/services/queries";
import { Grid } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import UysotCoinContent from "./uysot-coin-content";
import MiniCloseIcon from "assets/icons/MiniCloseIcon";
const UysotCoinMobile = () => {
  const dispatch = useAppDispatch();
  const {
    data
  } = useGetUyotCoin();
  const {
    setCoinVisible
  } = favouritesReducerActions;
  const {
    md
  } = Grid.useBreakpoint();
  const coinVisible = useAppSelector(state => state.favouritesReducer.coinVisible);
  const coinCounts = data?.reduce((prev, acc) => {
    return prev + acc?.count_coin;
  }, 0);
  const handleClose = () => {
    dispatch(setCoinVisible(false));
  };
  useEffect(() => {
    if (coinVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.overflowX = "hidden";
    }
  }, [coinVisible]);
  return !md && <div className={`${coinVisible ? "bg-[#00000040]" : "bg-transparent"} fixed w-full h-full left-0 bottom-0 ${coinVisible ? "z-[100001]" : "-z-10"} transition-all duration-500`}>
        <div className="w-full h-full absolute top-0 left-0 z-10" onClick={handleClose} />
        <div className={`bg-white w-full fixed rounded-t-2xl z-20 ${coinVisible ? "bottom-0" : "bottom-[-100%]"} transition-all duration-500 pt-4 pb-7 px-3`}>
          <div className="flex items-center justify-between mb-8">
            <div className="text-xl font-bold flex items-center">
              <span className="text-[#1D2939]">U</span>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0DC683] to-[#00A389]">
                COIN
              </span>
            </div>
            <div className="w-[30px] h-[30px] flex items-center justify-center rounded-full bg-[#F2F2F7] cursor-pointer" onClick={handleClose}>
              <MiniCloseIcon size={11} />
            </div>
          </div>
          <UysotCoinContent data={data} coinCounts={coinCounts} />
        </div>
      </div>;
};
export default UysotCoinMobile;