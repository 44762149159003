import { cx } from "utils/cx";
interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  active?: boolean;
}
const HamburgerMenuIcon = ({
  active = false,
  className,
  ...props
}: IProps) => {
  return <div className={cx("cursor-pointer w-[33px] flex flex-col gap-[8px] justify-center", className)} {...props} data-sentry-component="HamburgerMenuIcon" data-sentry-source-file="HamburgerMenuIcon.tsx">
      <span className={cx("flex bg-white w-full h-[2px] transition-[0.3s]")} style={{
      transform: active ? `rotate(45deg) translate(12px,4px)` : ""
    }}></span>
      <span className={cx("flex bg-white w-full h-[2px] transition-[0.3s]", active && "opacity-0")}></span>
      <span className={cx("flex bg-white w-full h-[2px] transition-[0.3s]")} style={{
      transform: active ? `rotate(-45deg) translate(9px, -2px)` : ""
    }}></span>
    </div>;
};
export default HamburgerMenuIcon;