import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { ErrorRes } from "utils/models/responseType";
import { localStorageKeys } from "utils/constants/localStorageKeys";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { isBrowser } from "utils/helpers/isBrowser";
import { userReducerActions } from "store/reducers/userReducer";
import { queryKeys } from "pages/main-layout/utils/constants/queryKeys";

// call back
export function useCallBack(afterClose: () => void) {
  const is_auth = useAppSelector((state) => state.userReducer.isAuth)
  const is_token = isBrowser() && localStorage.getItem(localStorageKeys.TOKEN)
  const dispatch = useAppDispatch()
  const { setIsAuth } = userReducerActions
  const qc = useQueryClient()
  
  return useMutation(
    async (data: any) => {

      const headers: Record<string, any> = {};
      if (is_auth || is_token) {
        headers.isAuth = true;
      }
      const res = await $api.post(endpoints.CALL_BACK, data, { headers });
      return res.data;
    },
    {
      onSuccess: () => {
        // showMessage(res?.messages, messageTypes.SUCCESS)
        afterClose();
      },
      onError: (err: ErrorRes) => {
        if (err?.response?.status === 401) {
          qc.invalidateQueries([queryKeys.CHECK_AUTH])
          localStorage.removeItem(localStorageKeys.IS_AUTH)
          localStorage.removeItem(localStorageKeys.TOKEN)
          dispatch(setIsAuth(false))
        }
      }
    }
  );
}
