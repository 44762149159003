import React, { useState } from "react";
import { useRouter } from "next/router";
import { Button } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { layoutLang } from "utils/language/layout";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import MetroItems from "pages/apartments/components/mobile-filter/metro-complex/MetroItems";
import { clearObj } from "utils/helpers/clearObj";
import { isEmptyArr } from "utils/helpers/isEmptyArr";
interface IProps {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}
const MetroSearch: React.FC<IProps> = ({
  value
}) => {
  const dispatch = useAppDispatch();
  const {
    locale,
    query,
    push
  } = useRouter();
  const complexes = useAppSelector(state => state.filterReducer.complexes);
  const metro_lines = useAppSelector(state => state.filterReducer.metro_lines);
  const nearest_places = useAppSelector(state => state.filterReducer.nearest_places);
  const brands = useAppSelector(state => state.filterReducer.brands);
  const locationType = useAppSelector(state => state.favouritesReducer.locationType);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setOpenRegionSelect
  } = favouritesReducerActions;
  const complex_ids = complexes && !isEmptyArr(complexes) ? complexes?.map(item => item?.id) : null;
  const metro_ids = metro_lines && !isEmptyArr(metro_lines) ? metro_lines?.map(item => item?.id) : null;
  const nearest_place_ids = nearest_places && !isEmptyArr(nearest_places) ? nearest_places?.map(item => item?.id) : null;
  const brand_ids = brands && !isEmptyArr(brands) ? brands?.map(item => item?.id) : null;
  const newEmptyFields = clearObj({
    ...query,
    complex_ids,
    metro_ids,
    nearest_place_ids,
    brand_ids
  });
  const handleSetQuery = () => {
    setIsLoading(true);
    push({
      query: newEmptyFields
    }).then(() => {
      setIsLoading(false);
      dispatch(setOpenRegionSelect({
        type: undefined,
        value: false
      }));
    });
  };
  return <>
      <MetroItems value={value} data-sentry-element="MetroItems" data-sentry-source-file="MetroSearch.tsx" />
      <div className="fixed h-[90px] bottom-0 w-full rounded-t-2xl bg-white right-0 flex items-center justify-center p-4 pb-7" style={{
      boxShadow: " 0px -4px 9.8px 0px #0000001A"
    }}>
        <Button type="primary" className="w-full h-[46px] rounded-xl" onClick={() => {
        locationType === "mobile_filter" ? handleSetQuery() : dispatch(setOpenRegionSelect({
          type: undefined,
          value: false
        }));
      }} loading={isLoading} data-sentry-element="Button" data-sentry-source-file="MetroSearch.tsx">
          {layoutLang[String(locale)]["Saqlash"]}
        </Button>
      </div>
    </>;
};
export default MetroSearch;